import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { CandidateCreateRequest } from '../../types/candidate/CandidateCreateRequest'
import candidateService from '../../api/candidate.api'
import ResultModalContent from '../modals/ResultModalContent'
import { ROUTES } from '../../helpers/routeConsts'
import ResultModal from '../modals/ResultModal'
import { ERROR_MESSAGES } from '../../helpers/errorMessage'
import ReactInputMask from 'react-input-mask'
import {useLocation, useParams} from "react-router-dom";

type Props = {}

const CandidateCreateForm = (props: Props) => {

    const [formValues, setFormValues] = useState<CandidateCreateRequest>(new CandidateCreateRequest())
    //--
    const [showResult, setShowResult] = useState<boolean>(false)
    const [resultModalContent, setResultModalContent] = useState<React.ReactNode>(<></>)
    const [link, setLink] = useState<string | null>(null)

    const handleCloseResult = () => {
        setShowResult(false)
    }

    const handleShowResult = () => {
        setShowResult(true)
    }
    //
    const location = useLocation();
    const path = location.state?.path;
    const handleForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        candidateService.create(formValues).then((response) => {
            if (response.status === 200) {
                setResultModalContent(<ResultModalContent type="success" message={"Кандидат успешно создан"} />)
                setLink(path || ROUTES.CANDIDATE + "/open")
                handleShowResult()
            }
        }).catch((e: any) => {
            setResultModalContent(<ResultModalContent type="error" message={ERROR_MESSAGES[e.response.data]} />)
            handleShowResult()
        })
    }
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFormValues((prev) => ({ ...prev, photo: null }))
        }
    }

    return <>
        <ResultModal
            show={showResult}
            link={link}
            title={"Кандидат создан"}
            handleClose={handleCloseResult}
            children={resultModalContent}
        />
        <form onSubmit={(e) => handleForm(e)} className="white-form-block mt-4 pt-4">
            <div className="form-group mb-5 d-flex">
                <label><span className="text-danger h5">*</span> Имя</label>
                <input
                    className="form-control"
                    value={formValues.firstName}
                    required={true}
                    onChange={(e) => { setFormValues((prev) => ({ ...prev, firstName: e.target.value })) }}
                />
            </div>
            <div className="form-group mb-5 d-flex">
                <label><span className="text-danger h5">*</span> Фамилия</label>
                <input
                    className="form-control"
                    value={formValues.lastName}
                    required={true}
                    onChange={(e) => { setFormValues((prev) => ({ ...prev, lastName: e.target.value })) }}
                />
            </div>
            <div className="form-group mb-5 d-flex">
                <label>Почта</label>
                <input
                    className="form-control"
                    value={formValues.email}
                    type='email'
                    onChange={(e) => { setFormValues((prev) => ({ ...prev, email: e.target.value })) }}
                />
            </div>
            <div className="form-group mb-5 d-flex">
                <label>Телефон</label>
                <Form.Control
                    className="form-control"
                    value={formValues.phone}
                    as={ReactInputMask} mask="+7 (999) 999-99-99"
                    onChange={(e) => { setFormValues((prev) => ({ ...prev, phone: e.target.value })) }}
                />
            </div>
            <div className="form-group mb-5 d-flex">
                {/* <input type="file" accept=".jpg, .jpeg, .png" onChange={handleFileChange} /> */}

            </div>
            <div className="d-flex mb-5">
                <label htmlFor=""></label>
                <Button type="submit" size={'lg'}>Создать кандидата</Button>
            </div>
        </form>
    </>
}

export default CandidateCreateForm