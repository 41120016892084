import React, { useEffect, useState } from 'react'
import candidateService from '../../../api/candidate.api'
import { ICandidateVacancyStepItem } from '../../../types/candidate/ICandidateVacancyStepItem'
import ComeFailedVacancyStepModal from "../../../components/modals/comeFailedVacancyStepModal";

type Props = {
  vacancyId: number
  resumeId: number
  update: () => void
}

const ComeFailedStep = (props: Props) => {


  const [list, setList] = useState<ICandidateVacancyStepItem[]>([]);

  const update = () => {
    candidateService.getVacancySteps(props.vacancyId, props.resumeId).then((response) => {
      setList(response.data)
    })
  }

  useEffect(() => {
    update()
  }, [props])


  return <ComeFailedVacancyStepModal step={list.filter(m => !m.complete)[0]} update={props.update} vacancyId={props.vacancyId}/>
}

export default ComeFailedStep
