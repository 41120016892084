import React from 'react'
import CandidateCreateForm from '../../components/forms/CandidateCreateForm'
import { useTranslation } from 'react-i18next'; // Импортируем хук для перевода текста

type Props = {}

const Create = (props: Props) => {
    const { t } = useTranslation(); // Хук для перевода текста
    return (
        <>
            <h3>{t('CreateCandidateTitle')}</h3> {/* Переводим заголовок "Создание кандидата" */}
            <p><span className='text-danger h5'>*</span> - {t('RequiredFieldsText')}</p> {/* Переводим текст "обязательные поля" */}
            <CandidateCreateForm />
        </>
    )
}

export default Create
