import React, { useState } from 'react'
import { ICandidateVacancyStepItem } from '../../types/candidate/ICandidateVacancyStepItem'
import { Button, Modal } from 'react-bootstrap'
import vacansyService from '../../api/vacancy.api'
import { socket } from '../../App'
import { useStores } from '../../stores'

type Props = {
  step: ICandidateVacancyStepItem,
  update: () => void,
  vacancyId:number
}

const ComeFailedVacancyStepModal = (props: Props) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {authStore} = useStores()

  const handleCome = () => {
    vacansyService.comeStep(props.step.id).then(response => {
      props.update()
      socket.emit('chat message', JSON.stringify({
        email:authStore.user?.email,
        action:`Кандидат ${props.step.resume.candidate.firstName} ${props.step.resume.candidate.lastName} не явился на этап "${props.step.step.name}"`,
        link:'/vacancy-candidates/'+ props.vacancyId,
        type:'step-failed',
      }));
      handleClose()
    })
  }
  return <>
    <button onClick={(e) => {
      handleShow()
    }} className={`dropdown-item`}>
      Не пришел
    </button>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Кандидат не пришел</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className='my-3'>Кандидат действительно не явился на этап вакансии?</h5>
        <Button onClick={handleCome} variant="danger">Да, не пришел</Button>
      </Modal.Body>
    </Modal>
  </>
}

export default ComeFailedVacancyStepModal