import React, {ChangeEvent, useState} from 'react'
import { ICandidateVacancyStepItem } from '../../types/candidate/ICandidateVacancyStepItem'
import { Button, Modal } from 'react-bootstrap'
import vacansyService from '../../api/vacancy.api'
import { socket } from '../../App'
import { useStores } from '../../stores'
import { ICandidateShort } from '../../types/candidate/ICandidateShort'
import { IDictionaryItem } from '../../types/IDictionaryItem'
import dictionaryService from '../../api/dictionary.api'
import {toast} from "react-toastify";
import userService from "../../api/user.api";
import {IUserShort} from "../../types/user/IUserShort";

type Props = {
    step: ICandidateVacancyStepItem,
    candidate: ICandidateShort,
    vacancyId: number
    update: () => void
}

const ChangeMeetingDateModal = (props: Props) => {
    const [show, setShow] = useState(false);
    const [usersSelect, setUsersSelect] = useState<IUserShort[]>([]);
    const [userSelected, setUserSelected] = useState<string>('');
    const handleClose = () => setShow(false);
    const handleShow = async () =>  {
        setShow(true);
        try {
            const {data} = await userService.findByRoles(['hiring', 'hrmanager'])
            setUsersSelect(data)
        } catch (e) {
            console.error(e)
        }
        dictionaryService.findAll("meetingType").then(response => {
           setMeetingTypes(response.data)
        }).catch(e => console.log(e))
    }
    const [meetingTypes, setMeetingTypes] = useState<IDictionaryItem[]>([])
    const [meetingTypeId, setMeetingTypeId] = useState<number>(0)
    const { authStore } = useStores()
    console.log(props)
    const [meetingDate, setMeetingDate] = useState<string>(props.step.meetingDate ? props.step.meetingDate.slice(0, 16) : "")

    const handleForm = () => {
        const selectedEmail = userSelected || authStore.user?.email
        vacansyService.changeMeetingDate(props.step.id, new Date(meetingDate).toISOString(), meetingTypeId, selectedEmail)
            .then(response => {
                socket.emit('chat message', JSON.stringify({
                    email: authStore.user?.email,
                    action: `Этап "${props.step.step.name}" с ${props.candidate.firstName} ${props.candidate.lastName} ${!props.step.meetingDate ? "назначен" : "перенесен"} на ${meetingDate}`,
                    link: '/vacancy-candidates/' + props.vacancyId,
                    type: 'meeting-date',
                    users: [props.candidate.email, authStore.user?.email]
                }));
                toast.success('Дата и время встречи успешно изменено.');

                props.update();
                handleClose();
            })
            .catch(error => {
                const message = error?.response?.data === 'CONFLICT_EVENT'
                    ? "На указанную дату или время уже запланировано другое событие."
                    : `Не удалось изменить дату встречи. Попробуйте снова. Ошибка: ${error?.response?.data}`

                toast.error(message);
            });
    }

    const handleSelectUser = (e: ChangeEvent<HTMLSelectElement>) => {
        setUserSelected(e.target.value)
    }

    return <>
        <Button variant="primary" onClick={handleShow}>
            {
                !props.step.meetingDate ? "Назначить" : "Перенести"
            }
        </Button>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Перенос даты</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>На какую дату хотите перенести "{props.step.step.name}"?</p>
                <input type="datetime-local" className="form-control mb-2"
                       value={meetingDate} onChange={(e) => setMeetingDate(e.target.value)}
                />
                <select className='form-select mb-3'
                        value={meetingTypeId} onChange={((e) => setMeetingTypeId(parseInt(e.target.value)))}
                >
                    <option value=''>-Выберите формат-</option>
                    {
                        meetingTypes.map((type) => (
                            <option value={type.id} key={type.id}>{type.name}</option>
                        ))
                    }
                </select>

                <select className='form-select mb-3'
                        value={userSelected}
                        onChange={handleSelectUser}
                >
                    <option value=''>-Выберите пользователя-</option>
                    {
                        usersSelect.map((user) => (
                            <option value={user?.email} key={user?.id}>{`${user?.firstName} ${user?.secondName}`}</option>
                        ))
                    }
                </select>
                <Button onClick={handleForm}>Сохранить изменения</Button>

            </Modal.Body>
        </Modal>
    </>
}

export default ChangeMeetingDateModal