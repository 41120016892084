import React, {useState} from 'react'
import {IUserFull} from '../../types/user/IUserFull'
import {Alert, Button, Form, Modal} from 'react-bootstrap'
import {UserUpdatePasswordDto} from '../../types/user/UserUpdatePasswordDto'
import userService from '../../api/user.api'
import {useStores} from '../../stores'
import authService from '../../api/auth.api'
import {useTranslation} from 'react-i18next'; // Импортируем функцию перевода

type Props = {
    user: IUserFull
}

const ChangePasswordForm = (props: Props) => {
    const [show, setShow] = useState(false);
    const {authStore} = useStores()
    const {t} = useTranslation(); // Используем функцию перевода

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordConfirmed, setShowNewPasswordConfirmed] = useState(false);
    const [error, setError] = useState<string>('')

    const handleForm = () => {
        userService.updatePassword(formValues).then((response) => {
            authService.logout().then((r) => {
                window.location.reload()
            })
        }).catch((e) => {
            console.log(e)
        })
    }

    const [formValues, setFormValues] = useState<UserUpdatePasswordDto>({
        email: props.user.email,
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    })


    return (<>
        <div style={{maxWidth: 400}}>
            <Form.Group className="mb-3 position-relative" controlId="oldPassword">
                <Form.Label>{t('changePasswordForm.oldPasswordLabel')}</Form.Label>
                <Form.Control type={showOldPassword ? "text" : "password"} value={formValues.oldPassword}
                              onChange={(e) => setFormValues((prev: any) => {
                                  return {
                                      ...prev,
                                      oldPassword: e.target.value
                                  }
                              })}/>
                <button type='button' className='bg-transparent border-0 position-absolute' style={{right: 10, top: 34}}
                        onClick={() => setShowOldPassword((prev) => (!prev))}>{showOldPassword ? "скрыть" : "показать"}</button>

            </Form.Group>

            <Form.Group className="mb-3 position-relative" controlId="newPassword">
                <Form.Label>{t('changePasswordForm.newPasswordLabel')}</Form.Label>
                <Form.Control type={showNewPassword ? "text" : "password"} value={formValues.newPassword}
                              onChange={(e) => setFormValues((prev: any) => {
                                  return {
                                      ...prev,
                                      newPassword: e.target.value
                                  }
                              })}/>
                <button type='button' className='bg-transparent border-0 position-absolute' style={{right: 10, top: 34}}
                        onClick={() => setShowNewPassword((prev) => (!prev))}>{showNewPassword ? "скрыть" : "показать"}</button>

            </Form.Group>

            <Form.Group className="mb-3 position-relative" controlId="newPasswordConfirmed">
                <Form.Label>{t('changePasswordForm.confirmPasswordLabel')}</Form.Label> {/* Используем функцию перевода */}
                <Form.Control type={showNewPasswordConfirmed ? "text" : "password"} value={formValues.confirmPassword}
                              onChange={(e) => setFormValues((prev: any) => {
                                  return {
                                      ...prev,
                                      confirmPassword: e.target.value
                                  }
                              })}/>
                <button type='button' className='bg-transparent border-0 position-absolute' style={{right: 10, top: 34}}
                        onClick={() => setShowNewPasswordConfirmed((prev) => (!prev))}>{showNewPasswordConfirmed ? "скрыть" : "показать"}</button>

            </Form.Group>


            {error !== '' && <Alert variant={'danger'}>
                {error}
            </Alert>}

            <Button type="button"
                    disabled={error !== '' || formValues.newPassword === '' || formValues.newPassword !== formValues.confirmPassword}
                    onClick={handleShow}>{t('changePasswordForm.changePasswordButton')}</Button> {/* Используем функцию перевода */}
        </div>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('changePasswordForm.modalTitle')}</Modal.Title> {/* Используем функцию перевода */}
            </Modal.Header>
            <Modal.Body>
                <p>
                    {t('changePasswordForm.modalMessage')}
                </p>

                <Button
                    onClick={handleForm}>{t('changePasswordForm.modalButton')}</Button> {/* Используем функцию перевода */}
            </Modal.Body>
        </Modal>
    </>)
}

export default ChangePasswordForm
