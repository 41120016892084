import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AxiosError } from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-toastify/dist/ReactToastify.css';
import './static/styles/main.css';
import "./i18n";

import { useStores } from './stores';
import authService from './api/auth.api';
import Router from './components/Router';
import Preloader from './components/Preloader';
import { IUserFull } from './types/user/IUserFull';

import io from 'socket.io-client';
import { API_URL } from './api';
import {ToastContainer} from "react-toastify";

export const socket = io(API_URL);



function App() {
  const { authStore } = useStores();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    authService.refresh().then((response) => {
      if (response.status === 200) {
        authStore.setAuth(true)
        authStore.setUser(response.data as IUserFull)
        setLoading(false)
      }
    }).catch((e: AxiosError) => {
      setLoading(false)
    })
  }, [authStore])
  return <>
    {loading ? <Preloader /> : <Router />}
    <ToastContainer />
    </>
}

export default observer(App);
