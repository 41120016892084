import React, { useState } from 'react'
import { Button, ListGroup, Modal } from 'react-bootstrap'
import { ICandidateFull } from '../../types/candidate/ICandidateFull'
import { ICandidateShort } from '../../types/candidate/ICandidateShort'
import candidateService from '../../api/candidate.api'
import { API_URL } from '../../api'
import vacansyService from '../../api/vacancy.api'
import ResumeCreateForm from "../forms/ResumeCreateForm";

type Props = {
    candidate: ICandidateShort,
    vacancyId: number,
    update: () => void,
    parentClose: () => void
}

const SelectCandidateResumeModal = (props: Props) => {

    const [show, setShow] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [candidate, setCandidate] = useState<ICandidateFull | null>(null);

    const handleClose = () => {
        document.getElementsByClassName('modal')[0].classList.add('show')
        document.getElementsByClassName('modal-backdrop')[0].classList.add('show')
        setShow(false);
    }
    const handleShow = () => {
        document.getElementsByClassName('modal')[0].classList.remove('show')
        document.getElementsByClassName('modal-backdrop')[0].classList.remove('show')
        candidateService.findById(props.candidate.id).then(response => {
            setCandidate(response.data)
        })
        setShow(true);
    }

    const handleSelectResume = (id: number) => {
        vacansyService.appendResume(props.vacancyId, id).then(response => {
            props.update()
            props.parentClose()
        })
    }

    const toggleShow = () => {
        setShowCreate(!showCreate)
    }

    const handleAddResume = () => {
        candidateService.findById(props.candidate.id).then(response => {
            setCandidate(response.data)
        })
        toggleShow()
    }

    return <>
        <ListGroup.Item onClick={handleShow}>
            {props.candidate.firstName} {props.candidate.lastName}
        </ListGroup.Item>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Список резюме кандидата</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                  candidate && candidate.resumes && candidate.resumes.length > 0 &&
                  candidate.resumes.map((resume, index) => {
                      return <>
                          <div className='d-flex align-content-center justify-content-between mb-2'>
                              <div>
                                  <Button className='me-4'
                                          onClick={() => handleSelectResume(resume.id)}
                                  >Выбрать</Button>
                                  {resume.name}
                              </div>

                              <a className='btn btn-outline-secondary btn-sm' href={API_URL + resume.file}>
                                  <i className="fa-solid fa-file-arrow-down"></i>
                              </a>

                          </div>

                      </>
                  })
                }
                <div className={'d-flex justify-content-between mt-3'}>
                    <h5>Добавить резюме</h5>
                    <button className='btn btn-outline-secondary btn-sm mb-2' onClick={toggleShow}>
                        <i className="fa-solid fa-plus"></i>
                    </button>
                </div>
                {(candidate && showCreate) &&
                  <div className={'mt-2'}>
                      <ResumeCreateForm id={candidate?.id} handleClose={handleAddResume}/>
                  </div>
                }

            </Modal.Body>
        </Modal>
    </>
}

export default SelectCandidateResumeModal