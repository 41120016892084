import React, {ChangeEvent, useRef, useState} from 'react'
import {IUserFull} from '../../types/user/IUserFull'
import {Button, Modal} from 'react-bootstrap'
import userService from '../../api/user.api'
import {useStores} from '../../stores'
import {observer} from 'mobx-react'
import {useTranslation} from 'react-i18next'; // Импортируем функцию перевода

type Props = {
    user: IUserFull
}
type Errors = {
    isError: boolean
    message: string
}

const ChangePhotoForm = (props: Props) => {

    const [show, setShow] = useState<boolean>(false);
    const [error, setError] = useState<Errors>({isError: false, message: ''});
    const {authStore} = useStores()
    const {t} = useTranslation(); // Используем функцию перевода

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);


    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleForm = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const response = await userService.updatePhoto(props.user.email, selectedFile as File)
            if (response.status === 200) {
                authStore.setUser(response.data)
                handleClose()
            }
        } catch (e: any) {
            if (e?.cause?.response?.data === "USER_NOT_FOUND") {
                setError(
                    {
                        isError: true,
                        message: "Произошла ошибка при сохранении картинки пользователя"
                    }
                )
                return
            }
            setError(
                {
                    isError: true,
                    message: "Произошла ошибка при сохранении картинки"
                }
            )
        }
    };
    return (
        <>
            <div className="edit-user-photo" onClick={handleShow}>
                <i className="fa-solid fa-pen-to-square"></i>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('changePhotoForm.title')}</Modal.Title> {/* Используем функцию перевода */}
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleForm}>
                        <div className="form-group mb-3">
                            <label htmlFor="photo"
                                   className="form-label">{t('changePhotoForm.photoLabel')}</label> {/* Используем функцию перевода */}
                            <input
                                required={true}
                                type="file"
                                className='form-control'
                                id="fileInput"
                                accept=".jpg, .jpeg, .png, .pdf, .webp" // specify accepted file types if needed
                                onChange={handleFileChange}
                            />
                        </div>
                        <Button
                            type="submit">{t('changePhotoForm.saveButton')}</Button> {/* Используем функцию перевода */}
                        {error.isError &&
                            <div className="alert alert-danger mt-3" role="alert">
                                {error.message}
                            </div>
                        }
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default observer(ChangePhotoForm)
