import React, { useEffect, useState } from 'react'
import { socket } from '../../../App';
import { Button } from 'react-bootstrap';
import notificationService from '../../../api/notification.api';
import { convertUTCToDatestring } from '../../../helpers/convertUTCToDatestring';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../helpers/routeConsts';


type Props = {}

export interface INotificationMessage {
    id: number
    author: string
    action: string
    link: string
    date: string
    type: string
    watched: boolean
}

const Notifications = (props: Props) => {
    const [messages, setMessages] = useState<INotificationMessage[]>([]);
    const [newMessage, setNewMessage] = useState<boolean>(false)
    const [show, setShow] = useState<boolean>(false)

    const update = () => {
        notificationService.getNotifications().then(response => {
            setMessages(response.data.filter((m: INotificationMessage) => !m.watched))
        }).catch(e => console.log(e))
    }

    const skipNotification = (id: number) => {
        notificationService.skipNotification(id).then(response => {
            update()
        })
    }

    const skipAllNotifications = () => {
        for (let message of messages) {
            skipNotification(message.id)
        }
    }

    socket.on('chat message', (msg: string) => {
        setNewMessage(true)
        update()
    });

    useEffect(() => {
        update()

        document.addEventListener('click', (e) => {
            let notificationMessages = document.getElementById('notification-messages')
            let notificationToggle = document.getElementById('notification-toggle')
            if (!notificationMessages?.contains(e.target as Node)
                && notificationToggle !== e.target
                && !notificationToggle?.contains(e.target as Node)) {
                setShow(false)
            }
        })

    }, []);

    return (
        <div className='notifications'>
            <Button onClick={() => {
                setShow((prev) => (!prev))
                setNewMessage(false)
            }} id="notification-toggle">
                {
                    newMessage ?
                        <i className="fa-solid fa-bell"></i>
                        :
                        <i className="fa-regular fa-bell"></i>
                }
            </Button>

            {
                show && <div className="messages shadow-lg" id="notification-messages">
                    <li className={'px-3 py-2'}><Link to={ROUTES.NOTIFICATIONS}>
                        <div className="message-item">
                            Все уведомления
                        </div>
                    </Link></li>
                    <ul style={{maxHeight: 400, overflowY: 'auto'}}>
                        {
                            messages.map((message, index) => {
                                return (
                                    <li key={index} className='position-relative'>
                                        <Link to={message.link} onClick={() => setShow(false)}>
                                            <div className="message-item position-relative pe-3">
                                                <small>
                                                    {convertUTCToDatestring(message.date)} - <b>{message.author}</b>
                                                </small>
                                                <p className='mb-0'>
                                                    {message.action}
                                                </p>
                                            </div>
                                        </Link>
                                        <Button size={'sm'} title="скрыть"
                                                style={{position: 'absolute', top: 10, right: 10}}
                                                onClick={() => skipNotification(message.id)}
                                        ><i className="fa-solid fa-xmark"></i></Button>
                                    </li>
                                )
                            })
                        }

                        {
                            messages.length > 1 && <li>
                                <div className="message-item" style={{cursor: 'pointer'}} onClick={skipAllNotifications}>
                                    <i className="fa-solid fa-xmark"></i> Очистить все уведомления
                                </div>
                            </li>
                        }
                    </ul>
                </div>
            }
        </div>
    );
}

export default Notifications