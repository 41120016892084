import React, { useEffect, useState } from 'react'
import { Button, Form, ListGroup, Modal } from 'react-bootstrap';
import companyService from '../../api/company.api';
import { IRole } from '../../types/company/IRole';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next'; // Импорт хука для перевода текста

type Props = {}

const Invite = (props: Props) => {

    const { t } = useTranslation(); // Хук для перевода текста

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setError('')
    }
    const handleShow = () => setShow(true);

    const [error, setError] = useState<string>('')

    const [email, setEmail] = useState<string>('')
    const [roles, setRoles] = useState<IRole[]>([])

    useEffect(() => {
        companyService.getRoles().then((response) => {
            setRoles(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    const [selectedRoles, setSelectedRoles] = useState<string[]>([])

    const handleForm = (e: React.FormEvent) => {
        e.preventDefault();
        setError('')
        companyService.sendInvite(email, selectedRoles).then((response) => {
            console.log('response', response)
            handleClose()
        }).catch((error:AxiosError) => {
            if(error.response?.status===409) {
                setError(t('InviteErrorLimit')) // Используем хук для перевода текста ошибки
            } else {
                setError(t('InviteErrorGeneral')) // Используем хук для перевода текста ошибки
            }
        })
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                {t('InviteButton')} {/* Переводим текст кнопки */}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('InviteModalTitle')}</Modal.Title> {/* Переводим заголовок модального окна */}
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleForm}>
                        <Form.Group className='mb-3'>
                            <Form.Label>{t('InviteEmailLabel')}</Form.Label> {/* Переводим текст метки поля ввода электронной почты */}
                            <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t('InviteEmailPlaceholder')} /> {/* Переводим текст placeholder */}
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>{t('InviteRolesLabel')}</Form.Label> {/* Переводим текст метки списка ролей */}
                            <ListGroup>
                                {roles.map((role) => {

                                    return <ListGroup.Item key={role.id}>
                                        <Form.Check // prettier-ignore
                                            type={'checkbox'}

                                            id={`default`}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedRoles((prev) => {
                                                        return [...prev, role.name]
                                                    })
                                                } else {
                                                    setSelectedRoles((prev) => {
                                                        return prev.filter((r) => r !== role.name)
                                                    })
                                                }
                                            }}
                                            label={role.description}
                                        />
                                    </ListGroup.Item>
                                })}
                            </ListGroup>
                        </Form.Group>
                        {error!=='' && <>
                            <p className="text-danger">{error} <a href="mailto:uki@unitoras.com">uki@unitoras.com</a></p>
                        </>}
                        <Button type='submit' className='mt-3'>{t('InviteSendButton')}</Button> {/* Переводим текст кнопки отправки */}
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Invite
