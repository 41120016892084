import React from 'react'
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../helpers/routeConsts';

type Props = {}

const Nav = (props: Props) => {
    const { i18n, t } = useTranslation();

    const changeLanguage = (lng: string, e: React.MouseEvent) => {
        e.preventDefault()
        i18n.changeLanguage(lng);
    };
    return (
        <nav>
            <Container>
                <h2><img src="/images/logo.png" alt="" style={{width:100,filter:'brightness(0)'}}/></h2>
                <ul>
                {/* <Dropdown>
                            <Dropdown.Toggle variant={'outline-primary'} id="dropdown-basic">
                                <i className="fa-solid fa-globe"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={(e) => changeLanguage('kz', e)}>KZ</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => changeLanguage('ru', e)}>RU</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => changeLanguage('en', e)}>EN</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                    {/* <li>
                        <Dropdown>
                            <Dropdown.Toggle variant={'outline-primary'} id="dropdown-basic">
                                <i className="fa-solid fa-globe"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={(e) => changeLanguage('kz', e)}>KZ</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => changeLanguage('ru', e)}>RU</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => changeLanguage('en', e)}>EN</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </li> */}
                    {/* <li>
                        <Link className='btn btn-outline-primary' to={ROUTES.REGISTER}>{t("sign-up")}</Link>
                    </li> */}
                    {/* <li>
                        <Link className='btn btn-primary' to={ROUTES.REGISTER}>{t("request-demo")}</Link>
                    </li> */}
                    <li></li>
                </ul>
            </Container>
        </nav>
    )
}

export default Nav