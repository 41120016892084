import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { ROUTES } from '../../../../helpers/routeConsts'
import { Link } from 'react-router-dom'
import EditModal from './EditModal'
import DeleteModal from './DeleteModal'
import { IDictionaryItem } from '../../../../types/IDictionaryItem'

type Props = {
    item: IDictionaryItem,
    update: () => void
}

const OperationList = (props: Props) => {
    return <Dropdown>
        <Dropdown.Toggle variant="light" id="dropdown-basic">
            <i className="fa-solid fa-ellipsis-vertical"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <Link to={`${ROUTES.ADMIN_DIVISION}/${props.item.id}`} className='dropdown-item'>Отделы</Link>
            <EditModal item={props.item} update={props.update} />
            <DeleteModal item={props.item} update={props.update} />
        </Dropdown.Menu>
    </Dropdown>
}

export default OperationList