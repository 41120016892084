import React, { useEffect } from 'react'
import Calendar from '../../components/calendar/Index'
import { useStores } from '../../stores'
import { Button } from 'react-bootstrap'
import { ROUTES } from '../../helpers/routeConsts'
import { useTranslation } from 'react-i18next'; // Импортируем хук для перевода текста

type Props = {}

const Index = (props: Props) => {
    const { t } = useTranslation(); // Хук для перевода текста

    const { authStore, routerStore } = useStores()

    useEffect(() => {
        routerStore.setCurrentPath(ROUTES.HOME)
    }, [routerStore])
    return (
        <div className='home=page' style={{ maxWidth: 1300 }}>
            <div className="home-page-cards mb-4">
                <div className="home-page-banner shadow-sm">
                    <div className="content">
                        <h2>{t('IndexWelcomeMessage', { name: authStore.user?.firstName })}</h2> {/* Переводим приветственное сообщение и подставляем имя пользователя */}
                        <p>{t('IndexScheduleMessage')}</p> {/* Переводим сообщение о расписании на текущую неделю */}
                    </div>
                    <img src="/images/hr-manager-welcome.png" alt="" />
                </div>
            </div>
            <Calendar />
        </div>
    )
}

export default Index
