import React from 'react'

type Props = {
    status: string
}

const StatusBadge = (props: Props) => {

    const statusTheme = () => {
        switch (props.status) {
            case "Новый":
                return "status-badge-primary";
            case "На рассмотрении":
            case "На доработке":
                return "status-badge-warning";
            case "Принят":
            case "Завершен":
                return "status-badge-success";
            case "Отклонен":
            case "Отменен":
                return "status-badge-danger";
            default:
                return "";
        }
    }

    return (
        <div className={"status-badge " + statusTheme()}>
            {props.status}
        </div>
    )
}

export default StatusBadge