import axiosInstance from "."

class CalendarService {
    async getEvents(date: Date) {
        const response = await axiosInstance.get('/api/calendar/getEvents/' + date.toISOString())
        return response
    }
}

const calendarService = new CalendarService()

export default calendarService