import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useStores } from '../../../stores'
import { observer } from 'mobx-react'
import authService from '../../../api/auth.api'
import { Link } from 'react-router-dom'
import { correctPhotoLink } from '../../../helpers/correctPhotoLink'
import { ADMIN_URL } from '../../../api'

type Props = {}

const UserDropdown = (props: Props) => {
    const { authStore } = useStores()

    const handleLogout = (e: React.MouseEvent<HTMLLinkElement>) => {
        e.preventDefault()
        authService.logout().then((response) => {
            if (response.status === 200) {
                window.location.reload()
            }
        })
    }
    return (
        <Dropdown>
            <Dropdown.Toggle variant="primary" className='w-auto' id="dropdown-basic">
                <div className='d-flex justify-content-center align-items-center'>
                    <img width={30} style={{ borderRadius: '50%', marginRight: '10px', aspectRatio:'1 / 1', objectFit:'cover' }} src={correctPhotoLink(authStore.user?.photo)} alt="" />
                    {authStore.user?.firstName}
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Link to="/profile" className='dropdown-item'>Личный кабинет</Link>
                <Link to="/my-company" className='dropdown-item'>Кабинет компании</Link>
                {authStore.hasRole('admin') && <Link to={ADMIN_URL} className='dropdown-item'>Управление</Link>}
                <a href='mailto:uki@unitoras.com' className='dropdown-item'> Нужна помощь? </a>
                <Dropdown.Item onClick={handleLogout}>Выйти</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default observer(UserDropdown)