import React, { useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'; // Импортируем хук для локализации

import { ICompanyEmployee } from '../../types/company/ICompanyEmployee'
import companyService from '../../api/company.api'

type Props = {
    employee: ICompanyEmployee,
    update: () => void
}

const Unassign = (props: Props) => {
    const { t } = useTranslation(); // Инициализируем хук для использования функции t() для локализации

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    const handleUnssign = () => {
        companyService.unassignEmployee(props.employee.email).then((response) => {
            props.update()
            handleClose()
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            <Dropdown.Item onClick={handleShow}>{t("unassign")} {/* Локализованный текст */}</Dropdown.Item>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("unassign_employee_title")}</Modal.Title> {/* Локализованный заголовок */}
                </Modal.Header>
                <Modal.Body>
                    <p className='text-danger'>{t("confirm_unassign")}</p> {/* Локализованный текст подтверждения */}
                    <p><small>{t("can_reassign")}</small></p> {/* Локализованный вспомогательный текст */}

                    <Button variant={'danger'} onClick={handleUnssign}>{t("unassign_button")}</Button> {/* Локализованная кнопка */}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Unassign
