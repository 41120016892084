import React, { useEffect, useState } from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import { IVacancyFull } from '../../types/vacancy/IVacancyFull';
import vacansyService from '../../api/vacancy.api';
import ManageTable from '../../components/manageTable/ManageTable';
import SelectCandidateModal from '../../components/modals/SelectCandidateModal';
import ResumeStep from './components/ResumeStep';
import ResumeOperationList from './components/ResumeOperationList';
import { Button } from 'react-bootstrap';
import { ROUTES } from '../../helpers/routeConsts';
import { useTranslation } from 'react-i18next'; // Добавлен импорт для локализации

type Props = {
    showComments: boolean;
};

const Candidats = (props: Props) => {
    const { id, resumeId } = useParams();
    const [vacancy, setVacancy] = useState<IVacancyFull | null>(null);
    const { t } = useTranslation(); // Инициализация хука для локализации

    const update = () => {
        vacansyService.findByIdShort(Number(id)).then((response) => {
            setVacancy(response.data);
        }).catch((e) => {
            console.error(e);
        });
    };

    useEffect(() => {
        update();
    }, [id]);

    const location = useLocation();
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(ROUTES.CANDIDATE_CREATE, { state: { path: location.pathname } });
    };

    return vacancy && (
        <>
            <h4 className='mb-3'>{t('candidates.title')} {vacancy.position}</h4>

            <div className={'d-flex gap-4'}>
                <SelectCandidateModal vacancy={vacancy} update={update}/>

                <button onClick={handleClick} className='btn btn-primary mb-3'>
                    {t('AddCandidateButton')}
                </button>
            </div>


            <ManageTable
              title={t('candidates.tableTitle')}
              header={<></>}
              footer={<></>}
                pages={[]}
            >
                <thead>
                    <tr>
                        <th>{t('candidates.tableHeaders.name')}</th>
                        <th>{t('candidates.tableHeaders.status')}</th>
                        <th>{t('candidates.tableHeaders.stageDate')}</th>
                        <th>{t('candidates.tableHeaders.stage')}</th>
                        <th>{t('candidates.tableHeaders.operations')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        vacancy.resumes && vacancy.resumes.length > 0 && vacancy.resumes.map((item) => {
                            return (
                              <tr key={item.id}>
                                  <td><Link
                                    to={ROUTES.CANDIDATE_INFO + "/" + item.candidate.id}>{item.candidate.firstName} {item.candidate.lastName}</Link>
                                  </td>
                                  <ResumeStep
                                    resumeId={Number(item.id)}
                                    candidate={item.candidate}
                                    vacancyId={vacancy.id}
                                  />
                                  <td><ResumeOperationList resumeId={Number(item.id)} vacancyId={vacancy.id}
                                                           update={update} showComments={props.showComments}
                                                           showCommentsFor={resumeId}/></td>
                              </tr>
                            );
                      })
                    }
                </tbody>
            </ManageTable>
        </>
    );
};

export default Candidats;
