import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type Props = {
    show: boolean,
    handleClose: () => void,
    children: React.ReactNode,
    link: string | null,
    title: string | null
}

const ResultModal = (props: Props) => {
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            {
                props.title && <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>
                {props.children}
                {
                    props.link ?
                        <Link to={props.link} onClick={props.handleClose} className='btn btn-primary'>Закрыть</Link>
                        : <Button variant="primary" onClick={props.handleClose}>
                            Закрыть
                        </Button>
                }

            </Modal.Body>
        </Modal>
    )
}

export default ResultModal