import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getApplicationTitle } from '../../helpers/getApplicationTitle'
import ManageTable from '../../components/manageTable/ManageTable'
import { Button, Spinner } from 'react-bootstrap'
import { IApplicationShort } from '../../types/application/IApplicationShort'
import { useStores } from '../../stores'
import applicationService from '../../api/application.api'
import { Link } from 'react-router-dom'
import { convertUTCToDatestring } from '../../helpers/convertUTCToDatestring'
import OperationsList from './components/OperationsList'
import UserShortInfo from '../../components/modals/UserShortInfo'
import { ROUTES } from '../../helpers/routeConsts'
import Filter from './Filter'
import { useTranslation } from 'react-i18next'; // Импортируем функцию перевода

type Props = {}

const Index = (props: Props) => {
    const { t } = useTranslation(); // Используем функцию перевода
    const { status } = useParams()

    const { routerStore } = useStores()

    const [pages, setPages] = useState<React.ReactNode[]>([])
    const [list, setList] = useState<IApplicationShort[] | null>(null)
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);

        if (timer) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(() => {
            updateList();
        }, 300);

        setTimer(newTimer);
    };


    const updateList = () => {
        setList(null)
        setSearchQuery('')
        applicationService.findAll({
            status,
            query: searchQuery
        }).then((response) => {
            if (response.status === 200) {
                setList(response.data[0])
            }
        }).catch((e) => {
            console.log(e)
            setList([])
        })
    }

    useEffect(() => {
        routerStore.setCurrentPath('/application/' + status)
    }, [routerStore, status])

    const afterFilter = (data: IApplicationShort[])=> {
        setList(data)
    }

    return (
        <>
            <h3>{getApplicationTitle(status as string)}</h3>
            <ManageTable
                title={t('applicationIndex.listTitle')}
                header={
                    <ul>
                        <li><input className='form-control' value={searchQuery} onChange={handleInputChange} placeholder={t('applicationIndex.searchPlaceholder')} /></li>
                        <li><Filter status={status} afterFilter={afterFilter}/></li>
                    </ul>
                }
                footer={<></>}
                pages={pages}
            >
                <thead>
                    <tr>
                        <th>{t('applicationIndex.tableHeaders.id')}</th>
                        <th>{t('applicationIndex.tableHeaders.position')}</th>
                        <th>{t('applicationIndex.tableHeaders.author')}</th>
                        <th>{t('applicationIndex.tableHeaders.creationDate')}</th>
                        <th>{t('applicationIndex.tableHeaders.operations')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list ?
                            <>
                                {
                                    list.map((item) => {
                                        return <tr key={item.id}>
                                            <td className='pt-3'>
                                                <Link to={`${ROUTES.APPLICATION_INFO}/${item.id}`}>{item.id}</Link>
                                            </td>
                                            <td className='pt-3'>
                                                <Link to={`${ROUTES.APPLICATION_INFO}/${item.id}`}>{item.position}</Link>
                                            </td>
                                            <td className='d-flex'>
                                                <UserShortInfo user={item.author} />
                                            </td>
                                            <td className='pt-3'>{convertUTCToDatestring(item.creationDate)}</td>
                                            <td >
                                                <OperationsList application={item} updateList={updateList} />
                                            </td>
                                        </tr>
                                    })
                                }
                            </> :
                            <tr>
                                <td colSpan={5} className='text-center p-5'>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </td>
                            </tr>
                    }
                </tbody>
            </ManageTable>
        </>
    )
}

export default Index
