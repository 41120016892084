import React, {useEffect, useState} from 'react';
import candidateService from '../../../api/candidate.api';
import {ICandidateVacancyStepItem} from '../../../types/candidate/ICandidateVacancyStepItem';
import ChangeMeetingDateModal from '../../../components/modals/ChangeMeetingDateModal';
import {convertUTCToDatestring} from '../../../helpers/convertUTCToDatestring';
import {ICandidateShort} from '../../../types/candidate/ICandidateShort';
import {useTranslation} from 'react-i18next'; // Импортируем хук для локализации

type Props = {
    resumeId: number;
    vacancyId: number;
    candidate: ICandidateShort;
};

const ResumeStep = (props: Props) => {
    const {t} = useTranslation(); // Инициализируем хук для локализации

    const [list, setList] = useState<ICandidateVacancyStepItem[]>([]);

    const update = () => {
        candidateService.getVacancyStepsShort(props.vacancyId, props.resumeId).then((response) => {
            setList(response.data);
        });
    };

    useEffect(() => {
        update();
    }, [props]);

    const incompleteItem = list.find((m) => !m.complete);
    console.log(incompleteItem)
    const renderContent = () => {
        if (!incompleteItem) {
            return (
                <>
                    <td>
                        <p className='text-success fw-bold'>{t('resumeStep.allCompleted')}</p>
                    </td>
                    <td></td>
                </>
            );
        }

        if (incompleteItem.isCome) {
            return (
                <>
                    <td>
                        <p className='text-warning fw-bold'>{t('resumeStep.notCome')}</p>
                    </td>
                    <td></td>
                </>
            );
        }

        if (incompleteItem.failed) {
            return (
                <>
                    <td>
                        <p className='text-danger fw-bold'>{t('resumeStep.notPassed')}</p>
                    </td>
                    <td></td>
                </>
            );
        }
        if (!incompleteItem.complete) {
            return (
                <>
                    <td>
                        <p className='text-secondary fw-bold'>{t('resumeStep.isProcess')}</p>
                    </td>
                    <td>
                        {incompleteItem.meetingDate ? (
                            <p className='mb-1 me-2'>
                                {convertUTCToDatestring(incompleteItem.meetingDate)} ({incompleteItem.meetingType ?? ''})
                            </p>
                        ) : (
                            <p className='mb-1 me-2'>{t('resumeStep.notSet')}</p>
                        )}
                        <ChangeMeetingDateModal
                            step={incompleteItem}
                            candidate={props.candidate}
                            update={update}
                            vacancyId={props.vacancyId}
                        />
                    </td>
                </>
            );
        }
        return (
            <>
                <td></td>
                <td>
                    {incompleteItem.meetingDate ? (
                        <p className='mb-1 me-2'>
                            {convertUTCToDatestring(incompleteItem.meetingDate)} ({incompleteItem.meetingType ?? ''})
                        </p>
                    ) : (
                        <p className='mb-1 me-2'>{t('resumeStep.notSet')}</p>
                    )}
                    <ChangeMeetingDateModal
                        step={incompleteItem}
                        candidate={props.candidate}
                        update={update}
                        vacancyId={props.vacancyId}
                    />
                </td>
            </>
        );
    };

    return (
        <>
            {renderContent()}
            <td>
                <p className='mb-0'>{incompleteItem?.step?.name}</p>
            </td>
        </>
    );
};

export default ResumeStep;
