import React, { useState } from 'react'
import { IDictionaryItem } from '../../../../types/IDictionaryItem'
import dictionaryService from '../../../../api/dictionary.api'
import { Button, Form, Modal } from 'react-bootstrap'

type Props = {
    item: IDictionaryItem,
    update: () => void
}

const DivisionEditModal = (props: Props) => {
    const [item, setItem] = useState<IDictionaryItem>(props.item)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleEdit = (e: React.FormEvent) => {

        e.preventDefault()

        dictionaryService.edit('division', item!).then((response: any) => {
            props.update()
            handleClose()
        })

    }

    return <>
        <a href="/" className='dropdown-item' onClick={(e) => {
            e.preventDefault()
            handleShow()
        }}>
            Редактировать
        </a>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Редактирование отдел "{item.name}"</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleEdit}>
                <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Название</Form.Label>
                        <Form.Control type="text" placeholder="Название"
                            value={item.name} onChange={(e) => setItem({ ...item, name: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Название на казахском</Form.Label>
                        <Form.Control type="text" placeholder="Название"
                            value={item.nameKZ} onChange={(e) => setItem({ ...item, nameKZ: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Название на английском</Form.Label>
                        <Form.Control type="text" placeholder="Название"
                            value={item.nameEN} onChange={(e) => setItem({ ...item, nameEN: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Описание</Form.Label>
                        <Form.Control type="text" placeholder="Краткое описание"
                            value={item.description} onChange={(e) => setItem({ ...item, description: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Описание на казахском</Form.Label>
                        <Form.Control type="text" placeholder="Краткое описание"
                            value={item.descriptionKZ} onChange={(e) => setItem({ ...item, descriptionKZ: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Описание на английском</Form.Label>
                        <Form.Control type="text" placeholder="Краткое описание"
                            value={item.descriptionEN} onChange={(e) => setItem({ ...item, descriptionEN: e.target.value })}
                        />
                    </Form.Group>
                    <Button type={"submit"}>Сохранить</Button>
                </Form>
            </Modal.Body>
        </Modal>
    </>
}

export default DivisionEditModal