import React, { useState } from 'react'
import { FINDER_CONST_RESULTS, IFinderConstResults } from '../../../helpers/finderConstResults'
import { Link } from 'react-router-dom'
import { IApplicationShort } from '../../../types/application/IApplicationShort'
import applicationService from '../../../api/application.api'
import { convertUTCToDate } from '../../../helpers/сonvertUTCToDate'
import { IVacancyShort } from '../../../types/vacancy/IVacancyShort'
import vacansyService from '../../../api/vacancy.api'
import { ICandidateShort } from '../../../types/candidate/ICandidateShort'
import candidateService from '../../../api/candidate.api'

type Props = {}

const Finder = (props: Props) => {

    const [searchQuery, setSearchQuery] = useState<string>('')
    const [hasResult, setHasResult] = useState<boolean>(false)

    const [finderConstResults, setFinderConstResults] = useState<IFinderConstResults[]>([]);
    const [applications, setApplications] = useState<IApplicationShort[]>([])
    const [vacancies, setVacancies] = useState<IVacancyShort[]>([])
    const [candidates, setCandidates] = useState<ICandidateShort[]>([])

    const hanldeSearchQuery = (e: React.FormEvent<HTMLInputElement>) => {
        setSearchQuery(e.currentTarget.value)

        if (e.currentTarget.value.length < 2) {
            setHasResult(false)
            return
        }
        let list = FINDER_CONST_RESULTS.filter(m => m.title.toLocaleLowerCase().includes(e.currentTarget.value.toLocaleLowerCase()))
        if (list.length > 0) {
            setHasResult(true)
        }
        setFinderConstResults(list)

        applicationService.findAll({
            query: e.currentTarget.value,
        }).then((response) => {
            setApplications(response.data[0])
            if (response.data[0].length > 0) {
                setHasResult(true)
            }
        }).catch((error) => {
            console.log(error)
        })

        vacansyService.findAll({
            query: e.currentTarget.value
        }).then((response) => {
            setVacancies(response.data[0])
            if (response.data[0].length > 0) {
                setHasResult(true)
            }
        }).catch((error) => {
            console.log(error)
        })

        candidateService.findAll({
            query: e.currentTarget.value
        }).then((response) => {
            setCandidates(response.data[0])
            if (response.data[0].length > 0) {
                setHasResult(true)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return <div className="finder">
        <form>
        <input className='form-control'
            id="nav-finder-input"
            value={searchQuery} onChange={(e) => hanldeSearchQuery(e)}
            placeholder='Поиск по системе' />
        </form>
        {
            searchQuery.length > 0 && hasResult &&
            <div className="result shadow">
                <ul className='consts-list'>
                    {
                        finderConstResults.map((result) => (
                            <li key={result.link}>
                                <Link to={result.link} onClick={() => setSearchQuery('')}>
                                    <div className="consts-list-item">
                                        {result.title}
                                    </div>
                                </Link>
                            </li>
                        ))
                    }
                </ul>
                {
                    applications.length > 0 && <div className="result-block">
                        <p>Заявки</p>
                        <ul className='result-list'>
                            {
                                applications.map((application) => {
                                    return <li key={application.id}>
                                        <Link to={'/application-info/' + application.id} onClick={() => setSearchQuery('')}>
                                            <div className="result-list-item">
                                                <span>
                                                    {application.position}
                                                </span>
                                                <span>
                                                    {convertUTCToDate(application.creationDate)}
                                                </span>
                                            </div>
                                        </Link>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                }

                {
                    vacancies.length > 0 && <div className="result-block">
                        <p>Вакансии</p>
                        <ul className='result-list'>
                            {
                                vacancies.map((vacancy) => {
                                    return <li key={vacancy.id}>
                                        <Link to={'/vacancy-info/' + vacancy.id} onClick={() => setSearchQuery('')}>
                                            <div className="result-list-item">
                                                <span>
                                                    {vacancy.position}
                                                </span>
                                                <span>
                                                    {convertUTCToDate(vacancy.creationDate)}
                                                </span>
                                            </div>
                                        </Link>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                }

                {
                    candidates.length > 0 && <div className="result-block">
                        <p>Кандидаты</p>
                        <ul className='result-list'>
                            {
                                candidates.map((candidate) => {
                                    return <li key={candidate.id}>
                                        <Link to={'/candidate-info/' + candidate.id} onClick={() => setSearchQuery('')}>
                                            <div className="result-list-item">
                                                <span>
                                                    {candidate.firstName} {candidate.lastName}
                                                </span>
                                                <span>
                                                    {candidate.email}
                                                </span>
                                            </div>
                                        </Link>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                }
            </div>
        }
        <button><i className="fa-solid fa-magnifying-glass"></i></button>
    </div>
}

export default Finder