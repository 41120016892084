import React from 'react'
import { Outlet } from 'react-router-dom'
import '../../../static/styles/auth.css'
import Nav from './Nav'
import Footer from './Footer'
import { Container } from 'react-bootstrap'

type Props = {}

const Layout = (props: Props) => {
  return (
    <div id="authLayout">
      <Nav />
      <Container style={{minHeight:700}}>
        <Outlet />
      </Container>
      <Footer />
    </div>
  )
}

export default Layout