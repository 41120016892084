import React from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

type Props = {}

const Footer = (props: Props) => {
    const { t } = useTranslation()
    return (
        <footer>
            <Container>
                <p className='text-center'>Copyright ©uki {new Date().getFullYear()} |
                    <a className='fw-bold ms-1' href="/docs/policy_of_privacy.pdf" target="_blank">{t("privacy-policy-title")}</a></p>
            </Container>
        </footer>
    )
}

export default Footer