import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IApplicationFull } from '../../types/application/IApplicationFull'
import { convertUTCToDatestring } from '../../helpers/convertUTCToDatestring'
import { convertToStringPrice } from '../../helpers/convertToStringPrice'
import { convertUTCToDate } from '../../helpers/сonvertUTCToDate'
import { Button } from 'react-bootstrap'
import applicationService from '../../api/application.api'
import StatusBadge from './components/StatusBadge'
import ResultModalContent from '../../components/modals/ResultModalContent'
import { ERROR_MESSAGES } from '../../helpers/errorMessage'
import ResultModal from '../../components/modals/ResultModal'
import { ROUTES } from '../../helpers/routeConsts'
import { correctPhotoLink } from '../../helpers/correctPhotoLink'
import { useStores } from '../../stores'

type Props = {}

const Info = (props: Props) => {

    const { authStore } = useStores()
    const { id } = useParams()
    const [application, setApplication] = useState<IApplicationFull | null>(null)

    const [showResult, setShowResult] = useState<boolean>(false)
    const [resultModalContent, setResultModalContent] = useState<React.ReactNode>(<></>)

    const handleCloseResult = () => {
        setShowResult(false)
    }

    const handleShowResult = () => {
        setShowResult(true)
    }

    useEffect(() => {
        applicationService.findById(Number(id)).then((response) => {
            setApplication(response.data)
            if (response.data.status.translit == 'new') {
                applicationService.setStatus(Number(id), 'under_consideration').then((response) => {
                    setApplication(response.data)
                })
            }
        })
    }, [id])

    const handleSetStatus = (statusName: string) => {
        applicationService.setStatus(Number(id), statusName).then((response) => {
            if (response.status === 200) {
                setResultModalContent(<ResultModalContent type="success" message={"Статус заявки изменен"} />)
                handleShowResult()
                setApplication(response.data)
            }
        }).catch((e: any) => {
            setResultModalContent(<ResultModalContent type="error" message={ERROR_MESSAGES[e.response.data]} />)
            handleShowResult()
        })
    }

    return <>
        <h5 className='text-muted mb-4'>Подробнее о заявке</h5>
        <ResultModal
            show={showResult}
            link={null}
            title={"Изменение статуса"}
            handleClose={handleCloseResult}
            children={resultModalContent}
        />
        <div className="white-form-block">
            {
                application && <div className='application-details-container'>
                    <StatusBadge status={application.status.name} />
                    <h4>{application.position}</h4>
                    <p className='text-muted mb-1'>{convertUTCToDatestring(application.creationDate)}</p>
                    <div className='d-flex'>
                        <Link to="/" className='text-decoration-none'>
                            <div className="user-list-item">
                                <img src={correctPhotoLink(application.author.photo)} alt="" />
                                <p>{application.author.fullName}</p>
                            </div>
                        </Link>
                    </div>
                    <hr />

                    <div className="application-details-item">
                        <div className="title">
                            Зарплата
                        </div>
                        <div className='value'>
                            {convertToStringPrice(application.salary)}  ({application.afterTax ? 'с учетом налогов' : 'Без учета налогов'})
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Бонусы
                        </div>
                        <div className='value'>
                            <p className='mb-0'>-{application.bonus.type}-</p>
                            <p>{application.bonus.value}</p>
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Количество работников
                        </div>
                        <div className='value'>
                            {application.amountOfWorkers}
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Информация о компании для вакансии
                        </div>
                        <div className='value flex-grow-1'>
                            <div className="card card-body" dangerouslySetInnerHTML={{ __html: application.aboutCompanyForVacancy }}>

                            </div>
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Проблемы из-за которой открыли вакансию
                        </div>
                        <div className='value flex-grow-1'>
                            <div className="card card-body" dangerouslySetInnerHTML={{ __html: application.problems }}>

                            </div>
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Обязанности
                        </div>
                        <div className='value flex-grow-1'>
                            <div className="card card-body" dangerouslySetInnerHTML={{ __html: application.responsibilities }}>

                            </div>
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Требования
                        </div>
                        <div className='value flex-grow-1'>
                            <div className="card card-body" dangerouslySetInnerHTML={{ __html: application.requirements }}>

                            </div>
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Ожидания от сотрудника
                        </div>
                        <div className='value flex-grow-1'>
                            <div className="card card-body" dangerouslySetInnerHTML={{ __html: application.expectations }}>

                            </div>
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Будет плюсом
                        </div>
                        <div className='value flex-grow-1'>
                            <div className="card card-body" dangerouslySetInnerHTML={{ __html: application.someWishes }}>

                            </div>
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Что мы предлагаем
                        </div>
                        <div className='value flex-grow-1'>
                            <div className="card card-body" dangerouslySetInnerHTML={{ __html: application.whatWeOffer }}>

                            </div>
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Возможности
                        </div>
                        <div className='value flex-grow-1'>
                            <div className="card card-body" dangerouslySetInnerHTML={{ __html: application.opportunities }}>

                            </div>
                        </div>
                    </div>


                    <div className="application-details-item">
                        <div className="title">
                            Дата выхода на работу
                        </div>
                        <div className='value'>
                            {convertUTCToDate(application.workStartDate)}
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Причина найма
                        </div>
                        <div className='value'>
                            {application.reasonForHiring}
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Вид договора
                        </div>
                        <div className='value'>
                            {application.workContractType}
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            График работы
                        </div>
                        <div className='value'>
                            {application.workScheduleType}
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Опыт работы
                        </div>
                        <div className='value'>
                            {application.workExperienceType}
                        </div>
                    </div>


                    <div className="application-details-item">
                        <div className="title">
                            Департамент / отдел
                        </div>
                        <div className='value'>
                            {application.department} / {application.division}
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Льготы
                        </div>
                        <div className='value'>
                            <ul className='list-group'>
                                {
                                    application.privileges.map((item, index) => (
                                        <li className='list-group-item' key={index}>{item}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>

                    <div className="application-details-item">
                            <div className="title">
                                Личные качества
                            </div>
                            <div className='value'>
                                <ul className='list-group'>
                                    {
                                        application.personalQualities.map((item, index) => (
                                            <li className='list-group-item' key={index}>{item}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>

                    <div className="application-details-item">
                        <div className="title">
                            Уровень английского
                        </div>
                        <div className='value'>
                            {application.englishLevel}
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Другие языки
                        </div>
                        <div className='value'>
                            <ul className="list-group">
                                {
                                    application.languages.map((item) => {
                                        return <li key={item.name} className='list-group-item'>{item.name} ({item.degree})</li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Образование
                        </div>
                        <div className='value'>
                            {application.educationDegree}
                        </div>
                    </div>

                    <div className="application-details-item">
                        <div className="title">
                            Дополнительные навыки
                        </div>
                        <div className='value'>
                            {application.additionalSkills}
                        </div>
                    </div>

                    <div className="application-details-item mt-5 not-hovered">
                        <div className="title">

                        </div>
                        <div className='value'>
                            <div className='d-flex mb-2 align-items-center flex-wrap' style={{ gap: '10px' }}>

                                {
                                    authStore.hasRole('hrmanager') && <>
                                        {
                                            [
                                                'new',
                                                'under_consideration'
                                            ].includes(application.status.translit) &&
                                            <>
                                                <Link to={ROUTES.VACANCY_CREATE + '/' + application.id} className='btn btn-success text-white'>Создать вакансию</Link>
                                            </>
                                        }

                                        {
                                            [
                                                'new',
                                                'under_consideration'
                                            ].includes(application.status.translit) &&
                                            <>
                                                <Button onClick={() => handleSetStatus('under_revision')}>Отправить на доработку</Button>
                                            </>
                                        }
                                        {
                                            ![
                                                'accepted',
                                                'completed',
                                                'canceled',
                                                'rejected'
                                            ].includes(application.status.translit) &&
                                            <Button variant='danger' onClick={() => handleSetStatus('rejected')}>Отклонить</Button>
                                        }
                                    </>
                                }
                                {
                                    authStore.user!.email === application.author.email && <>
                                       {
                                         [
                                            'new',
                                            'under_consideration',
                                            'under_revision'
                                            ].includes(application.status.translit) &&
                                            <>
                                                <Link to={ROUTES.APPLICATION_EDIT + '/' + application.id} className='btn btn-warning text-white'>Редактировать</Link>
                                            </>
                                       }

                                        {
                                            !['canceled','rejected'].includes(application.status.translit) &&
                                            <>
                                            <Button variant='danger' onClick={() => handleSetStatus('canceled')} >Отменить</Button>
                                            </>
                                        }
                                    </>


                                }

                            </div>

                        </div>
                    </div>


                </div>
            }
        </div>
    </>
}

export default Info