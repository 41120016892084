import React, { useEffect, useState } from 'react'
import { IUserFull } from '../../types/user/IUserFull'
import userService from '../../api/user.api'
import { useStores } from '../../stores'
import { ListGroup, Tab, Tabs } from 'react-bootstrap'
import AccountSettingsForm from './AccountSettingsForm'
import { Link } from 'react-router-dom'
import ChangePasswordForm from './ChangePasswordForm'
import ChangePhotoForm from './ChangePhotoForm'
import { correctPhotoLink } from '../../helpers/correctPhotoLink'
import { observer } from 'mobx-react'
import { ROUTES } from '../../helpers/routeConsts'
import { useTranslation } from 'react-i18next'; // Импорт хука для перевода текста

type Props = {}

const Profile = (props: Props) => {
  const { t } = useTranslation(); // Хук для перевода текста
  const [user, setUser] = useState<IUserFull | null>(null)
  const { authStore } = useStores()
  const update = () => {
    userService.findByEmail(authStore.user!.email).then((response) => {
      setUser(response.data as IUserFull)
    }).catch((e) => console.log(e))
  }

  useEffect(() => {
    setUser(authStore.user!)
  }, [])

  return <>
    <div className="profile">
      <h3 className='mb-4'>{t('ProfileTitle')}</h3> {/* Переводим заголовок "Личный кабинет" */}
      <div className="profile-container">
        <div className="profile-block shadow-sm">
          <div className="header">
            <div className="image">
              <img src={correctPhotoLink(authStore.user?.photo)} alt="" />
              {
                user && <ChangePhotoForm user={user}/> 
              }
            </div>
            <div className="info">
              <h5>
                {authStore.user?.firstName} {authStore.user?.lastName}
              </h5>
              <p>{authStore.user?.email}</p>
            </div>
            {
              user && user.company && <div className="company-info">
              <Link to={ROUTES.MY_COMPANY}>
                <div className="company-block">
                  <img src={correctPhotoLink(user.company.logo)} alt="" />
                  <p>
                    {user.company.name}
                  </p>
                </div>
              </Link>
            </div>
            }
          </div>
        </div>
        <div className="setting-block">
          <Tabs
            variant="pills"
            defaultActiveKey="account-setting"
            id="uncontrolled-tab-example"
            className="mb-3 border-bottom pb-3"
          >
            <Tab eventKey="account-setting" title={t('UserInfoTabTitle')}> {/* Переводим название вкладки "Информация о пользователе" */}
              {user && <AccountSettingsForm user={user} />} {/* Передаем пользователя в компонент AccountSettingsForm */}
            </Tab>
            <Tab eventKey="password-setting" title={t('ChangePasswordTabTitle')}> {/* Переводим название вкладки "Смена пароля" */}
              {user && <ChangePasswordForm user={user}/>} {/* Передаем пользователя в компонент ChangePasswordForm */}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  </>
}

export default observer(Profile)
