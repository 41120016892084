import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { IDictionaryItem } from '../../../../types/IDictionaryItem';
import dictionaryService from '../../../../api/dictionary.api';

type Props = {
    update: () => void
}

const CreateModal = (props: Props) => {

    const [item, setItem] = useState<IDictionaryItem>({
        name: '',
        nameKZ: '',
        nameEN: '',
        description: '',
        descriptionKZ: '',
        descriptionEN: '',
        parentId: 0,
        id: 0
    })

    const [show, setShow] = useState(false);
    const [error, setError] = useState('')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleEdit = (e: React.FormEvent) => {

        e.preventDefault()

        setError('')

        dictionaryService.create('department', item!).then((response: any) => {
            props.update()
            handleClose()
        }).catch((error: any) => {
            if (error.response.status === 409) {
                setError("Департамент с таким названием уже существует")
            } else {
                setError("Неизвестная ошибка")
            }
        })

    }

    return <>
        <Button onClick={handleShow} className='mb-3'>
            Создать
        </Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Создание Департамента</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleEdit}>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Название</Form.Label>
                        <Form.Control type="text" placeholder="Название департамента"
                            value={item.name} onChange={(e) => setItem({ ...item, name: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Название на казахском</Form.Label>
                        <Form.Control type="text" placeholder="Название департамента"
                            value={item.nameKZ} onChange={(e) => setItem({ ...item, nameKZ: e.target.value })}
                        />
                    </Form.Group>


                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Название на английском</Form.Label>
                        <Form.Control type="text" placeholder="Название департамента"
                            value={item.nameEN} onChange={(e) => setItem({ ...item, nameEN: e.target.value })}
                        />
                    </Form.Group>


                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Описание</Form.Label>
                        <Form.Control type="text" placeholder="Краткое описание департамента"
                            value={item.description} onChange={(e) => setItem({ ...item, description: e.target.value })}
                        />
                    </Form.Group>

                    
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Описание на казахском</Form.Label>
                        <Form.Control type="text" placeholder="Краткое описание департамента"
                            value={item.descriptionKZ} onChange={(e) => setItem({ ...item, descriptionKZ: e.target.value })}
                        />
                    </Form.Group>

                    
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Описание на английском</Form.Label>
                        <Form.Control type="text" placeholder="Краткое описание департамента"
                            value={item.descriptionEN} onChange={(e) => setItem({ ...item, descriptionEN: e.target.value })}
                        />
                    </Form.Group>

                    {error !== '' && <p className='text-danger'>{error}</p>}

                    <Button type={"submit"}>Сохранить</Button>

                </Form>
            </Modal.Body>
        </Modal>
    </>
}

export default CreateModal