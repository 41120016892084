import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import ResumeCreateForm from '../forms/ResumeCreateForm'

type Props = {
    id: number,
    update: () => void
}

const CreateResumeModal = (props: Props) => {

    const [show, setShow] = useState<boolean>(false)

    const handleShow = () => {
        setShow(true)
    }

    const handleClose = () => {
        props.update()
        setShow(false)
    }

    return (
        <>
            <Button onClick={handleShow}>+ Добавить резюме</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавление резюме кандидату</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ResumeCreateForm id={props.id} handleClose={handleClose} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateResumeModal