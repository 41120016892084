export const ROUTES = {
    HOME: '/',
    REFRESH: "/api/auth/refresh",
    LOGIN: '/auth/login',
    REGISTER:'/auth/register',
    NEED_COMPANY:'/auth/need-company',
    APPLICATION:'/application',
    APPLICATION_INFO:'/application-info',
    APPLICATION_CREATE:'/application-create',
    APPLICATION_EDIT:'/application-edit',
    APPLICATION_CANCEL:'/application-cancel',
    VACANCY_CREATE:'/vacancy-create',
    VACANCY:'/vacancy',
    VACANCY_INFO:'/vacancy-info',
    VACANCY_CANDIDATS:'/vacancy-candidates',
    VACANCY_STEPS:'/vacancy-steps',
    CANDIDATE:'/candidate',
    CANDIDATE_CREATE:'/candidate-create',
    CANDIDATE_INFO:'/candidate-info',
    ADMIN_DEPARTMENT:'/admin/department',
    ADMIN_DIVISION:'/admin/division',
    ADMIN_PRIVILEGE:'/admin/privilege',
    ADMIN_PERSONAL_QUALITY:'/admin/personal-quality',
    PROFILE:'/profile',
    NOTIFICATIONS:'/notifications',
    MY_COMPANY:'/my-company',
    NEED_ACTIVATE:'/auth/need-activate',
}