import React, { useState } from 'react'
import Hours from './Hours'
import Day from './Day'
import { Calendar } from './types/Calendar'
import { Button } from 'react-bootstrap'

type Props = {}

const Index = (props: Props) => {
    const [calendar, setCalendar] = useState(new Calendar())

    const handleSetWeek = (type: 'prev' | 'next') => {
        if (type === 'next') {
            setCalendar((prev) => (Calendar.nextWeek(prev)))
        } else {
            setCalendar((prev) => (Calendar.prevWeek(prev)))
        }
    }

    return (
        <div className="calendar-container shadow-sm">
            <div className="d-flex my-3 align-items-center" style={{ gap: 10 }}>
                <Button onClick={() => handleSetWeek('prev')}><i className="fa-solid fa-arrow-left"></i></Button>
                <h4>{calendar.currentMonth}</h4>
                <Button onClick={() => handleSetWeek('next')}><i className="fa-solid fa-arrow-right"></i></Button>
                {/* <input style={{width:300}} className='form-control' type='week'/> */}
            </div>
            <div className="calendar-container-content">
                <Hours />
                <div className='calendar-week-container'>
                    {
                        calendar.calendarDays.map((day, index) => {
                            return (
                                <Day key={index} day={day} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Index