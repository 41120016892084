import React from 'react'
import { Outlet } from 'react-router'
import Nav from './Nav'

type Props = {}

const Content = (props: Props) => {
    return (
        <div className='main-layout-content-block-container'>
            <Outlet />
        </div>
    )
}

export default Content