import React, { useState } from 'react'
import { IDictionaryItem } from '../../../../types/IDictionaryItem';
import dictionaryService from '../../../../api/dictionary.api';
import { Button, Form, Modal } from 'react-bootstrap';

type Props = {
    parentId: number,
    update: () => void
}

const DivisionCreateModal = (props: Props) => {
    const [item, setItem] = useState<IDictionaryItem>({
        name: '',
        nameKZ:'',
        nameEN:'',
        description: '',
        descriptionKZ:'',
        descriptionEN:'',
        parentId: props.parentId,
        id: 0
    })

    const [error, setError] = useState('')
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleEdit = (e: React.FormEvent) => {

        e.preventDefault()
        setError('')
        dictionaryService.create('division', item!).then((response: any) => {
            props.update()
            handleClose()
        }).catch(error=>{
            if(error.response.status===409) {
                setError('Отдел с таким названием уже существует')
            }
        })

    }

    return <>
        <Button onClick={handleShow} className='mb-3'>
            + Создать
        </Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Создание Отдела</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleEdit}>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Название</Form.Label>
                        <Form.Control type="text" placeholder="Название"
                            value={item.name} onChange={(e) => setItem({ ...item, name: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Название на казахском</Form.Label>
                        <Form.Control type="text" placeholder="Название"
                            value={item.nameKZ} onChange={(e) => setItem({ ...item, nameKZ: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Название на английском</Form.Label>
                        <Form.Control type="text" placeholder="Название"
                            value={item.nameEN} onChange={(e) => setItem({ ...item, nameEN: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Описание</Form.Label>
                        <Form.Control type="text" placeholder="Краткое описание"
                            value={item.description} onChange={(e) => setItem({ ...item, description: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Описание на казахском</Form.Label>
                        <Form.Control type="text" placeholder="Краткое описание"
                            value={item.descriptionKZ} onChange={(e) => setItem({ ...item, descriptionKZ: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Описание на английском</Form.Label>
                        <Form.Control type="text" placeholder="Краткое описание"
                            value={item.descriptionEN} onChange={(e) => setItem({ ...item, descriptionEN: e.target.value })}
                        />
                    </Form.Group>

                    {error !== '' && <p className='text-danger'>{error}</p>}
                    <Button type={"submit"}>Сохранить</Button>

                </Form>
            </Modal.Body>
        </Modal>
    </>
}

export default DivisionCreateModal