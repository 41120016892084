import React, { useState } from 'react';
import { ICandidateComment } from '../../../types/candidate/ICandidateComment';
import { Button, Form, ListGroup } from 'react-bootstrap';
import { convertUTCToDatestring } from '../../../helpers/convertUTCToDatestring';
import { IDictionaryItem } from '../../../types/IDictionaryItem';
import candidateService from '../../../api/candidate.api';
import { useStores } from '../../../stores';
import { useTranslation } from 'react-i18next'; // Импортируем хук для локализации

type Props = {
    item: ICandidateComment;
    types: IDictionaryItem[];
    update: () => void;
};

const CommentItem = ({ item, types, update }: Props) => {
    const { t } = useTranslation(); // Инициализируем хук для локализации

    const [editMode, setEditMode] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');
    const [commentTypeId, setCommentsTypeID] = useState<number>(0);
    const { authStore } = useStores();

    const handleDelete = (id: number, text: string) => {
        let result = window.confirm(t('comment.confirmDelete', { text: text })); // Локализованный текст подтверждения удаления
        if (result) {
            candidateService.deleteComment(id).then((response) => {
                update();
            }).catch((err) => {
                console.log(err);
            });
        }
    };

    const updateComment = (id: number) => {
        candidateService.updateComment(id, comment, commentTypeId).then((response) => {
            setComment('');
            setCommentsTypeID(0);
            setEditMode(false);
            update();
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <ListGroup.Item>
            <div className="d-flex justify-content-between">
                <div>
                    <small>{convertUTCToDatestring(item.date)}</small>
                    <p className='fw-bold mb-1'>{item.author.fullName} - {editMode ?
                        <Form.Select value={commentTypeId} onChange={(e) => setCommentsTypeID(Number(e.target.value))}>
                            {
                                types.map((item) => {
                                    return <option value={item.id}>{item.name}</option>;
                                })
                            }
                        </Form.Select> : item.type.name}</p>
                </div>
                {
                    item.author.email === authStore.user?.email && <div>
                        <Button variant='secondary' onClick={() => {
                            setComment(item.comment);
                            setCommentsTypeID(item.type.id);
                            setEditMode(true);
                        }} size='sm' className='me-2'><i className="fa-solid fa-pen"></i></Button>
                        <Button variant='danger' onClick={() => handleDelete(item.id, item.comment)} size='sm'><i className="fa-solid fa-trash"></i></Button>
                    </div>
                }
            </div>
            <div className="card card-body">
                {
                    editMode ?
                        <Form.Control as='textarea' value={comment} onChange={(e) => setComment(e.target.value)} />
                        : <p>{item.comment}</p>
                }
            </div>
            {
                editMode && <div className='mt-2'>
                    <Button onClick={() => updateComment(item.id)}>{t('comment.saveChanges')}</Button> {/* Локализованный текст кнопки сохранения изменений */}
                    <Button variant='secondary' className='ms-2'>{t('comment.cancel')}</Button> {/* Локализованный текст кнопки отмены */}
                </div>
            }

        </ListGroup.Item>
    );
};

export default CommentItem;
