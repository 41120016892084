import AuthStore from "./auth.store"
import FilterStore from "./filter.store"
import RouterStore from "./router.store"

class RootStore {
    authStore:AuthStore
    routerStore:RouterStore
    filterStore:FilterStore
    constructor(){
        this.authStore = new AuthStore()
        this.routerStore = new RouterStore()
        this.filterStore = new FilterStore()
    }
}

export default RootStore