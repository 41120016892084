export class ApplicationCreateRequest {
    position: string;
    workStartDate: string;
    salary: string;
    afterTax: boolean;
    reasonForHiringId: number;
    workContractTypeId: number;
    departmentId: number;
    divisionId: number;
    privilegeIds: number[];
    englishLevelId: number;
    educationDegreeId: number;
    additionalSkills: string;
    bonusTypeId:number;
    bonusValue:string;
    languages: { languageId: number, degreeId: number }[]
    amountOfWorkers: string;
    workScheduleTypeId: number;
    workExperienceTypeId: number;
    aboutCompanyForVacancy: string;
    problems: string;
    responsibilities: string;
    requirements: string;
    expectations: string;
    someWishes: string;
    whatWeOffer: string;
    opportunities: string;
    personalQualitiesIds: number[];
    destinationEmail:string
    constructor() {
        this.amountOfWorkers = '1';
        this.privilegeIds = [];
        this.englishLevelId = 0;
        this.educationDegreeId = 0;
        this.additionalSkills = "";
        this.afterTax = false;
        this.position = "";
        this.workStartDate = "";
        this.salary = "";
        this.reasonForHiringId = 0;
        this.workContractTypeId = 0;
        this.departmentId = 0;
        this.divisionId = 0;
        this.bonusTypeId = 0;
        this.bonusValue = "";
        this.languages = [];
        this.workScheduleTypeId = 0;
        this.workExperienceTypeId = 0;
        this.aboutCompanyForVacancy = "";
        this.problems = "";
        this.responsibilities = "";
        this.requirements = "";
        this.expectations = "";
        this.someWishes = "";
        this.whatWeOffer = "";
        this.opportunities = "";
        this.personalQualitiesIds = [];
        this.destinationEmail = ''
    }
}