import React from 'react';
import { useTranslation } from 'react-i18next'; // Импортируем хук для локализации

type Props = {
    status: string;
};

const StatusBadge = (props: Props) => {
    const { t } = useTranslation(); // Инициализируем хук для локализации

    const statusTheme = () => {
        switch (props.status) {
            case t('status.open'): // Заменяем статичные значения на локализированные
                return "status-badge-primary";
            case t('status.closed'): // Заменяем статичные значения на локализированные
                return "status-badge-danger";
            default:
                return "";
        }
    };

    return (
        <div className={"status-badge " + statusTheme()}>
            {props.status}
        </div>
    );
};

export default StatusBadge;
