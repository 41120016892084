import React, { useState } from 'react'
import { IUserShort } from '../../types/user/IUserShort'
import { Button, Modal } from 'react-bootstrap'
import { correctPhotoLink } from '../../helpers/correctPhotoLink'

type Props = {
    user: IUserShort
}

const UserShortInfo = (props: Props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='user-list-item-container'>
                <div className="user-list-item">
                    <img src={correctPhotoLink(props.user.photo)} alt="" />
                    <p>{props.user.fullName}</p>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UserShortInfo