import React, { useEffect, useState } from 'react'
import { useStores } from '../../stores'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../helpers/routeConsts'
import { useTranslation } from 'react-i18next'; // Импортируем функцию перевода

type Props = {}

const NeedActivation = (props: Props) => {
    const { t } = useTranslation(); // Используем функцию перевода
    const { authStore } = useStores()
    const navigator = useNavigate()

    useEffect(() => {
        if (authStore.user?.company?.isActive) {
            navigator(ROUTES.HOME)
        }
    }, [])
    return (
        <div className='need-company-container sign-in-container'>
            <div className="white-block" style={{ width: 600, minHeight: 400 }}>
                <h1 className='mb-5'>{t('needActivation.title')}</h1>
                <h3>
                    {t('needActivation.inactiveCompanyMessage')}
                </h3>
                <p>
                    {t('needActivation.contactCompanyAdminMessage')}
                </p>

                <p>{t('needActivation.contactOurManagersMessage')}</p>

                <a href={"mailto:" + t('needActivation.contactEmail')} className='btn btn-primary'><i className="fa-solid fa-envelope"></i> {t('needActivation.contactEmail')}</a>

                <Link className='btn btn-outline-primary mt-3' to={ROUTES.LOGIN}>{t('needActivation.loginWithAnotherAccountButton')}</Link>
            </div>
        </div>
    )
}

export default NeedActivation
