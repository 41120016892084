import React, { useEffect, useState } from 'react'
import CompanyBindingForm from '../../components/forms/auth/CompanyBindingForm'
import { ROUTES } from '../../helpers/routeConsts'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useStores } from '../../stores'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'; // Импортируем функцию перевода

type Props = {}

const NeedCompany = (props: Props) => {
    const { t } = useTranslation(); // Используем функцию перевода

    const { authStore } = useStores()
    console.log(toJS(authStore.user))

    const navigator = useNavigate()

    const [hasCompany, setHasCompany] = useState<boolean>(false)

    useEffect(() => {
        if (authStore.user && authStore.user.company && authStore.user.company.id !== 0) {
            setHasCompany(true)
            if (authStore.user?.company?.confirmed) {
                navigator(ROUTES.HOME)
            }
        } else {
            setHasCompany(false)
        }
    }, [authStore.user])
    return <div className='need-company-container sign-in-container'>
        <div className="white-block" style={{width:600, minHeight:600}}>
            <h1 className='mb-5'>{t('needCompany.welcomeTitle')}</h1>
            {
                hasCompany ?
                    <>
                        <h2 className='my-4 fw-bold'><i className="fa-solid fa-hourglass-start"></i> {t('needCompany.registrationPendingMessage')}</h2>
                        <h4 className="mb-4">{t('needCompany.registrationConfirmationMessage')}</h4>
                        <p>{t('needCompany.questionsMessage')} <br /> <a className='h4' href={"mailto:" + t('needCompany.emailAddress')}>{t('needCompany.emailAddress')}</a>
                        </p>
                    </> :
                    <>
                        <h4>{t('needCompany.noCompanyMessage')}</h4>
                        <CompanyBindingForm />
                    </>
            }

            <div className="sign-in-with-external">
                <div className="title">
                    <div className="line"></div>
                    <div>{t('needCompany.loginWithAnotherAccount')}</div>
                    <div className="line"></div>
                </div>
            </div>

            <Link className='btn btn-outline-primary' to={ROUTES.LOGIN}>{t('needCompany.loginButtonLabel')}</Link>
        </div>
    </div>
}

export default observer(NeedCompany)
