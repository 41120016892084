import React, { useEffect, useState } from 'react'
import { CompanyUpdateRequest } from '../../types/company/CompanyUpdateRequest'
import { Button, Form } from 'react-bootstrap'
import { ICompanyInfo } from '../../types/company/ICompanyInfo'
import companyService from '../../api/company.api'
import { useStores } from '../../stores'
import ReactInputMask from 'react-input-mask'
import { useTranslation } from 'react-i18next'; // Импортируем хук для перевода текста

type Props = {
    company: ICompanyInfo
}

const ChangeInfoForm = (props: Props) => {
    const { t } = useTranslation(); // Хук для перевода текста

    const {authStore} = useStores()

    const [formValues, setFormValues] = useState<CompanyUpdateRequest>({
        ...props.company
    })

    const hanldeForm = (e: React.FormEvent) => {
        e.preventDefault()
        companyService.updateCompanyInfo(formValues).then((response) => {
            console.log(response.data)
        })
    }

    useEffect(()=>{

    },[])

    return <form onSubmit={hanldeForm}>
        <div className="row">
            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>{t('ChangeInfoBIN')}</Form.Label> {/* Переводим метку поля "БИН" */}
                    <Form.Control type="text" value={props.company.bin} disabled />
                </Form.Group>
            </div>
            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>{t('ChangeInfoLegalName')}</Form.Label> {/* Переводим метку поля "Юридическое имя" */}
                    <Form.Control type="text" value={props.company.lawName} disabled />
                </Form.Group>
            </div>

            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>{t('ChangeInfoDisplayName')}</Form.Label> {/* Переводим метку поля "Отображаемое имя" */}
                    <Form.Control type="text" required value={formValues?.name} disabled={!authStore.hasRole('companyAdmin')} onChange={(e) => setFormValues((prev: any) => {
                        return {
                            ...prev,
                            name: e.target.value
                        }
                    })} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>{t('ChangeInfoDescription')}</Form.Label> {/* Переводим метку поля "Описание" */}
                    <Form.Control as={'textarea'} value={formValues?.description} disabled={!authStore.hasRole('companyAdmin')} onChange={(e) => setFormValues((prev: any) => {
                        return {
                            ...prev,
                            description: e.target.value
                        }
                    })} />
                </Form.Group>

            </div>

            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>{t('ChangeInfoPhone')}</Form.Label> {/* Переводим метку поля "Телефон" */}
                    <Form.Control type="text" 
                    required value={formValues?.phone}
                    as={ReactInputMask} mask="+7 (999) 999-99-99"
                     disabled={!authStore.hasRole('companyAdmin')} 
                     onChange={(e) => setFormValues((prev: any) => {
                        return {
                            ...prev,
                            phone: e.target.value
                        }
                    })} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>{t('ChangeInfoAddress')}</Form.Label> {/* Переводим метку поля "Адрес" */}
                    <Form.Control type="text" required value={formValues?.address} disabled={!authStore.hasRole('companyAdmin')} onChange={(e) => setFormValues((prev: any) => {
                        return {
                            ...prev,
                            address: e.target.value
                        }
                    })} />
                </Form.Group>
            </div>
        </div>
        {
            authStore.hasRole('companyAdmin') && <Button variant="primary" type="submit">{t('ChangeInfoSaveChangesButton')}</Button> 
        }
    </form>
}

export default ChangeInfoForm
