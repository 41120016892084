import React from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { useStores } from '../../../stores'
import { getCurrentDate } from '../../../helpers/getCurrentDate'
import UserDropdown from './UserDropdown'
import Finder from './Finder'
import Notifications from './Notifications'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

type Props = {}

const Nav = (props: Props) => {

    const { i18n, t } = useTranslation();

    const changeLanguage = (lng: string, e: React.MouseEvent) => {
        e.preventDefault()
        i18n.changeLanguage(lng);
    };

    const { authStore } = useStores()

    return <nav className='shadow-sm'>
        <div className="nav-container">
            <a href="/">
                <div className="brand">
                    <img src="/images/logo.png" alt="" style={{width:100}}/>
                </div>
            </a>
            <div className="d-flex justify-content-between align-items-center flex-grow-1">
                <Finder/>
                <ul>
                    {/* <li>
                    <Dropdown>
                            <Dropdown.Toggle variant={'outline-primary'} id="dropdown-basic">
                                <i className="fa-solid fa-globe"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={(e) => changeLanguage('kz', e)}>KZ</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => changeLanguage('ru', e)}>RU</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={(e) => changeLanguage('en', e)}>EN</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li> */}
                    <li>
                        <div className='btn' style={{ cursor: 'n' }}>{getCurrentDate()}</div>
                    </li>
                    <li>
                        <Notifications/>
                    </li>
                    <li>
                        <UserDropdown />
                    </li>
                </ul>
            </div>
        </div>
    </nav>
}

export default observer(Nav)