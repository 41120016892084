import React, { useEffect, useState } from 'react'
import { INotificationMessage } from '../../components/layouts/main/Notifications';
import notificationService from '../../api/notification.api';
import { socket } from '../../App';
import { ListGroup } from 'react-bootstrap';
import { convertUTCToDatestring } from '../../helpers/convertUTCToDatestring';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {}

const Notifications = (props: Props) => {

    const [list, setList] = useState<INotificationMessage[]>([]);
    const update = () => {
        notificationService.getNotifications().then(response => {
            setList(response.data)
        }).catch(e => console.log(e))
    }

    socket.on('chat message', (msg: string) => {
        update()
    });

    useEffect(() => {
        update()
    }, []);

    const {t} = useTranslation()

    const icons: any = {
        'vacancy-create':'https://img.icons8.com/pulsar-color/48/add-list.png',
        'application-create':'https://img.icons8.com/pulsar-color/48/add-list.png',
        'meeting-date':'https://img.icons8.com/pulsar-color/48/user-group-man-woman.png',
        'new-comment':"https://img.icons8.com/pulsar-color/48/comments.png",
        "step-complete":"https://img.icons8.com/pulsar-color/48/checked-2.png",
        "step-failed":"https://img.icons8.com/pulsar-color/48/multiply-2.png"
    }
    return (
        <>
            <h3>{t("notifications")}</h3>

            <div className="white-form-block mt-4 pt-4">
                <ListGroup>
                    {
                        list.map((item, index) => {
                            return (
                                <ListGroup.Item key={index}>
                                    <Link to={item.link} style={{ textDecoration: 'none' }}>
                                    <div className="notification-page-list-item">
                                        <div>
                                            <img src={icons[item.type]} alt="" />
                                        </div>
                                        <div>
                                            <p className="mb-0"><b>{item.author}</b> : {item.action}</p>
                                            <small>{convertUTCToDatestring(item.date)}</small>
                                        </div>
                                    </div>
                                    </Link>
                                </ListGroup.Item>
                            )
                        })
                    }
                </ListGroup>
            </div>
        </>
    )
}

export default Notifications