import React, { useState } from 'react'
import { Dropdown, ListGroup, Modal } from 'react-bootstrap'
import { ICompanyEmployee } from '../../types/company/ICompanyEmployee'
import companyService from '../../api/company.api'
import { IRole } from '../../types/company/IRole'
import { useTranslation } from 'react-i18next'; // Добавляем импорт для i18next

type Props = {
    employee: ICompanyEmployee,
    update: () => void
}

const ManageRoles = (props: Props) => {

    const { t } = useTranslation(); // Используем хук i18next для перевода текста

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        update()
        setShow(true);
        console.log(props.employee)
    }

    const [list, setList] = useState<IRole[]>([])

    const update = () => {
        companyService.getRoles().then((response) => {
            setList(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const setRole = (roleId: number, checked: boolean) => {
        companyService.toggleRole({
            userId: props.employee.id,
            roleId,
            checked
        }).then((response) => {
            props.update()
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            <Dropdown.Item onClick={handleShow}>{t('ManageRolesButton')}</Dropdown.Item> {/* Используем хук i18next для перевода текста кнопки */}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('ManageRolesTitle')}</Modal.Title> {/* Используем хук i18next для перевода заголовка модального окна */}
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {
                            list.map((item) => {
                                return <ListGroup.Item key={item.id}>
                                    <input type="checkbox"
                                        defaultChecked={props.employee.roles.some(role => role === item.description)}
                                        onChange={(e) => setRole(item.id, e.target.checked)}
                                    /> {item.description}
                                </ListGroup.Item>
                            })
                        }
                    </ListGroup>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ManageRoles
    