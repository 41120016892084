import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { ICompanyEmployee } from '../../types/company/ICompanyEmployee'
import ManageRoles from './ManageRoles'
import Unassign from './Unassign'

type Props = {
    employee: ICompanyEmployee,
    update: () => void
}

const EmployeeOperations = (props: Props) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                <i className="fa-solid fa-ellipsis-vertical"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <ManageRoles employee={props.employee} update={props.update} />
                <Unassign employee={props.employee} update={props.update} />
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default EmployeeOperations