import React, { useEffect, useState } from 'react'
import candidateService from '../../../api/candidate.api'
import { ICandidateVacancyStepItem } from '../../../types/candidate/ICandidateVacancyStepItem'
import ResumeVacancyStepsModal from '../../../components/modals/ResumeVacancyStepsModal'
import CompleteVacancyStepModal from '../../../components/modals/CompleteVacancyStepModal'
import FailedVacancyStepModal from '../../../components/modals/FailedVacancyStepModal'

type Props = {
    vacancyId: number
    resumeId: number
    update: () => void
}

const FailedStep = (props: Props) => {


    const [list, setList] = useState<ICandidateVacancyStepItem[]>([]);

    const update = () => {
        candidateService.getVacancySteps(props.vacancyId, props.resumeId).then((response) => {
            setList(response.data)
        })
    }

    useEffect(() => {
        update()
    }, [props])


    return <FailedVacancyStepModal step={list.filter(m => !m.complete)[0]} update={props.update} vacancyId={props.vacancyId}/>
}

export default FailedStep