import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { VacancyCreateRequest } from '../../types/vacancy/VacancyCreateRequest'
import dictionaryService from '../../api/dictionary.api'
import vacansyService from '../../api/vacancy.api'
import applicationService from '../../api/application.api'
import { IDictionaryItem } from '../../types/IDictionaryItem'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ResultModal from '../modals/ResultModal'
import ResultModalContent from '../modals/ResultModalContent'
import { ROUTES } from '../../helpers/routeConsts'
import { ERROR_MESSAGES } from '../../helpers/errorMessage'
import { socket } from '../../App'
import { useStores } from '../../stores'

type Props = {
    id: number | null
}

const VacancyCreateForm = (props: Props) => {

    const tempRequest: VacancyCreateRequest = localStorage.getItem('vacancyCreateRequest') ? JSON.parse(localStorage.getItem('vacancyCreateRequest')!) : new VacancyCreateRequest()
    const tempPriveleges: Set<number> = localStorage.getItem('vacancyCreateRequestPriveleges') ? new Set(JSON.parse(localStorage.getItem('vacancyCreateRequestPriveleges')!)) : new Set<number>()
    const tempPersonalQualities: Set<number> = localStorage.getItem('vacancyCreateRequestPersonalQualities') ? new Set(JSON.parse(localStorage.getItem('vacancyCreateRequestPersonalQualities')!)) : new Set<number>()

    const [formValues, setFormValues] = useState<VacancyCreateRequest>(tempRequest)
    const [selectedPriveleges, setSelectedPriveleges] = useState<Set<number>>(tempPriveleges)
    const [selectedPersonalQualities, setSelectedPersonalQualities] = useState<Set<number>>(tempPersonalQualities)

    const [dictionaries, setDictionaries] = useState<{ [key: string]: IDictionaryItem[] }>({
        privileges: [],
        personalQualities: [],
        reasonsForHiring: [],
        workContractTypes: [],
        departments: [],
        divisions: [],
        englishLevels: [],
        educationDegrees: [],
        workScheduleTypes: [],
        workExperienceTypes: [],
        languages: [],
        languageDegrees: [],
        bonusTypes: []
    })

    const handleSaveTempRequest = (request: VacancyCreateRequest) => {
        localStorage.setItem('vacancyCreateRequest', JSON.stringify(request))
    }

    //--
    const [showResult, setShowResult] = useState<boolean>(false)
    const [resultModalContent, setResultModalContent] = useState<React.ReactNode>(<></>)
    const [link, setLink] = useState<string | null>(null)

    const handleCloseResult = () => {
        setShowResult(false)
    }

    const handleShowResult = () => {
        setShowResult(true)
    }

    const { authStore } = useStores()
    //

    const handleForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        vacansyService.create({
            ...formValues,
            salary: String(formValues.salary).replaceAll(' ', '')
        }).then((response) => {
            if (response.status === 200) {
                socket.emit('chat message', JSON.stringify({
                    email: authStore.user?.email,
                    action: 'Создана вакансия на должность ' + formValues.position,
                    link: '/vacancy-info/' + response.data.id,
                    type: 'vacancy-create',
                }));
                setResultModalContent(<ResultModalContent type="success" message={"Вакансия успешно создана"} />)
                setLink(ROUTES.VACANCY + "/open")
                handleShowResult()

                localStorage.setItem("applicationCreateRequest", JSON.stringify(new VacancyCreateRequest()))
                localStorage.setItem("applicationCreateRequestPriveleges", JSON.stringify([]))
                localStorage.setItem("applicationCreateRequestPersonalQualities", JSON.stringify([]))
            }
        }).catch((e: any) => {
            setResultModalContent(<ResultModalContent type="error" message={ERROR_MESSAGES[e.response.data]} />)
            handleShowResult()
        })
    }

    useEffect(() => {
        if (props.id) {
            applicationService.findForVacancy(props.id).then((response) => {
                setSelectedPriveleges(new Set(response.data.privilegeIds))
                localStorage.setItem('vacancyCreateRequestPriveleges', JSON.stringify(response.data.privilegeIds))
                localStorage.setItem('vacancyCreateRequestPersonalQualities', JSON.stringify(response.data.personalQualitiesIds))
                setSelectedPersonalQualities(new Set(response.data.personalQualitiesIds))
                dictionaryService.findAllByParentId('division', response.data.departmentId).then((response) => {
                    setDictionaries((prev) => {
                        return {
                            ...prev,
                            divisions: response.data
                        }
                    })
                })
                setFormValues({
                    ...formValues,
                    ...response.data,
                    salary: String(response.data.salary).replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                    applicationId: props.id
                })
                handleSaveTempRequest({
                    ...formValues,
                    ...response.data,
                    salary: String(response.data.salary).replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                    applicationId: props.id
                })
            })
        }

        dictionaryService.findAll('privilege').then((response) => {
            setDictionaries((prev) => {
                return {
                    ...prev,
                    privileges: response.data
                }
            })
        })

        dictionaryService.findAll('reasonForHiring').then((response) => {
            setDictionaries((prev) => {
                return {
                    ...prev,
                    reasonsForHiring: response.data
                }
            })
        })

        dictionaryService.findAll('workContractType').then((response) => {
            setDictionaries((prev) => {
                return {
                    ...prev,
                    workContractTypes: response.data
                }
            })
        })

        dictionaryService.findAll('language').then((response) => {
            setDictionaries((prev) => ({
                ...prev,
                languages: response.data.map((item: IDictionaryItem) => item)
            }))
        })

        dictionaryService.findAll('languageDegree').then((response) => {
            setDictionaries((prev) => ({
                ...prev,
                languageDegrees: response.data.map((item: IDictionaryItem) => item)
            }))
        })

        dictionaryService.findAll('department').then((response) => {
            setDictionaries((prev) => {
                return {
                    ...prev,
                    departments: response.data
                }
            })
        })

        dictionaryService.findAll('englishLevel').then((response) => {
            setDictionaries((prev) => {
                return {
                    ...prev,
                    englishLevels: response.data
                }
            })
        })

        dictionaryService.findAll('educationDegree').then((response) => {
            console.log(dictionaries, response.data)
            setDictionaries((prev) => {
                return {
                    ...prev,
                    educationDegrees: response.data
                }
            })
        })

        dictionaryService.findAll('workScheduleType').then((response) => {
            setDictionaries((prev) => {
                return {
                    ...prev,
                    workScheduleTypes: response.data
                }
            })
        })

        dictionaryService.findAll('workExperienceType').then((response) => {
            setDictionaries((prev) => {
                return {
                    ...prev,
                    workExperienceTypes: response.data
                }
            })
        })

        dictionaryService.findAll('personalQuality').then((response) => {
            setDictionaries((prev) => {
                return {
                    ...prev,
                    personalQualities: response.data
                }
            })
        })

        dictionaryService.findAll('bonusType').then((response) => {
            setDictionaries((prev) => ({
                ...prev,
                bonusTypes: response.data.map((item: IDictionaryItem) => item)
            }))
        })

    }, [])

    return (
        <>
            <ResultModal
                show={showResult}
                link={link}
                title={"Создание вакансии"}
                handleClose={handleCloseResult}
                children={resultModalContent}
            />

            <form onSubmit={(e) => handleForm(e)} className="white-form-block mt-4 pt-4">
                <div className="form-group mb-5 d-flex">
                    <label><span className="text-danger h5">*</span> Должность</label>
                    <textarea className="form-control"
                        rows={2}
                        required={true}
                        placeholder=''
                        value={formValues.position}
                        onChange={(e) => {
                            setFormValues((prev) => ({
                                ...prev,
                                position: e.target.value
                            }))
                            handleSaveTempRequest({
                                ...formValues,
                                position: e.target.value
                            })
                        }}></textarea>

                </div>
                <div className="form-group mb-5 d-flex">
                    <label><span className="text-danger h5">*</span> Количество работников</label>

                    <input type="number" className="form-control flex-grow-1"
                        placeholder=''
                        required={true}
                        value={formValues.amountOfWorkers}
                        min={1}
                        onChange={(e) => {
                            setFormValues((prev) => ({
                                ...prev,
                                amountOfWorkers: e.target.value
                            }))
                            handleSaveTempRequest({
                                ...formValues,
                                amountOfWorkers: e.target.value
                            })
                        }} />
                </div>

                <div className="form-group mb-5 d-flex">
                    <label><span className="text-danger h5">*</span> Зарплата</label>
                    <div className="d-flex align-items-center" style={{ gap: 20 }}>
                        <input type="text" className="form-control flex-grow-1"
                            placeholder=''
                            required={true}
                            value={formValues.salary}
                            onChange={(e) => {
                                setFormValues((prev) => ({
                                    ...prev,
                                    salary: e.target.value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                }))
                                handleSaveTempRequest({
                                    ...formValues,
                                    salary: e.target.value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                })
                            }} />


                        <div className="form-check flex-grow-1">
                            <input className="form-check-input" type="checkbox"
                                checked={formValues.afterTax}
                                onChange={(e) => {
                                    setFormValues((prev) => ({
                                        ...prev,
                                        afterTax: e.target.checked
                                    }))
                                    handleSaveTempRequest({
                                        ...formValues,
                                        afterTax: e.target.checked
                                    })
                                }}
                                id="isAfterTax" />
                            <label className="form-check-label" htmlFor="isAfterTax">
                                После вычета налогов
                            </label>
                        </div>
                    </div>
                </div>

                <div className="form-group mb-5 d-flex">
                    <label><span className="text-danger h5">*</span> Бонусы</label>
                    <div className="d-flex align-items-center flex-grow-1" style={{ gap: 20 }}>
                        <select value={formValues.bonusTypeId}
                            className='form-select'
                            style={{ maxWidth: 200 }}
                            required={true}
                            onChange={(e) => {
                                setFormValues((prev) => ({ ...prev, bonusTypeId: +e.target.value }))
                                handleSaveTempRequest({
                                    ...formValues,
                                    bonusTypeId: +e.target.value
                                })
                            }}>
                            <option value="">-Выберите тип бонусов-</option>
                            {
                                dictionaries.bonusTypes.map((bonusType) => {
                                    return <option key={bonusType.id} value={bonusType.id}>{bonusType.name}</option>
                                })
                            }
                        </select>
                        <input type='text' className='form-control flex-grow-1'
                            value={formValues.bonusValue}
                            placeholder='размер бонуса или описание бонуса'
                            onChange={(e) => {
                                setFormValues((prev) => ({ ...prev, bonusValue: e.target.value }))
                                handleSaveTempRequest({
                                    ...formValues,
                                    bonusValue: e.target.value
                                })
                            }} />
                    </div>
                </div>

                <div className="form-group mb-5 d-flex" >
                    <label><span className="text-danger h5">*</span> Причина найма</label>
                    <select className="form-select" id="groupSelect"
                        value={formValues.reasonForHiringId}
                        required={true}
                        onChange={(e) => {
                            setFormValues((prev) => ({
                                ...prev,
                                reasonForHiringId: +e.target.value
                            }))
                            handleSaveTempRequest({
                                ...formValues,
                                reasonForHiringId: +e.target.value
                            })
                        }}
                        aria-label="group label select example">
                        <option value="">-Выберите-</option>
                        {
                            dictionaries.reasonsForHiring.map((reason) => <option key={reason.id} value={reason.id}>{reason.name}</option>)
                        }
                    </select>

                </div>

                <div className="form-group mb-5 d-flex" >
                    <label><span className="text-danger h5">*</span> Вид договора</label>
                    <select className="form-select" id="groupSelect"
                        value={formValues.workContractTypeId}
                        required={true}
                        onChange={(e) => {
                            setFormValues((prev) => ({
                                ...prev,
                                workContractTypeId: +e.target.value
                            }))
                            handleSaveTempRequest({
                                ...formValues,
                                workContractTypeId: +e.target.value
                            })
                        }}
                        aria-label="group label select example">
                        <option value="">-Выберите-</option>
                        {
                            dictionaries.workContractTypes.map((workContractType) => <option key={workContractType.id} value={workContractType.id}>{workContractType.name}</option>)
                        }
                    </select>

                </div>

                <div className="form-group mb-5 d-flex">
                    <label>Описание компании для позиции</label>
                    <div className='flex-grow-1' style={{ minHeight:300 }}>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formValues.aboutCompanyForVacancy}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setFormValues((prev) => ({
                                    ...prev,
                                    aboutCompanyForVacancy: data
                                }))
                                handleSaveTempRequest({
                                    ...formValues,
                                    aboutCompanyForVacancy: data
                                })
                            }}
                        />
                    </div>
                </div>

                <div className="form-group mb-5 d-flex">
                    <label>Проблемы ради которых создается вакансия</label>
                    <div className='flex-grow-1' style={{ minHeight:300 }}>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formValues.problems}

                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setFormValues((prev) => ({
                                    ...prev,
                                    problems: data
                                }))
                                handleSaveTempRequest({
                                    ...formValues,
                                    problems: data
                                })
                            }}
                        />
                    </div>
                </div>

                <div className="form-group mb-5 d-flex">
                    <label><span className="text-danger h5">*</span> Обязанности</label>
                    <div className='flex-grow-1' >
                        <textarea className='form-control' required={true}
                            value={formValues.responsibilities}
                            rows={6}
                            onChange={(e) => {
                                setFormValues((prev) => ({
                                    ...prev,
                                    responsibilities: e.target.value
                                }))
                                handleSaveTempRequest({
                                    ...formValues,
                                    responsibilities: e.target.value
                                })
                            }}
                        ></textarea>
                    </div>
                </div>

                <div className="form-group mb-5 d-flex">
                    <label><span className="text-danger h5">*</span> Требования</label>
                    <div className='flex-grow-1' >
                        <textarea className='form-control' required={true}
                            value={formValues.requirements}
                            rows={6}
                            onChange={(e) => {
                                setFormValues((prev) => ({
                                    ...prev,
                                    requirements: e.target.value
                                }))
                                handleSaveTempRequest({
                                    ...formValues,
                                    requirements: e.target.value
                                })
                            }}
                        ></textarea>
                    </div>
                </div>

                <div className="form-group mb-5 d-flex">
                    <label>Ожидания</label>
                    <div className='flex-grow-1' style={{ minHeight:300 }}>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formValues.expectations}

                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setFormValues((prev) => ({
                                    ...prev,
                                    expectations: data
                                }))
                                handleSaveTempRequest({
                                    ...formValues,
                                    expectations: data
                                })
                            }}
                        />
                    </div>
                </div>

                <div className="form-group mb-5 d-flex">
                    <label><span className="text-danger h5">*</span> Будет плюсом</label>
                    <div className='flex-grow-1' >
                        <textarea className='form-control' required={true}
                            value={formValues.someWishes}
                            rows={6}
                            onChange={(e) => {
                                setFormValues((prev) => ({
                                    ...prev,
                                    someWishes: e.target.value
                                }))
                                handleSaveTempRequest({
                                    ...formValues,
                                    someWishes: e.target.value
                                })
                            }}
                        ></textarea>
                    </div>
                </div>

                <div className="form-group mb-5 d-flex">
                    <label><span className="text-danger h5">*</span> Предложения от нас</label>
                    <div className='flex-grow-1'>
                        <textarea className='form-control' required={true}
                            value={formValues.whatWeOffer}
                            rows={6}
                            onChange={(e) => {
                                setFormValues((prev) => ({
                                    ...prev,
                                    whatWeOffer: e.target.value
                                }))
                                handleSaveTempRequest({
                                    ...formValues,
                                    whatWeOffer: e.target.value
                                })
                            }}
                        ></textarea>
                    </div>
                </div>

                <div className="form-group mb-5 d-flex">
                    <label><span className="text-danger h5">*</span> Карьерные возможности</label>
                    <div className='flex-grow-1'>
                        <textarea className='form-control' required={true}
                            value={formValues.opportunities}
                            rows={6}
                            onChange={(e) => {
                                setFormValues((prev) => ({
                                    ...prev,
                                    opportunities: e.target.value
                                }))
                                handleSaveTempRequest({
                                    ...formValues,
                                    opportunities: e.target.value
                                })
                            }}
                        ></textarea>
                    </div>
                </div>




                <div className="form-group d-flex mb-5" >
                    <label><span className="text-danger h5">*</span> Департамент</label>
                    <select className="form-select" id="groupSelect"
                        value={formValues.departmentId}
                        required={true}
                        onChange={(e) => {
                            setFormValues((prev) => ({
                                ...prev,
                                departmentId: +e.target.value
                            }))
                            handleSaveTempRequest({
                                ...formValues,
                                departmentId: +e.target.value
                            })
                            dictionaryService.findAllByParentId('division', +e.target.value).then((response) => {
                                setDictionaries((prev) => {
                                    return {
                                        ...prev,
                                        divisions: response.data
                                    }
                                })
                            })
                        }}
                        aria-label="group label select example">
                        <option value="">-Выберите-</option>
                        {
                            dictionaries.departments.map(department => (
                                <option key={department.id} value={department.id}>{department.name}</option>
                            ))
                        }
                    </select>

                </div>

                <div className="form-group d-flex mb-5" >
                    <label>Отдел</label>
                    <select className="form-select" id="groupSelect"
                        value={formValues.divisionId}
                        onChange={(e) => {
                            setFormValues((prev) => ({
                                ...prev,
                                divisionId: +e.target.value
                            }))
                            handleSaveTempRequest({
                                ...formValues,
                                divisionId: +e.target.value
                            })
                        }}
                        aria-label="group label select example">
                        <option value="">-Выберите-</option>
                        {
                            dictionaries.divisions.map(d => (
                                <option key={d.id} value={d.id}>{d.name}</option>
                            ))
                        }
                    </select>

                </div>


                <div className="form-group d-flex mb-5" >
                    <label><span className="text-danger h5">*</span> График работы</label>
                    <select className="form-select" id="groupSelect"
                        value={formValues.workScheduleTypeId}
                        required={true}
                        onChange={(e) => {
                            setFormValues((prev) => ({
                                ...prev,
                                workScheduleTypeId: +e.target.value
                            }))
                            handleSaveTempRequest({
                                ...formValues,
                                workScheduleTypeId: +e.target.value
                            })
                        }}
                        aria-label="group label select example">
                        <option value="">-Выберите-</option>
                        {
                            dictionaries.workScheduleTypes.map(w => (
                                <option key={w.id} value={w.id}>{w.name}</option>
                            ))
                        }
                    </select>

                </div>

                <div className="form-group d-flex mb-5" >
                    <label><span className="text-danger h5">*</span> Опыт работы</label>
                    <select className="form-select" id="groupSelect"
                        value={formValues.workExperienceTypeId}
                        required={true}
                        onChange={(e) => {
                            setFormValues((prev) => ({
                                ...prev,
                                workExperienceTypeId: +e.target.value
                            }))
                            handleSaveTempRequest({
                                ...formValues,
                                workScheduleTypeId: +e.target.value
                            })
                        }}
                        aria-label="group label select example">
                        <option value="">-Выберите-</option>
                        {
                            dictionaries.workExperienceTypes.map(w => (
                                <option key={w.id} value={w.id}>{w.name}</option>
                            ))
                        }
                    </select>

                </div>

                <div className="form-group d-flex mb-5" >
                    <label>Уровень английского</label>
                    <select className="form-select" id="groupSelect"
                        value={formValues.englishLevelId}
                        onChange={(e) => {
                            setFormValues((prev) => ({
                                ...prev,
                                englishLevelId: +e.target.value
                            }))
                            handleSaveTempRequest({
                                ...formValues,
                                englishLevelId: +e.target.value
                            })
                        }}
                        aria-label="group label select example">
                        <option value="">-Выберите-</option>
                        {
                            dictionaries.englishLevels.map(el => (
                                <option key={el.id} value={el.id}>{el.name}</option>
                            ))
                        }
                    </select>

                </div>

                <div className="fomr-group d-flex mb-5">
                    <label>Другие языки</label>
                    <ul className="list-group" id="languages">
                        {
                            formValues.languages.map((item, index) => {
                                return <li key={index} className="list-group-item d-flex">
                                    <div className="d-flex" style={{ gap: 10 }}>
                                        <select name="languageId" className='form-select' value={item.languageId} onChange={(e) => {
                                            setFormValues((prev) => ({
                                                ...prev,
                                                languages: prev.languages.map((item, i) => {
                                                    if (i === index) {
                                                        return {
                                                            ...item,
                                                            languageId: +e.target.value
                                                        }
                                                    }
                                                    return item
                                                })
                                            }))
                                            handleSaveTempRequest({
                                                ...formValues,
                                                languages: formValues.languages.map((item, i) => {
                                                    if (i === index) {
                                                        return {
                                                            ...item,
                                                            languageId: +e.target.value
                                                        }
                                                    }
                                                    return item
                                                })
                                            })
                                        }} style={{ width: 150 }} required={true}>
                                            <option value="">-Выберите язык-</option>
                                            {
                                                dictionaries.languages.map((language) => {
                                                    return <option key={language.id} value={language.id}>{language.name}</option>
                                                })
                                            }
                                        </select>
                                        <select name="languageDegreeId" className='form-select' value={item.degreeId} onChange={(e) => {
                                            setFormValues((prev) => ({
                                                ...prev,
                                                languages: prev.languages.map((item, i) => {
                                                    if (i === index) {
                                                        return {
                                                            ...item,
                                                            degreeId: +e.target.value
                                                        }
                                                    }
                                                    return item
                                                })
                                            }))
                                            handleSaveTempRequest({
                                                ...formValues,
                                                languages: formValues.languages.map((item, i) => {
                                                    if (i === index) {
                                                        return {
                                                            ...item,
                                                            degreeId: +e.target.value
                                                        }
                                                    }
                                                    return item
                                                })
                                            })
                                        }} required={true}>
                                            <option value="">-Выберите уровень-</option>
                                            {
                                                dictionaries.languageDegrees.map((degree) => {
                                                    return <option key={degree.id} value={degree.id}>{degree.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <Button variant='outline-secondary' className='ms-2' size='sm'
                                        onClick={() => {
                                            setFormValues((prev) => ({
                                                ...prev,
                                                languages: prev.languages.filter((item, i) => i !== index)
                                            }))
                                        }}
                                    ><i className="fa-solid fa-xmark"></i></Button>
                                </li>
                            })
                        }
                        <li className='list-group-item list-group-item-action' style={{ cursor: 'pointer' }} onClick={() => {
                            setFormValues((prev) => ({
                                ...prev,
                                languages: [...prev.languages, {
                                    languageId: 0,
                                    degreeId: 0
                                }]
                            }))
                        }}>+ Добавить язык</li>
                    </ul>
                </div>

                <div className="form-group d-flex mb-5" >
                    <label><span className="text-danger h5">*</span> Образование</label>
                    <select className="form-select" id="groupSelect"
                        value={formValues.educationDegreeId}
                        required={true}
                        onChange={(e) => {
                            setFormValues((prev) => ({
                                ...prev,
                                educationDegreeId: +e.target.value
                            }))
                            handleSaveTempRequest({
                                ...formValues,
                                educationDegreeId: +e.target.value
                            })
                        }}
                        aria-label="group label select example">
                        <option value="">-Выберите-</option>
                        {
                            dictionaries.educationDegrees.map(ed => (
                                <option key={ed.id} value={ed.id}>{ed.name}</option>
                            ))
                        }
                    </select>

                </div>



                <div className="form-group mb-5 d-flex">
                    <label className='form-label'><span className="text-danger h5">*</span> Льготы</label>
                    <ul className='list-group flex-grow-1'>
                        {
                            dictionaries.privileges.map((item, index) => {
                                return <li className='list-group-item' key={index}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id={"priv-" + item.id}
                                            checked={selectedPriveleges.has(item.id)}
                                            onChange={(e) => {

                                                setFormValues((prev) => {
                                                    const newSet = new Set(prev.privilegeIds);
                                                    if (e.target.checked) {
                                                        newSet.add(item.id)
                                                    } else {
                                                        newSet.delete(item.id)
                                                    }
                                                    setSelectedPriveleges(newSet)
                                                    localStorage.setItem('vacancyCreateRequestPriveleges', JSON.stringify(Array.from(newSet)))
                                                    return {
                                                        ...prev,
                                                        privilegeIds: Array.from(newSet)
                                                    }
                                                })

                                                const newSet = new Set(selectedPriveleges);
                                                if (e.target.checked) {
                                                    newSet.add(item.id)
                                                } else {
                                                    newSet.delete(item.id)
                                                }
                                                handleSaveTempRequest({
                                                    ...formValues,
                                                    privilegeIds: Array.from(newSet)
                                                })

                                            }}
                                        />
                                        <label className="form-check-label" htmlFor={"priv-" + item.id}>
                                            {item.name}
                                        </label>
                                    </div>
                                </li>
                            })
                        }
                    </ul>
                </div>

                <div className="form-group mb-5 d-flex">
                    <label className='form-label'><span className="text-danger h5">*</span> Личные качества</label>
                    <ul className='list-group flex-grow-1'>
                        {
                            dictionaries.personalQualities.map((item, index) => {
                                return <li className='list-group-item' key={index}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id={"priv-" + item.id}
                                            checked={selectedPersonalQualities.has(item.id)}
                                            onChange={(e) => {

                                                setFormValues((prev) => {
                                                    const newSet = new Set(prev.personalQualitiesIds);
                                                    if (e.target.checked) {
                                                        newSet.add(item.id)
                                                    } else {
                                                        newSet.delete(item.id)
                                                    }
                                                    setSelectedPersonalQualities(newSet)
                                                    localStorage.setItem('vacancyCreateRequestPersonalQualities', JSON.stringify(Array.from(newSet)))
                                                    return {
                                                        ...prev,
                                                        personalQualitiesIds: Array.from(newSet)
                                                    }
                                                })

                                                let newSet = new Set(formValues.personalQualitiesIds);
                                                if (e.target.checked) {
                                                    newSet.add(item.id)
                                                } else {
                                                    newSet.delete(item.id)
                                                }
                                                handleSaveTempRequest({
                                                    ...formValues,
                                                    personalQualitiesIds: Array.from(newSet)
                                                })

                                            }}
                                        />
                                        <label className="form-check-label" htmlFor={"priv-" + item.id}>
                                            {item.name}
                                        </label>
                                    </div>
                                </li>
                            })
                        }
                    </ul>
                </div>

                <div className="form-group mb-5 d-flex">
                    <label>Дополнительные навыки</label>
                    <textarea className="form-control"
                        rows={3}
                        placeholder=''
                        value={formValues.additionalSkills}
                        onChange={(e) => {
                            setFormValues((prev) => ({
                                ...prev,
                                additionalSkills: e.target.value
                            }))
                            handleSaveTempRequest({
                                ...formValues,
                                additionalSkills: e.target.value
                            })
                        }}></textarea>
                </div>


                <div className="d-flex mb-5">
                    <label htmlFor=""></label>
                    <Button type="submit" size={'lg'}>Создать вакансию</Button>
                    <Button type="button" onClick={() => {
                        setFormValues(new VacancyCreateRequest())
                        setSelectedPriveleges(new Set())
                        setSelectedPersonalQualities(new Set())
                    }} variant='secondary' size={'lg'} className='ms-2'>Сбросить</Button>
                </div>
            </form>
        </>
    )
}

export default VacancyCreateForm