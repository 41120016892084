import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { IDictionaryItem } from '../../../../types/IDictionaryItem';
import dictionaryService from '../../../../api/dictionary.api';

type Props = {
    item: IDictionaryItem,
    update: () => void
}

const DeleteModal = (props: Props) => {

    const [item, setItem] = useState<IDictionaryItem>(props.item)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDelete = () => {
        dictionaryService.delete('department', props.item.id).then((response: any) => {
            props.update()
            handleClose()
        })

    }

    return <>
        <a href="/" className='dropdown-item' onClick={(e) => {
            e.preventDefault()
            handleShow()
        }}>
            Удалить
        </a>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Удаление Департамента "{item.name}"</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-danger'>Вы действительно хотите удалить департамент "{item.name}"?</p>
                <p>Все связанные с ним данные будут удалены</p>

                <Button onClick={handleDelete} variant='danger'>Удалить</Button>

            </Modal.Body>
        </Modal>
    </>
}

export default DeleteModal