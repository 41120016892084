import React, { ChangeEvent, useRef, useState } from 'react'
import { IUserFull } from '../../types/user/IUserFull'
import { Button, Modal } from 'react-bootstrap'
import userService from '../../api/user.api'
import { useStores } from '../../stores'
import { observer } from 'mobx-react'
import companyService from '../../api/company.api'
import { useTranslation } from 'react-i18next'; // Импортируем хук для перевода текста

type Props = {
    update:()=>void
}

const ChangePhotoForm = (props: Props) => {
    const { t } = useTranslation(); // Хук для перевода текста

    const [show, setShow] = useState<boolean>(false);
    const {authStore} = useStores()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);


    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleForm = (event: React.FormEvent) => {
        event.preventDefault();
        companyService.updateCompanyLogo(selectedFile as File).then((response) => {
            if (response.status === 200) {
                authStore.setUser({
                    ...authStore.user!,
                    company: response.data
                })
                props.update()
                handleClose()
            }
        })
    };

    return (
        <>
            <div className="edit-user-photo" onClick={handleShow}>
                <i className="fa-solid fa-pen-to-square"></i>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('ChangePhotoModalTitle')}</Modal.Title> {/* Переводим заголовок модального окна */}
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleForm}>
                        <div className="form-group mb-3">
                            <label htmlFor="photo" className="form-label">{t('ChangePhotoInputLabel')}</label> {/* Переводим метку поля ввода файла */}
                            <input
                                required={true}
                                type="file"
                                className='form-control'
                                id="fileInput"
                                accept=".jpg, .jpeg, .png, .pdf, .webp" // specify accepted file types if needed
                                onChange={handleFileChange}
                            />
                        </div>
                        <Button type="submit">{t('ChangePhotoSaveButton')}</Button> {/* Переводим текст кнопки сохранения изменений */}
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default observer(ChangePhotoForm)
