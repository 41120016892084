import axiosInstance, { fileUploadInstance } from "."
import { IUserFull } from "../types/user/IUserFull"
import { UserUpdatePasswordDto } from "../types/user/UserUpdatePasswordDto"
import {IUserShort} from "../types/user/IUserShort";

type IUserRoles = 'admin' | 'companyAdmin' | 'hiring' | 'hrmanager'

class UserService {
    async findByEmail(email: string) {
        const response = await axiosInstance.get('/api/user/' + email)
        return response
    }

    async findByQuery(query: string) {
        const response = await axiosInstance.get('/api/user/search/' + query)
        return response
    }

    async findByQueryAndRole(query:string, role: string) {
        const response = await axiosInstance.get('/api/user/searchByQueryAndRole/' + query + '/' + role)
        return response
    }

    async findByRoles(roles: IUserRoles[]) {
        const response = await axiosInstance.post<IUserShort[]>( '/api/user/findByRoles/', {roles})
        return response
    }

    async updatePhoto(email:string, image:File) {
        try {
            let fd = new FormData();
            fd.append('email', email);
            fd.append('image', image);

            const response = await fileUploadInstance.put('/api/user/photo', fd);


            return response;
        } catch (error: any) {
            if (error?.response && error.response.data ) {
                throw new Error(error.response.data, { cause: error });
            } else {
                throw new Error('Failed to update photo. Please try again later.', { cause: error });
            }

        }
    }

    async update(fd:IUserFull) {
        const response = await axiosInstance.put('/api/user', {
            ...fd,
            secondName:fd.lastName
        })
        return response
    }

    async updatePassword(fd:UserUpdatePasswordDto) {
        const response = await axiosInstance.put('/api/user/password', fd)
        return response
    }
}

const userService = new UserService()

export default userService