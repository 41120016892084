import React from 'react'
import ApplicationEditForm from '../../components/forms/ApplicationEditForm'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'; // Импортируем функцию перевода

type Props = {}

const Edit = (props: Props) => {
    const { t } = useTranslation(); // Используем функцию перевода
    const {id} = useParams()
    return (
        <div>
            <h3>{t('edit.editTitle')}</h3>
            <p>{t('edit.requiredFields')}</p>
            <ApplicationEditForm id={Number(id)}/>
        </div>
    )
}

export default Edit
