import React, { useState } from 'react'
import { IVacancyStep } from '../../types/vacancy/IVacancyStep'
import { Button, Form, Modal } from 'react-bootstrap'
import vacansyService from '../../api/vacancy.api'

type Props = {
    step: IVacancyStep,
    update: () => void
}

const UpdateVacancyStepModal = (props: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formValues, setFormValues] = useState<IVacancyStep>(props.step)

    const handleForm = (e: React.FormEvent) => {
        e.preventDefault();

        vacansyService.updateStep(formValues).then((response) => {
            if (response.status === 200) {
                handleClose()
                props.update()
            }
        }).catch((e: any) => {
            console.log(e)
        })
    }

    return <>
        <button onClick={(e) => {
            handleShow()
        }} className={`dropdown-item`}>
            Редактировать
        </button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Редактировать этап</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleForm}>
                    <Form.Group className='mb-3'>
                        <Form.Label>Название этапа</Form.Label>
                        <Form.Control type="text" value={formValues.name} onChange={(e) => setFormValues((prev) => ({ ...prev, name: e.target.value }))} />
                    </Form.Group>

                    <Button type='submit'>Сохранить изменения</Button>
                </Form>
            </Modal.Body>
        </Modal>
    </>
}

export default UpdateVacancyStepModal