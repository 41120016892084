import React from 'react'
import ApplicationCreateForm from '../../components/forms/ApplicationCreateForm'
import { useTranslation } from 'react-i18next'; // Импортируем функцию перевода

type Props = {}

const Create = (props: Props) => {
    const { t } = useTranslation(); // Используем функцию перевода
    return (
        <div>
            <h3>{t('applicationCreate.createTitle')}</h3>
            <p><span className='text-danger h5'>*</span> {t('applicationCreate.requiredFields')}</p>
            <ApplicationCreateForm />
        </div>
    )
}

export default Create
