import axiosInstance from ".";
import { ILoginFormRequest } from "../types/auth/ILoginFormRequest";
import { IRegisterFormRequest } from "../types/auth/IRegisterFormRequest";
import { ResetPasswordRequest } from "../types/user/ResetPasswordRequest";


class AuthService {
    async login(formData: ILoginFormRequest) {
        const response = await axiosInstance.post('/api/auth/login', formData)
        return response
    }
    async refresh() {
        const response = await axiosInstance.get('/api/auth/refresh')
        return response
    }

    async register(formData: IRegisterFormRequest) {
        const response = await axiosInstance.post('/api/auth/registration', formData)
        return response
    }

    async refreshByGoogleToken(token:string) {
        const response = await axiosInstance.get('/api/auth/refreshByGoogleToken/'+token)
        return response
    }

    async getCompany() {
        const response = await axiosInstance.get('/api/auth/company')
        return response
    }

    async logout() {
        const response = await axiosInstance.get('/api/auth/logout')
        return response
    }

    async sendInviteCode(code:string) {
        const response = await axiosInstance.post('/api/auth/sendInviteCode', {code})
        return response
    }

    async resetPassword(email:string) {
        const response = await axiosInstance.post('/api/auth/resetPassword', {email})
        return response
    }
    async resetPasswordConfirm(fd:ResetPasswordRequest) {
        const response = await axiosInstance.post('/api/auth/resetPasswordPost', fd)
        return response
    }
}

const authService = new AuthService()
export default authService