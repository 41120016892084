import React, { useEffect, useState } from 'react'
import { Button, ListGroup } from 'react-bootstrap'
import { IApplicationGetFilter } from '../../types/application/IApplicationGetFilter'
import applicationService from '../../api/application.api'
import { IApplicationShort } from '../../types/application/IApplicationShort'
import { IUserShort } from '../../types/user/IUserShort'
import userService from '../../api/user.api'
import { useStores } from '../../stores'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'; // Импортируем функцию перевода

type Props = {
    status: string | undefined
    afterFilter: (data: IApplicationShort[]) => void
}

const Filter = (props: Props) => {
    const { t } = useTranslation(); // Используем функцию перевода
    const [show, setShow] = useState<boolean>(false)

    const { filterStore} = useStores()

    const [users, setUsers] = useState<IUserShort[]>([])

    const findUser = (query:string)=>{
        if(query.length>2) {
            userService.findByQuery(query).then((response) => {
                setUsers(response.data[0].map((u:any) =>{
                    return {
                        id: u.id,
                        fullName: u.firstName + ' ' + u.secondName,
                        email: u.email
                    }
                }))
            })
        } else {
            setUsers([])
        }
    }

    useEffect(() => {

        filterStore.setApplicationFilter({ ...filterStore.applicationFilter, status: props.status })
        update()

        document.addEventListener('click', (e) => {
            let target = e.target as HTMLElement
            let filterContainer = document.querySelector('.filter-container')
            let filterToggle = document.querySelector('#filter-toggle')
            if (!filterContainer?.contains(target)
                && !filterToggle?.contains(target)
                && filterToggle !== target
                ) {
                setShow(false)
            }
        })
    }, [props.status])

    const update = ()=>{
        applicationService.findAll({
            status:filterStore.applicationFilter.status !== '' ? filterStore.applicationFilter.status: undefined,
            from:filterStore.applicationFilter.from !== '' ? filterStore.applicationFilter.from: undefined,
            to:filterStore.applicationFilter.to !== '' ? filterStore.applicationFilter.to: undefined,
            authorEmail:filterStore.applicationFilter.authorEmail !== '' ? filterStore.applicationFilter.authorEmail: undefined,
            salaryFrom:filterStore.applicationFilter.salaryFrom !== '' ? filterStore.applicationFilter.salaryFrom: undefined,
            salaryTo:filterStore.applicationFilter.salaryTo !== '' ? filterStore.applicationFilter.salaryTo: undefined
        }).then((response) => {
            props.afterFilter(response.data[0])
        })
    }

    const handleForm = (e: React.FormEvent) => {
        e.preventDefault()
        setShow(false)
        update()
    }

    return (
        <>
            <div className="filter">
                <Button id="filter-toggle" onClick={() => setShow((prev) => (!prev))}>{t('filter.filterButton')}</Button>
                {
                    filterStore.usedApplicationFilter() && <Button className='ms-2' variant='secondary' onClick={() => {
                        filterStore.resetApplicationFilter()
                        update()
                    }}>{t('filter.resetFilterButton')}</Button>
                }
                {
                    show && <div className="filter-container shadow">
                        <h5>{t('filter.filterSearch')}</h5>
                        <form onSubmit={handleForm}>
                            <label className="form-label">{t('filter.creationDateLabel')}</label>
                            <div className="d-flex mb-3">
                                <input type="date" className="form-control"
                                    value={filterStore.applicationFilter.from} onChange={(e) => {
                                        filterStore.setApplicationFilter({ ...filterStore.applicationFilter, from: e.target.value })
                                    }} />
                                -
                                <input type="date" className="form-control"
                                    value={filterStore.applicationFilter.to} onChange={(e) => {
                                        filterStore.setApplicationFilter({ ...filterStore.applicationFilter, to: e.target.value })
                                    }}
                                />
                            </div>

                            <div className="form-group">
                            <label  className="form-label">{t('filter.authorLabel')}</label>
                            <input type="email" className="form-control mb-3"
                                value={filterStore.applicationFilter.authorEmail} onChange={(e) => {
                                    filterStore.setApplicationFilter({ ...filterStore.applicationFilter, authorEmail: e.target.value })
                                    findUser(e.target.value)
                                }}
                            />
                            {
                                users.length>0 && <ListGroup>
                                {
                                    users.map((user) => {
                                        return (
                                            <ListGroup.Item key={user.id} className="filter-users"  onClick={()=>{
                                                filterStore.setApplicationFilter({ ...filterStore.applicationFilter, authorEmail: user.email })
                                                setTimeout(()=>{
                                                    setUsers([])
                                                },100)
                                            }}>
                                                {user.fullName}
                                            </ListGroup.Item>
                                        )
                                    })
                                }
                            </ListGroup>
                            }
                            
                            </div>

                            <label className="form-label">{t('filter.salaryLabel')}</label>
                            <div className="d-flex">
                                <input type="number" className="form-control"
                                    value={filterStore.applicationFilter.salaryFrom} onChange={(e) => {
                                        filterStore.setApplicationFilter({ ...filterStore.applicationFilter, salaryFrom: e.target.value })
                                    }} />
                                -
                                <input type="number" className="form-control"
                                    value={filterStore.applicationFilter.salaryTo} onChange={(e) => {
                                        filterStore.setApplicationFilter({ ...filterStore.applicationFilter, salaryTo: e.target.value })
                                    }}
                                />
                            </div>

                            <Button type='submit' className='mt-2'>{t('filter.findButton')}</Button>
                            <Button type='button' variant='secondary' className='mt-2 ms-2' onClick={()=>{
                                filterStore.resetApplicationFilter()
                                update()
                            }}>{t('filter.resetButton')}</Button>
                        </form>
                    </div>
                }
            </div>
        </>
    )
}

export default observer(Filter)
