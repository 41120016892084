import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { UserUpdatePasswordDto } from '../../types/user/UserUpdatePasswordDto';
import { useNavigate, useParams } from 'react-router-dom';
import authService from '../../api/auth.api';
import { useTranslation } from 'react-i18next'; // Импортируем функцию перевода

type Props = {}

const ConfirmResetPassword = (props: Props) => {
    const { t } = useTranslation(); // Используем функцию перевода

    const { key } = useParams()

    const navigator = useNavigate()

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [formValues, setFormValues] = useState<UserUpdatePasswordDto>({
        email: key!,
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    })
    const [showNewPasswordConfirmed, setShowNewPasswordConfirmed] = useState(false);
    const handleForm = (event: React.FormEvent) => {
        event.preventDefault();
        authService.resetPasswordConfirm({
            key:key!,
            newPassword: formValues.newPassword,
        }).then((response)=>{
            navigator('/auth/login')
        }).catch((e)=>{
        })
    }
    const [error, setError] = useState<string>('')
    return <div className='sign-in-container'>
        <div className="white-block">
            <h1>{t('confirmResetPassword.title')}</h1>

            <Form onSubmit={handleForm}>
                <Form.Group className="mb-3 position-relative" controlId="firstName">
                    <Form.Label>{t('confirmResetPassword.newPasswordLabel')}</Form.Label>
                    <Form.Control type={showNewPassword ? "text" : "password"} value={formValues.newPassword} onChange={(e) => {
                        setFormValues((prev: any) => {
                            return {
                                ...prev,
                                newPassword: e.target.value
                            }
                        })

                        if (e.target.value !== formValues.confirmPassword && formValues.confirmPassword !== '') {
                            setError(t('confirmResetPassword.passwordMismatchError'));
                        } else {
                            setError('')
                        }
                    }} />
                    <button type='button' className='bg-transparent border-0 position-absolute' style={{ right: 10, top: 34 }} onClick={() => setShowNewPassword((prev) => (!prev))}>{showNewPassword ? t('confirmResetPassword.hideButtonText') : t('confirmResetPassword.showButtonText')}</button>
                </Form.Group>
                <Form.Group className="mb-3 position-relative" controlId="firstName">
                    <Form.Label>{t('confirmResetPassword.confirmPasswordLabel')}</Form.Label>
                    <Form.Control type={showNewPasswordConfirmed ? "text" : "password"} value={formValues.confirmPassword} onChange={(e) => {
                        setFormValues((prev: any) => {
                            return {
                                ...prev,
                                confirmPassword: e.target.value
                            }
                        })
                        if (formValues.newPassword !== e.target.value) {
                            setError(t('confirmResetPassword.passwordMismatchError'));
                        } else {
                            setError('')
                        }
                    }} />
                    <button type='button' className='bg-transparent border-0 position-absolute' style={{ right: 10, top: 34 }} onClick={() => setShowNewPasswordConfirmed((prev) => (!prev))}>{showNewPasswordConfirmed ? t('confirmResetPassword.hideButtonText') : t('confirmResetPassword.showButtonText')}</button>
                </Form.Group>

                <Button variant="primary" type="submit">{t('confirmResetPassword.submitButton')}</Button>
            </Form>
        </div>
    </div>
}

export default ConfirmResetPassword
