export const getApplicationTitle = (status: string) => {
    switch (status) {
        case 'new': return 'Новые заявки'
        case 'under_consideration': return 'Заявки на рассмотрении'
        case 'accepted': return 'Принятые заявки'
        case 'rejected': return 'Отклоненные заявки'
        case 'under_revision': return 'Заявки на доработке'
        case 'completed': return 'Завершенные заявки'
        case 'canceled': return 'Отмененые заявки'
    }
}