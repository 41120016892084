import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Добавлен импорт для локализации
import VacancyCreateForm from '../../components/forms/VacancyCreateForm';

type Props = {};

const Create = (props: Props) => {
    const { id } = useParams();
    const { t } = useTranslation(); // Инициализация хука для локализации

    return (
        <div>
            <h3>{t('vacancyCreate.title')}</h3> {/* Заменяем статичный текст на локализированный */}
            <p><span className="text-danger h5">*</span> - {t('vacancyCreate.requiredFields')}</p> {/* То же самое */}
            <VacancyCreateForm id={Number(id)} />
        </div>
    );
};

export default Create;
