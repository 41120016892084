import {makeAutoObservable} from 'mobx'
import { IUserFull } from '../types/user/IUserFull'
class AuthStore {
    isAuth:boolean = false
    user?:IUserFull
    constructor(){
        makeAutoObservable(this)
    }

    setAuth = (value:boolean) => {
        this.isAuth = value
    }

    setUser(user:IUserFull) {
        this.user = user
    }

    hasRole(role:string) {
        return this.user?.roles.includes(role)
    }
}

export default AuthStore;