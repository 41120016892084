import React from 'react'
import { useTranslation } from 'react-i18next';
import LoginForm from '../../components/forms/auth/LoginForm'
import { observer } from 'mobx-react';

type Props = {}

const Login = (props: Props) => {
    const { t } = useTranslation();
    return (
        <div className='sign-in-container'>
            <div className="white-block">
                <h1>{t("sign-in-title")}</h1>
                <h4>{t("sign-in-description")}</h4>
                <LoginForm />
            </div>
        </div>
    )
}

export default observer(Login)