import React from 'react'
import { Link } from 'react-router-dom'
import { useStores } from '../../../stores'
import { ASIDE_MENU } from '../../../helpers/asideMenuConsts'
import { observer } from 'mobx-react'
import { ROUTES } from '../../../helpers/routeConsts'
import { useTranslation } from 'react-i18next'; // Импортируем функцию перевода

type Props = {}

const Aside = (props: Props) => {
    const { routerStore } = useStores()
    const { t, i18n } = useTranslation(); // Используем функцию перевода

    return (
        <aside>
            <Link to={ROUTES.APPLICATION_CREATE} className='btn btn-primary mb-2'>{t('aside.newApplication')}</Link>
            <Link to={ROUTES.VACANCY_CREATE} className='btn btn-primary mb-4'>{t('aside.newVacancy')}</Link>

            <ul className='main-menu'>
                <li>
                    <ul className='main-menu-links'>
                        <li className={ROUTES.HOME === routerStore.currentPath ? 'active' : ''}>
                            <Link to={ROUTES.HOME}>
                                <div className='main-menu-links-item'>
                                    <span dangerouslySetInnerHTML={{ __html: `<i class='fa-regular fa-calendar'></i>` }}></span>
                                    <p>
                                        {t('aside.schedule.title')}
                                    </p>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </li>
                {
                    ASIDE_MENU.map((main_menu_item, main_menu_index) => {
                        return <li key={main_menu_index}>
                            <h4>{i18n.language === 'ru' ? main_menu_item.title :
                                i18n.language === 'kz' ? main_menu_item.titleKZ : main_menu_item.titleEN}</h4>
                            <ul className='main-menu-links'>
                                {
                                    main_menu_item.list.map((list_item, list_item_index) => {
                                        return <li key={list_item_index} className={list_item.link === routerStore.currentPath ? 'active' : ''}>
                                            <Link to={list_item.link}>
                                                <div className='main-menu-links-item'>
                                                    <span dangerouslySetInnerHTML={{ __html: list_item.icon }}></span>
                                                    <p>
                                                        {i18n.language === 'ru' ? list_item.title :
                                                            i18n.language === 'kz' ? list_item.titleKZ :
                                                                list_item.titleEN}
                                                    </p>
                                                </div>
                                            </Link>
                                        </li>
                                    })
                                }
                            </ul>
                        </li>
                    })
                }
            </ul>
        </aside>
    )
}

export default observer(Aside)
