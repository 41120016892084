import React from 'react'

type Props = {}

const Preloader = (props: Props) => {
    return (
        <div className='preloader'></div>
    )
}

export default Preloader