export class CompanyCreateRequest {
    name:string
    bin:string
    phone:string

    constructor() {
        this.name = ''
        this.bin = ''
        this.phone = ''
    }
}