import React, { useEffect, useState } from 'react'
import companyService from '../../api/company.api'
import { correctPhotoLink } from '../../helpers/correctPhotoLink'
import { ICompanyInfo } from '../../types/company/ICompanyInfo'
import ChangePhotoForm from './ChangePhotoForm'
import { Tab, Tabs } from 'react-bootstrap'
import ChangeInfoForm from './ChangeInfoForm'
import Employees from './Employees'
import { useStores } from '../../stores'
import { useTranslation } from 'react-i18next'; // Импортируем хук для перевода текста

type Props = {}

const Index = (props: Props) => {
  const { t } = useTranslation(); // Используем хук для перевода текста

  const [companyInfo, setCompanyInfo] = useState<ICompanyInfo | null>(null)
  const { authStore } = useStores()
  const getCompanyInfo = () => {
    companyService.getMyCompany().then((response) => {
      setCompanyInfo(response.data)
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    getCompanyInfo()
  }, [])

  return <>
    <div className="profile">
      <h3 className='mb-4'>{t('CompanyCabinetTitle')}</h3> {/* Переводим заголовок компании */}
      {
        companyInfo && <div className="profile-container">
          <div className="profile-block shadow-sm">
            <div className="header">
              <div className="image">
                <img src={correctPhotoLink(companyInfo.logo)} alt="" />
                {authStore.hasRole('companyAdmin') && <ChangePhotoForm update={getCompanyInfo}/>}
              </div>
              <div className="info">
                <h5>
                  {companyInfo.name}
                </h5>
      
              </div>
             
            </div>
           
          </div>
          <div className="setting-block">
            <Tabs
              variant="pills"
              defaultActiveKey="base-info"
              id="uncontrolled-tab-example"
              className="mb-3 border-bottom pb-3"
            >
              <Tab eventKey="base-info" title={t('CompanyInfoTabTitle')}> {/* Переводим заголовок вкладки информации о компании */}
                <ChangeInfoForm company={companyInfo} />
              </Tab>
              {
                authStore.hasRole('companyAdmin') && <Tab eventKey={"employees"} title={t('EmployeesTabTitle')}> {/* Переводим заголовок вкладки сотрудников */}
                  <Employees />
                </Tab>
              }
            </Tabs>
          </div>
        </div>
      }
    </div>
  </>
}

export default Index
