import React, { useEffect, useState } from 'react'
import ManageTable from '../../../components/manageTable/ManageTable'
import { useStores } from '../../../stores'
import { IDictionaryItem } from '../../../types/IDictionaryItem'
import { ROUTES } from '../../../helpers/routeConsts'
import dictionaryService from '../../../api/dictionary.api'
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CreateModal from './components/CreateModal'
import OperationList from './components/OperationList'

type Props = {}

const Index = (props: Props) => {

    const { routerStore } = useStores()
    const [list, setList] = useState<IDictionaryItem[] | null>(null)
    const [searchResult, setSearchResult] = useState<IDictionaryItem[] | null>(null)

    const [searchQuery, setSearchQuery] = useState<string>('')
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);



    const updateList = () => {
        setList(null)
        setSearchResult(null)
        setSearchQuery('')
        dictionaryService.findAll('privilege').then((response) => {
            if (response.status === 200) {
                setList(response.data)
                setSearchResult(response.data)
            }
        })
    }

    useEffect(() => {
        routerStore.setCurrentPath(ROUTES.ADMIN_PRIVILEGE)
        updateList()
    }, [])

    useEffect(() => {
        setSearchResult(list ? list.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase())) : [])
    }, [searchQuery])

    return <>
        <h3>Льготы</h3>

        <CreateModal update={updateList} />

        <ManageTable
            title='Список льгот'
            header={
                <ul>
                    <li><input className='form-control' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder='поиск в списке' /></li>
                </ul>
            }
            footer={<></>}
            pages={[<></>]}
        >
            <thead>
                <tr>
                    <th>ID</th>
                    <th >Название</th>
                    <th>Операции</th>
                </tr>
            </thead>
            <tbody>
                {
                    searchResult ?
                        <>
                            {
                                (searchResult as IDictionaryItem[]).map((item) => {
                                    return <tr key={item.id}>
                                        <td className='pt-3'>{item.id}</td>
                                        <td className='pt-3'>
                                            <div className="d-flex">
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={(props: any) => (
                                                        <Tooltip id="button-tooltip" {...props}>
                                                            {item.description}
                                                        </Tooltip>
                                                    )}
                                                >
                                                    <div style={{ cursor: 'pointer', paddingRight: 20 }}>{item.name}</div>
                                                </OverlayTrigger>
                                            </div>

                                        </td>
                                        <td>
                                            <OperationList update={updateList} item={item} />
                                        </td>
                                    </tr>
                                })
                            }
                        </> :
                        <tr>
                            <td colSpan={5} className='text-center p-5'>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </td>
                        </tr>
                }
            </tbody>
        </ManageTable>
    </>
}

export default Index