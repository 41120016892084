import React, { useEffect, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { useStores } from '../../../stores';
import { IVacancyShort } from '../../../types/vacancy/IVacancyShort';
import { IUserShort } from '../../../types/user/IUserShort';
import userService from '../../../api/user.api';
import vacansyService from '../../../api/vacancy.api';
import { useTranslation } from 'react-i18next'; // Импортируем хук для локализации

type Props = {
    status: string | undefined;
    afterFilter: (data: IVacancyShort[]) => void;
};

const Filter = (props: Props) => {
    const { t } = useTranslation(); // Инициализируем хук для локализации

    const [show, setShow] = useState<boolean>(false);

    const { filterStore } = useStores();

    const [users, setUsers] = useState<IUserShort[]>([]);

    // Функция поиска пользователя
    const findUser = (query: string) => {
        if (query.length > 2) {
            userService.findByQuery(query).then((response) => {
                setUsers(
                    response.data[0].map((u: any) => {
                        return {
                            id: u.id,
                            fullName: u.firstName + ' ' + u.secondName,
                            email: u.email,
                        };
                    })
                );
            });
        } else {
            setUsers([]);
        }
    };

    useEffect(() => {
        filterStore.setVacancyFilter({ ...filterStore.vacancyFilter, status: props.status });
        update();

        // Обработчик для скрытия фильтра при клике вне его области
        document.addEventListener('click', (e) => {
            let target = e.target as HTMLElement;
            let filterContainer = document.querySelector('.filter-container');
            let filterToggle = document.querySelector('#filter-toggle');
            if (
                !filterContainer?.contains(target) &&
                !filterToggle?.contains(target) &&
                filterToggle !== target
            ) {
                setShow(false);
            }
        });
    }, [props.status]);

    // Функция обновления списка вакансий после применения фильтра
    const update = () => {
        vacansyService
            .findAll({
                status: filterStore.vacancyFilter.status !== '' ? filterStore.vacancyFilter.status : undefined,
                from: filterStore.vacancyFilter.from !== '' ? filterStore.vacancyFilter.from : undefined,
                to: filterStore.vacancyFilter.to !== '' ? filterStore.vacancyFilter.to : undefined,
                authorEmail: filterStore.vacancyFilter.authorEmail !== '' ? filterStore.vacancyFilter.authorEmail : undefined,
                salaryFrom: filterStore.vacancyFilter.salaryFrom !== '' ? filterStore.vacancyFilter.salaryFrom : undefined,
                salaryTo: filterStore.vacancyFilter.salaryTo !== '' ? filterStore.vacancyFilter.salaryTo : undefined,
            })
            .then((response) => {
                props.afterFilter(response.data[0]);
            });
    };

    // Функция обработки отправки формы
    const handleForm = (e: React.FormEvent) => {
        e.preventDefault();
        setShow(false);
        update();
    };

    return (
        <>
            <div className='filter'>
                <Button id='filter-toggle' onClick={() => setShow((prev) => !prev)}>
                    {t('filter.filter')}
                </Button>
                {filterStore.usedVacancyFilter() && (
                    <Button
                        className='ms-2'
                        variant='secondary'
                        onClick={() => {
                            filterStore.resetVacancyFilter();
                            update();
                        }}
                    >
                        {t('filter.resetFilter')}
                    </Button>
                )}
                {show && (
                    <div className='filter-container shadow'>
                        <h5>{t('filter.searchByFilter')}</h5>
                        <form onSubmit={handleForm}>
                            <label className='form-label'>{t('filter.creationDate')}</label>
                            <div className='d-flex mb-3'>
                                <input
                                    type='date'
                                    className='form-control'
                                    value={filterStore.vacancyFilter.from}
                                    onChange={(e) => {
                                        filterStore.setVacancyFilter({ ...filterStore.vacancyFilter, from: e.target.value });
                                    }}
                                />
                                -
                                <input
                                    type='date'
                                    className='form-control'
                                    value={filterStore.vacancyFilter.to}
                                    onChange={(e) => {
                                        filterStore.setVacancyFilter({ ...filterStore.vacancyFilter, to: e.target.value });
                                    }}
                                />
                            </div>

                            <div className='form-group'>
                                <label className='form-label'>{t('filter.requestAuthor')}</label>
                                <input
                                    type='email'
                                    className='form-control mb-3'
                                    value={filterStore.vacancyFilter.authorEmail}
                                    onChange={(e) => {
                                        filterStore.setVacancyFilter({ ...filterStore.vacancyFilter, authorEmail: e.target.value });
                                        findUser(e.target.value); // Вызываем функцию поиска пользователя
                                    }}
                                />
                                {users.length > 0 && (
                                    <ListGroup>
                                        {users.map((user) => {
                                            return (
                                                <ListGroup.Item
                                                    key={user.id}
                                                    className='filter-users'
                                                    onClick={() => {
                                                        filterStore.setVacancyFilter({ ...filterStore.vacancyFilter, authorEmail: user.email });
                                                        setTimeout(() => {
                                                            setUsers([]);
                                                        }, 100);
                                                    }}
                                                >
                                                    {user.fullName}
                                                </ListGroup.Item>
                                            );
                                        })}
                                    </ListGroup>
                                )}
                            </div>

                            <label className='form-label'>{t('filter.salary')}</label>
                            <div className='d-flex'>
                                <input
                                    type='number'
                                    className='form-control'
                                    value={filterStore.vacancyFilter.salaryFrom}
                                    onChange={(e) => {
                                        filterStore.setVacancyFilter({ ...filterStore.vacancyFilter, salaryFrom: e.target.value });
                                    }}
                                />
                                -
                                <input
                                    type='number'
                                    className='form-control'
                                    value={filterStore.vacancyFilter.salaryTo}
                                    onChange={(e) => {
                                        filterStore.setVacancyFilter({ ...filterStore.vacancyFilter, salaryTo: e.target.value });
                                    }}
                                />
                            </div>

                            <Button type='submit' className='mt-2'>
                                {t('filter.find')}
                            </Button>
                            <Button
                                type='button'
                                variant='secondary'
                                className='mt-2 ms-2'
                                onClick={() => {
                                    filterStore.resetVacancyFilter();
                                    update();
                                }}
                            >
                                {t('filter.reset')}
                            </Button>
                        </form>
                    </div>
                )}
            </div>
        </>
    );
};

export default observer(Filter);
