import { makeAutoObservable } from "mobx"
import { IApplicationGetFilter } from "../types/application/IApplicationGetFilter"
import { IVacancyGetFilter } from "../types/vacancy/IVacancyGetFilter"

class FilterStore {
    applicationFilter: IApplicationGetFilter
    vacancyFilter: IVacancyGetFilter
    constructor() {
        makeAutoObservable(this)
        this.applicationFilter = {
            status: '',
            from: '',
            to: '',
            authorEmail: '',
            salaryFrom: '',
            salaryTo: ''
        }
        this.vacancyFilter = {
            status: '',
            from: '',
            to: '',
            authorEmail: '',
            salaryFrom: '',
            salaryTo: ''
        }
    }

    setApplicationFilter(filter: IApplicationGetFilter) {
        this.applicationFilter = filter
    }

    usedApplicationFilter() {
        return this.applicationFilter.from !== ''
            || this.applicationFilter.to !== ''
            || this.applicationFilter.authorEmail !== ''
            || this.applicationFilter.salaryFrom !== ''
            || this.applicationFilter.salaryTo !== ''
    }

    resetApplicationFilter() {
        this.applicationFilter = {
            from: '',
            to: '',
            authorEmail: '',
            salaryFrom: '',
            salaryTo: ''
        }
    }

    setVacancyFilter(filter: IVacancyGetFilter) {
        this.vacancyFilter = filter
    }

    usedVacancyFilter() {
        return this.vacancyFilter.from !== ''
            || this.vacancyFilter.to !== ''
            || this.vacancyFilter.authorEmail !== ''
            || this.vacancyFilter.salaryFrom !== ''
            || this.vacancyFilter.salaryTo !== ''
    }

    resetVacancyFilter() {
        this.vacancyFilter = {
            from: '',
            to: '',
            authorEmail: '',
            salaryFrom: '',
            salaryTo: ''
        }
    }
}

export default FilterStore