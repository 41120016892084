import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import candidateService from '../../../api/candidate.api';
import { useTranslation } from 'react-i18next'; // Импортируем хук для перевода текста

type Props = {
    id: number
    update:()=>void
}

const RemoveResume = (props: Props) => {

    const { t } = useTranslation(); // Хук для перевода текста
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleDelete = () => {

        candidateService.deleteResume(props.id).then((response) => {
            setShow(false)
            props.update()
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <>
            <a href='#' onClick={(e)=>{
                e.preventDefault()
                handleShow()
            }}>
                <i className="fa-solid fa-trash text-danger" style={{fontSize:22}}></i>
            </a>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('DeleteResumeTitle')}</Modal.Title> {/* Переводим заголовок "Удаление резюме" */}
                </Modal.Header>
                <Modal.Body>
                    {t('DeleteResumeConfirmation')} <br /> <br /> {/* Переводим текст подтверждения удаления */}
                    <Button onClick={handleDelete} variant="danger">{t('Delete')}</Button> {/* Переводим кнопку "Удалить" */}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RemoveResume
