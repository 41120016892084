import React, { useState } from 'react'
import { ICandidateVacancyStepItem } from '../../types/candidate/ICandidateVacancyStepItem'
import { Button, Modal } from 'react-bootstrap'
import vacansyService from '../../api/vacancy.api'
import { socket } from '../../App'
import { useStores } from '../../stores'

type Props = {
    step: ICandidateVacancyStepItem,
    update: () => void,
    vacancyId:number
}

const CompleteVacancyStepModal = (props: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {authStore} = useStores()
    const handleComplete = (e: React.MouseEvent) => {
        vacansyService.completeStep(props.step.id).then(response => {
            props.update()
            socket.emit('chat message', JSON.stringify({
                email:authStore.user?.email,
                action:`Кандидат ${props.step.resume.candidate.firstName} ${props.step.resume.candidate.lastName} завершил этап "${props.step.step.name}"`,
                link:'/vacancy-candidates/'+ props.vacancyId,
                type:'step-complete',
            }));
            handleClose()
        })
    }

    return <>
        <button onClick={(e) => {
            handleShow()
        }} className={`dropdown-item`}>
            К след. этапу
        </button>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Завершение этапа</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 className='my-3'>Вы действительно хотите завершить этап?</h5>
                <Button onClick={handleComplete} variant="success">Да, перейти к след этапу</Button>
            </Modal.Body>
        </Modal>
    </>
}

export default CompleteVacancyStepModal