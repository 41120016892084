import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import vacansyService from '../../api/vacancy.api';
import { IVacancyFull } from '../../types/vacancy/IVacancyFull';
import { convertUTCToDatestring } from '../../helpers/convertUTCToDatestring';
import { convertToStringPrice } from '../../helpers/convertToStringPrice';
import ResultModal from '../../components/modals/ResultModal';
import ResultModalContent from '../../components/modals/ResultModalContent';
import { ERROR_MESSAGES } from '../../helpers/errorMessage';
import StatusBadge from './components/StatusBadge';
import { correctPhotoLink } from '../../helpers/correctPhotoLink';
import { ROUTES } from '../../helpers/routeConsts';
type Props = {}

const Info = (props: Props) => {
    const { id } = useParams()
    const [vacancy, setVacancy] = useState<IVacancyFull | null>(null)

    const [showResult, setShowResult] = useState<boolean>(false)
    const [resultModalContent, setResultModalContent] = useState<React.ReactNode>(<></>)

    const handleCloseResult = () => {
        setShowResult(false)
    }

    const handleShowResult = () => {
        setShowResult(true)
    }

    const update = () => {
        vacansyService.findById(Number(id)).then((response) => {
            setVacancy(response.data)
        })
    }

    useEffect(() => {
        update()
    }, [id])

    return (
        <>
            <h5 className='text-muted mb-4'>Подробнее о вакансии</h5>
            <ResultModal
                show={showResult}
                title={null}
                link={null}
                handleClose={handleCloseResult}
                children={resultModalContent}
            />
            <div className="white-form-block">
                {
                    vacancy && <div className='application-details-container'>
                        <StatusBadge status={vacancy.status.name} />
                        <p className='text-muted mb-1'>{convertUTCToDatestring(vacancy.creationDate)}</p>
                        <h4>{vacancy.position}</h4>
                        <div className='d-flex'>
                            <Link to="/" className='text-decoration-none'>
                                <div className="user-list-item">
                                    <img src={correctPhotoLink(vacancy.user.photo)} alt="" />
                                    <p>{vacancy.user.fullName}</p>
                                </div>
                            </Link>
                        </div>
                        <hr />

                        <div className="application-details-item">
                            <div className="title">
                                Зарплата
                            </div>
                            <div className='value'>
                                {convertToStringPrice(vacancy.salary)}  ({vacancy.afterTax ? 'с учетом налогов' : 'Без учета налогов'})
                            </div>
                        </div>

                        <div className="application-details-item">
                        <div className="title">
                            Бонусы
                        </div>
                        <div className='value'>
                            <p className='mb-0'>-{vacancy.bonus.type}-</p>
                            <p>{vacancy.bonus.value}</p>
                        </div>
                    </div>

                        <div className="application-details-item">
                            <div className="title">
                                Количество работников
                            </div>
                            <div className='value'>
                                {vacancy.amountOfWorkers}
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Информация о компании для вакансии
                            </div>
                            <div className='value flex-grow-1'>
                                <div className="card card-body" dangerouslySetInnerHTML={{ __html: vacancy.aboutCompanyForVacancy }}>

                                </div>
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Проблемы из-за которой открыли вакансию
                            </div>
                            <div className='value flex-grow-1'>
                                <div className="card card-body" dangerouslySetInnerHTML={{ __html: vacancy.problems }}>

                                </div>
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Обязанности
                            </div>
                            <div className='value flex-grow-1'>
                                <div className="card card-body" dangerouslySetInnerHTML={{ __html: vacancy.responsibilities }}>

                                </div>
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Требования
                            </div>
                            <div className='value flex-grow-1'>
                                <div className="card card-body" dangerouslySetInnerHTML={{ __html: vacancy.requirements }}>

                                </div>
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Ожидания от сотрудника
                            </div>
                            <div className='value flex-grow-1'>
                                <div className="card card-body" dangerouslySetInnerHTML={{ __html: vacancy.expectations }}>

                                </div>
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Будет плюсом
                            </div>
                            <div className='value flex-grow-1'>
                                <div className="card card-body" dangerouslySetInnerHTML={{ __html: vacancy.someWishes }}>

                                </div>
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Что мы предлагаем
                            </div>
                            <div className='value flex-grow-1'>
                                <div className="card card-body" dangerouslySetInnerHTML={{ __html: vacancy.whatWeOffer }}>

                                </div>
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Возможности
                            </div>
                            <div className='value flex-grow-1'>
                                <div className="card card-body" dangerouslySetInnerHTML={{ __html: vacancy.opportunities }}>

                                </div>
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Причина найма
                            </div>
                            <div className='value'>
                                {vacancy.reasonForHiring}
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Вид договора
                            </div>
                            <div className='value'>
                                {vacancy.workContractType}
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Департамент / отдел
                            </div>
                            <div className='value'>
                                {vacancy.department} / {vacancy.division}
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                График работы
                            </div>
                            <div className='value'>
                                {vacancy.workScheduleType}
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Опыт работы
                            </div>
                            <div className='value'>
                                {vacancy.workExperienceType}
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Льготы
                            </div>
                            <div className='value'>
                                <ul className='list-group'>
                                    {
                                        vacancy.privileges.map((item, index) => (
                                            <li className='list-group-item' key={index}>{item}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Личные качества
                            </div>
                            <div className='value'>
                                <ul className='list-group'>
                                    {
                                        vacancy.personalQualities.map((item, index) => (
                                            <li className='list-group-item' key={index}>{item}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Уровень английского
                            </div>
                            <div className='value'>
                                {vacancy.englishLevel}
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Другие языки
                            </div>
                            <div className='value'>
                                <ul className="list-group">
                                    {
                                        vacancy.languages.map((item) => {
                                            return <li key={item.name} className='list-group-item'>{item.name} ({item.degree})</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Образование
                            </div>
                            <div className='value'>
                                {vacancy.educationDegree}
                            </div>
                        </div>

                        <div className="application-details-item">
                            <div className="title">
                                Дополнительные навыки
                            </div>
                            <div className='value'>
                                {vacancy.additionalSkills}
                            </div>
                        </div>

                        <div className="application-details-item mt-5 not-hovered">
                            <div className="title">
                                Кандидаты
                            </div>
                            <div className='value'>
                                <Link to={ROUTES.VACANCY_CANDIDATS + '/' + vacancy.id}>Перейти</Link>
                            </div>
                        </div>
                        <div className="application-details-item mt-5 not-hovered">
                            <div className="title">

                            </div>
                            <div className='value'>
                                {
                                    vacancy.status.translit === 'open' && <>
                                        <Button
                                            variant="success"
                                            className='d-block mb-2'
                                            onClick={() => {
                                                vacansyService.setStatus(vacancy.id, 'closed').then((response) => {
                                                    if (response.status === 200) {
                                                        setResultModalContent(<ResultModalContent type="success" message={"Вакансия закрыта"} />)
                                                        handleShowResult()
                                                        setVacancy(response.data)
                                                    }
                                                }).catch((e: any) => {
                                                    setResultModalContent(<ResultModalContent type="error" message={ERROR_MESSAGES[e.response.data]} />)
                                                    handleShowResult()
                                                })
                                            }}
                                        >Закрыть вакансию</Button>
                                        <Button
                                            variant="danger"
                                            onClick={() => {
                                                vacansyService.setStatus(vacancy.id, 'canceled').then((response) => {
                                                    if (response.status === 200) {
                                                        setResultModalContent(<ResultModalContent type="success" message={"Вакансия отменена"} />)
                                                        handleShowResult()
                                                        setVacancy(response.data)
                                                    }
                                                }).catch((e: any) => {
                                                    setResultModalContent(<ResultModalContent type="error" message={ERROR_MESSAGES[e.response.data]} />)
                                                    handleShowResult()
                                                })
                                            }}
                                        >Отменить вакансию</Button>
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                }
            </div>

        </>
    )
}

export default Info