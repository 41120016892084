import axiosInstance from "."
import { IDictionaryItem } from "../types/IDictionaryItem"

class DictionaryService {
    async findAll(entity:string) {
        const response = await axiosInstance.get(`/api/${entity}`)
        return response
    }
    async findById(entity:string, id:number) {
        const response = await axiosInstance.get(`/api/${entity}/${id}`)
        return response
    }
    async findAllByParentId(entity:string, id:number) {
        const response = await axiosInstance.get(`/api/${entity}/getByParentId/${id}`)
        return response
    }
    async create(entity:string, formData:IDictionaryItem) {
        const response = await axiosInstance.post(`/api/${entity}`, formData)
        return response
    }

    async edit(entity:string, formData:IDictionaryItem) {
        const response = await axiosInstance.put(`/api/${entity}/${formData.id}`, formData)
        return response
    }

    async delete(entity:string, id:number) {
        const response = await axiosInstance.delete(`/api/${entity}/${id}`)
        return response
    }
}

const dictionaryService = new DictionaryService()
export default dictionaryService