import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import candidateService from "../../api/candidate.api";
import {toast} from "react-toastify";

type Props = {
    id: number
    update: () => void
}

const DeleteCandidate = (props: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleForm = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const { data} = await candidateService.deleteCandidate(props.id)
            props.update()
            toast.success(`Кандидат ${data?.firstName} ${data?.lastName} успешно удален`);
            handleClose()
        } catch (error: any) {
            toast.error('Произошла ошибка при удалении кандидата:', error?.response?.data);
        }
    }


    return <>
        <button onClick={(e) => {
            handleShow()
        }} className={`dropdown-item p-0`}>
            Удалить
        </button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Вы уверены, что хотите удалить этого кандидата? </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p style={{color: "#dc3545"}}>Это действие нельзя отменить</p>
                <Button onClick={handleForm}>
                    Удалить кандидата из системы
                </Button>
            </Modal.Body>
        </Modal>
    </>
}

export default DeleteCandidate