// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en.json';
import ruTranslation from './locales/ru.json';
import kzTranslation from './locales/kz.json';

// the translations
const resources = {
    en: { translation: enTranslation },
    ru: { translation: ruTranslation },
    kz: { translation: kzTranslation },
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'ru', // default language
        interpolation: {
            escapeValue: false, // not needed for React as it escapes by default
        },
    });

export default i18n;
