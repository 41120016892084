import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useStores } from '../../../stores'
import Aside from './Aside'
import Content from './Content'
import { ROUTES } from '../../../helpers/routeConsts'
import { Navigate } from 'react-router-dom'
import Nav from './Nav'

type Props = {}

const Layout = (props: Props) => {
    const { authStore } = useStores()

    if (!authStore.isAuth) {
        return <Navigate to={ROUTES.LOGIN} />
    } else if (!authStore.user?.company) {
        return <Navigate to={ROUTES.NEED_COMPANY} />
    } else if (authStore.user?.company && (!authStore.user?.company?.isActive || !authStore.user?.company?.confirmed)) {
        return <Navigate to={ROUTES.NEED_ACTIVATE} />
    }
    else {
        return <div id="mainBody">  
            <div className='root-container'>
                <Nav />
                <div className="main-layout">
                    <Aside />
                    <Content />
                </div>
            </div>
        </div>
    }

}

export default observer(Layout)