import axiosInstance from "."

class NotificationService {
    async getNotifications() {
        const response = await axiosInstance.get('/api/notification')
        return response
    }

    async skipNotification(id: number) {
        const response = await axiosInstance.patch('/api/notification/skip/' + id)
        return response
    }
}

const notificationService = new NotificationService()

export default notificationService