import React, { useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routeConsts'
import applicationService from '../../../api/application.api'
import ResultModal from '../../../components/modals/ResultModal'
import ResultModalContent from '../../../components/modals/ResultModalContent'
import { ERROR_MESSAGES } from '../../../helpers/errorMessage'
import { IApplicationShort } from '../../../types/application/IApplicationShort'
import { useStores } from '../../../stores'
import { useTranslation } from 'react-i18next'

type Props = {
    application: IApplicationShort,
    updateList: () => void
}

const OperationsList = (props: Props) => {

    const {t} = useTranslation()


    const {authStore} = useStores()
    const [showResult, setShowResult] = useState<boolean>(false)
    const [resultModalContent, setResultModalContent] = useState<React.ReactNode>(<></>)

    const handleCloseResult = () => {
        setShowResult(false)
    }

    const handleShowResult = () => {
        setShowResult(true)
    }

    const handleSetStatus = (statusName: string) => {
        applicationService.setStatus(props.application.id, statusName).then((response) => {
            if (response.status === 200) {
                setResultModalContent(<ResultModalContent type="success" message={"Статус заявки изменен"} />)
                handleShowResult()
                props.updateList()
            }
        }).catch((e: any) => {
            setResultModalContent(<ResultModalContent type="error" message={ERROR_MESSAGES[e.response.data]} />)
            handleShowResult()
        })
    }

    return <>
        <ResultModal
            show={showResult}
            title={"Изменение статуса"}
            link={null}
            handleClose={handleCloseResult}
            children={resultModalContent}
        />

        <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic">
                <i className="fa-solid fa-ellipsis-vertical"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Link to={`${ROUTES.APPLICATION_INFO}/${props.application.id}`} className='dropdown-item'>{t("operationsList.details")}</Link>
                {
                    [
                        'new',
                        'under_consideration'
                    ].includes(props.application.status.translit)
                    && authStore.hasRole('hrmanager') && <Link to={`${ROUTES.VACANCY_CREATE}/${props.application.id}`} className='dropdown-item'>{t("vacancyCreate.title")}</Link>
                }
                {
                    ![
                        'canceled',
                        'rejected'
                    ].includes(props.application.status.translit)
                    && authStore.user?.email === props.application.author.email && <button type='button' onClick={(e) => {
                        e.preventDefault()
                        handleSetStatus('canceled')

                    }} className='dropdown-item '>{t("comment")}</button>
                }
            </Dropdown.Menu>
        </Dropdown>
    </>
}

export default OperationsList