import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { IDictionaryItem } from '../../../../types/IDictionaryItem'
import { ROUTES } from '../../../../helpers/routeConsts'
import DivisionEditModal from './DivisionEditModal'
import DivisionDeleteModal from './DivisionDeleteModal'

type Props = {
    item: IDictionaryItem,
    update: () => void
}

const DivisionOperationList = (props: Props) => {
    return <Dropdown>
        <Dropdown.Toggle variant="light" id="dropdown-basic">
            <i className="fa-solid fa-ellipsis-vertical"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <DivisionEditModal item={props.item} update={props.update} />
            <DivisionDeleteModal item={props.item} update={props.update} />
        </Dropdown.Menu>
    </Dropdown>
}

export default DivisionOperationList