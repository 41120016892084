export const getCurrentDate = () => {
    // Создаем объект для текущей даты
    var currentDate = new Date();
  
    // Определяем названия месяцев на русском языке
    var monthNames = [
      "января", "февраля", "марта",
      "апреля", "мая", "июня",
      "июля", "августа", "сентября",
      "октября", "ноября", "декабря"
    ];
  
    // Получаем день, месяц и год
    var day = currentDate.getDate();
    var monthIndex = currentDate.getMonth();
    var year = currentDate.getFullYear();
  
    // Форматируем строку и возвращаем результат
    var formattedDate = day + ' ' + monthNames[monthIndex] + ' ' + year;
    return formattedDate;
  }