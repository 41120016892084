import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import ResumeVacancyStepsModal from '../../../components/modals/ResumeVacancyStepsModal';
import CompleteStep from './CompleteStep';
import FailedStep from './FailedStep';
import candidateService from '../../../api/candidate.api';
import { ICandidateVacancyStepItem } from '../../../types/candidate/ICandidateVacancyStepItem';
import CandidateCommentsModal from './CandidateCommentsModal';
import ComeFailedStep from "./comeFailedStep";

type Props = {
  vacancyId: number;
  resumeId: number;
  update: () => void;
  showComments: boolean;
  showCommentsFor: string | undefined;
};

const ResumeOperationList = (props: Props) => {
  const { vacancyId, resumeId, update, showComments, showCommentsFor } = props;
  const [list, setList] = useState<ICandidateVacancyStepItem[]>([]);
  const [isOpen, setIsOpen] = useState(!!(showComments && showCommentsFor && Number(showCommentsFor) === resumeId));

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  const fetchVacancySteps = () => {
    candidateService.getVacancyStepsShort(vacancyId, resumeId).then(response => {
      setList(response.data);
    });
  };

  useEffect(() => {
    fetchVacancySteps();
  }, []);


  const notCompleted = list.filter((m) => !m.complete);

  const firstItem = notCompleted.length > 0 ? notCompleted[0] : undefined;

  const hasSteps = firstItem
      && !firstItem.failed
      && !firstItem.isCome
      && list.length > 0;

  const showCompleteStep = hasSteps && list.some(m => !m.complete);

  return (
    <Dropdown show={isOpen} onToggle={toggleDropdown}>
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <ResumeVacancyStepsModal vacancyId={vacancyId} resumeId={resumeId} />
        {hasSteps && (
          <>
            {showCompleteStep && <CompleteStep vacancyId={vacancyId} resumeId={resumeId} update={update} />}
            <FailedStep vacancyId={vacancyId} resumeId={resumeId} update={update} />
            <ComeFailedStep vacancyId={vacancyId} resumeId={resumeId} update={update} />
          </>
        )}
        <CandidateCommentsModal vacancyId={vacancyId} resumeId={resumeId} showComments={showComments} showCommentsFor={showCommentsFor} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ResumeOperationList;