import React from 'react'

type ResultType = 'success' | 'warning' | 'error'

type Props = {
  type: ResultType
  message: string
}

const ResultModalContent = (props: Props) => {

  switch (props.type) {
    case 'success':
      return (
        <div className="alert alert-success" role="alert">
          {props.message}
        </div>
      )
    case 'warning':
      return (
        <div className="alert alert-warning" role="alert">
          {props.message}
        </div>
      )
    case 'error':
      return (
        <div className="alert alert-danger" role="alert">
          {props.message}
        </div>
      )
    default:
      return null
  }

}

export default ResultModalContent