import React from 'react'

type Props = {
    pages: React.ReactNode[],
    children: React.ReactNode
}

const Footer = (props: Props) => {
    return (
        <div className="footer">
            <div className="manage-table-pagination">
                {
                    props.pages.map((page, index) => {
                        return <button key={index} className={page === 2 ? 'active' : ''}>
                            {page}
                        </button>
                    })
                }
            </div>

        </div>
    )
}

export default Footer