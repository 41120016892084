import React from 'react'
import { IVacancyStep } from '../../../types/vacancy/IVacancyStep'
import { Dropdown } from 'react-bootstrap'
import UpdateVacancyStepModal from '../../../components/modals/UpdateVacancyStepModal'
import DeleteVacancyStepModal from '../../../components/modals/DeleteVacancyStepModal'

type Props = {
    step: IVacancyStep
    update: () => void
}

const StepOperationList = (props: Props) => {
    return <Dropdown>
        <Dropdown.Toggle variant="light" id="dropdown-basic">
            <i className="fa-solid fa-ellipsis-vertical"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <UpdateVacancyStepModal step={props.step} update={props.update} />
            <DeleteVacancyStepModal step={props.step} update={props.update} />
        </Dropdown.Menu>
    </Dropdown>
}

export default StepOperationList