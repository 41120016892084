import React, { useState } from 'react'
import { IVacancyStep } from '../../types/vacancy/IVacancyStep'
import { Button, Form, Modal } from 'react-bootstrap'
import vacansyService from '../../api/vacancy.api'

type Props = {
    step: IVacancyStep,
    update: () => void
}

const DeleteVacancyStepModal = (props: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleForm = (e: React.FormEvent) => {
        e.preventDefault();
        vacansyService.deleteStep(props.step.id).then((response) => {
            if (response.status === 200) {
                handleClose()
                props.update()
            }
        }).catch((e: any) => {
            console.log(e)
        })
    }


    return <>
        <button onClick={(e) => {
            handleShow()
        }} className={`dropdown-item`}>
            Удалить
        </button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Редактировать этап</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleForm}>

                    <p className="text-danger">Вы действительно хотите удалить этап?</p>

                    <Button type='submit' variant="danger">Да, удалить!</Button>
                </Form>
            </Modal.Body>
        </Modal>
    </>
}

export default DeleteVacancyStepModal