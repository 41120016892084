import React, { useState } from 'react'
import { ILoginFormRequest } from '../../../types/auth/ILoginFormRequest'
import authService from '../../../api/auth.api'
import { AxiosError } from 'axios'
import { ERROR_MESSAGES } from '../../../helpers/errorMessage'
import { useStores } from '../../../stores'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routeConsts'
import { IUserFull } from '../../../types/user/IUserFull'
import { API_URL } from '../../../api'

type Props = {}

const LoginForm = (props: Props) => {
    const { t } = useTranslation();

    const [formValues, setFormValues] = useState<ILoginFormRequest>({
        email: '',
        password: ''
    })
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState<string>('')

    const { authStore } = useStores()

    const navigator = useNavigate()

    const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        authService.login(formValues).then((response) => {
            setError('')
            authStore.setAuth(true)
            authStore.setUser(response.data as IUserFull)
            if ((response.data as IUserFull).company && (response.data as IUserFull).company?.name !== "TEMP") {
                authStore.setUser(response.data as IUserFull)
                navigator('/')
            } else {
                authStore.setUser(response.data as IUserFull)
                navigator(ROUTES.NEED_COMPANY)
            }
        }).catch((e: any) => {
            if (e instanceof AxiosError) {
                setError(ERROR_MESSAGES[e.response?.data])
            } else {
                setError("Неизвестная ошибка")
            }

        })
    }

    return (
        <form onSubmit={(e) => handleForm(e)}>
            {error && <input disabled className='form-control is-invalid bg-danger bg-opacity-10' value={error} />}
            <div className='sign-in-email-input-container'>
                <input
                    className='form-control'
                    autoComplete='off'
                    type="email"
                    value={formValues.email}
                    onChange={(e) => {
                        setFormValues((prev) => ({
                            ...prev,
                            email: e.target.value
                        }))
                    }}
                    placeholder={t("sign-in-email-placeholder")}
                />
            </div>
            <div className='sign-in-password-input-container'>
                <input
                    className='form-control'
                    type={showPassword ? 'text' : 'password'}
                    value={formValues.password}
                    onChange={(e) => {
                        setFormValues((prev) => ({
                            ...prev,
                            password: e.target.value
                        }))
                    }}
                    placeholder={t("sign-in-password-placeholder")}
                />
                <button type='button' onClick={() => setShowPassword((prev) => (!prev))}>{showPassword ? t("sign-in-password-hide") : t("sign-in-password-show")}</button>
            </div>

            <div className='sign-in-problem-text'><Link to={'/auth/reset-password'}>{t("sign-in-having-problems")}</Link></div>
            <Button className='w-100' type="submit">{t("sign-in-submit-text")}</Button>

            <div className="sign-in-with-external">
                <div className="title">
                    <div className="line"></div>
                    <div>{t("sign-in-externals-title")}</div>
                    <div className="line"></div>
                </div>
                <div className="buttons">
                    <a className='btn btn-outline-primary' href={`${API_URL}/api/auth/google`}><i className="fa-brands fa-google me-2"></i>Google</a>
                </div>
            </div>

            <p className='text-center'>{t("sign-in-dont-have-account")}
                <Link to={ROUTES.REGISTER} className='fw-bold ms-2'>{t("sign-up")}</Link>
            </p>
        </form>
    )
}

export default observer(LoginForm)