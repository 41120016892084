import React, { useEffect, useState } from 'react'
import { ICandidateFull } from '../../types/candidate/ICandidateFull'
import { Link, useParams } from 'react-router-dom'
import candidateService from '../../api/candidate.api'
import CreateResumeModal from '../../components/modals/CreateResumeModal'
import { ListGroup } from 'react-bootstrap'
import { API_URL } from '../../api'
import RemoveResume from './components/RemoveResume'
import { ROUTES } from '../../helpers/routeConsts'
import { useTranslation } from 'react-i18next'; // Импортируем хук для перевода текста

type Props = {}

const Info = (props: Props) => {
  const { t } = useTranslation(); // Хук для перевода текста
  const { id } = useParams()
  const [candidate, setCandidate] = useState<ICandidateFull | null>(null)
  const [vacancies, setVacancies] = useState<{ id: number, position: string }[]>([])

  const [showResult, setShowResult] = useState<boolean>(false)
  const [resultModalContent, setResultModalContent] = useState<React.ReactNode>(<></>)

  const handleCloseResult = () => {
    setShowResult(false)
  }

  const handleShowResult = () => {
    setShowResult(true)
  }

  const update = () => {
    candidateService.findById(Number(id)).then((response) => {
      setCandidate(response.data)
    }).catch((e) => {
      console.log(e)
    })

    candidateService.getVacancies(Number(id)).then((response) => {
      setVacancies(response.data)
    }).catch((e) => {
      console.log(e)
    })
  }


  useEffect(() => {
    update()
  }, [id])

  return (
    <>
      <h5 className='text-muted mb-4'>{t('CandidateDetailsTitle')}</h5> {/* Переводим заголовок "Подробнее о кандидате" */}
      <div className="white-form-block mb-3">
        {
          candidate && <div className='application-details-container'>
            <h4>{candidate.firstName} {candidate.lastName}</h4>
            {
              candidate.email !== "" && <div className="application-details-item">
                <div className="title">
                  {t('CandidateEmailTitle')} {/* Переводим метку "Почта" */}
                </div>
                <div className='value'>
                  {candidate.email}
                </div>
              </div>
            }
            {
              candidate.phone !== "" && <div className="application-details-item">
                <div className="title">
                  {t('CandidatePhoneTitle')} {/* Переводим метку "Телефон" */}
                </div>
                <div className='value'>
                  {candidate.phone}
                </div>
              </div>
            }
            <div className="application-details-item">
              <div className="title">
                {t('CandidateResumeTitle')} {/* Переводим метку "Резюме" */}
              </div>
              <div className='value' >

                <ul className='list-group mb-3'>
                  {
                    candidate.resumes && candidate.resumes.length > 0 && candidate.resumes.map((resume) => {
                      return (
                        <li className='list-group-item'>

                          <div className='d-flex justify-content-between'>
                            <div className='me-5'>
                              {resume.name}
                            </div>
                            <div className="d-flex" style={{ gap: 20 }}>
                              <a key={resume.id} href={`${API_URL}${resume.file}`} download={true} target='_blank' rel="noreferrer">
                                <i className="fa-solid fa-file-arrow-down text-success" style={{ fontSize: '22px' }}></i>
                              </a>
                              <RemoveResume id={resume.id} update={update} />
                            </div>
                          </div>

                        </li>
                      )
                    })
                  }
                </ul>

                <CreateResumeModal update={update} id={Number(id)} />
              </div>
            </div>
          </div>
        }
      </div>
      <div className="white-form-block">
        <h4>{t('CandidateParticipatingVacanciesTitle')}</h4> {/* Переводим заголовок "Вакансии с участием кандидата" */}
        <ListGroup>
          {
            vacancies.map((vacancy) => {
              return (
                <ListGroup.Item key={vacancy.id}>
                  <Link to={ROUTES.VACANCY_INFO + '/' + vacancy.id}>
                    <div>
                      {vacancy.position}
                    </div>
                  </Link>
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </div>
    </>
  )
}

export default Info
