import React, { useEffect, useState } from 'react'
import { ICompanyEmployee } from '../../types/company/ICompanyEmployee'
import Image from './Image'
import { ListGroup } from 'react-bootstrap'
import EmployeeOperations from './EmployeeOperations'
import companyService from '../../api/company.api'
import Invite from './Invite'
import { useTranslation } from 'react-i18next'; // Импортируем хук для перевода текста

type Props = {}

const Employees = (props: Props) => {
    const { t } = useTranslation(); // Используем хук для перевода текста

    const [list, setList] = useState<ICompanyEmployee[]>([])

    const update = () => {
        companyService.getEmployees().then((response) => {
            setList(response.data)
        }).catch((e) => console.log(e))
    }

    useEffect(() => {
        update()
    }, [])

    return <>
        <table className='table table-bordered'>
            <thead>
                <tr>
                    <th>#</th>
                    <th>{t('EmployeesPhoto')}</th> {/* Переводим заголовок столбца "Фото" */}
                    <th>{t('EmployeesName')}</th> {/* Переводим заголовок столбца "Имя" */}
                    <th>{t('EmployeesEmail')}</th> {/* Переводим заголовок столбца "Почта" */}
                    <th>{t('EmployeesRoles')}</th> {/* Переводим заголовок столбца "Роли" */}
                    <th>{t('EmployeesOperations')}</th> {/* Переводим заголовок столбца "Операции" */}
                </tr>
            </thead>
            <tbody>
                {
                    list.map((item) => {
                        return (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td><Image path={item.photo} /></td>
                                <td>{item.firstName} {item.secondName}</td>
                                <td>{item.email}</td>
                                <td><ListGroup>
                                    {
                                        item.roles.map((role, index) => {
                                            return <ListGroup.Item key={index}>{role}</ListGroup.Item>
                                        })
                                    }
                                </ListGroup></td>
                                <td>
                                    <EmployeeOperations employee={item} update={update} />
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>

        <Invite />
    </>
}

export default Employees
