import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routeConsts'
import { useTranslation } from 'react-i18next'
import DeleteCandidate from "../../../components/modals/DeleteCandidat";

type Props = {
    id: number
    update: () => void
}

const OperationsList = (props: Props) => {
    const { t } = useTranslation()
    return <Dropdown>
        <Dropdown.Toggle variant="light" id="dropdown-basic">
            <i className="fa-solid fa-ellipsis-vertical"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <Dropdown.Item className={`dropdown-item`} as={Link} to={`${ROUTES.CANDIDATE_INFO}/${props.id}`}>
                {t("operationsList.details")}
            </Dropdown.Item>
            <Dropdown.Item>
                <DeleteCandidate id={props.id} update={props.update} />
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
}

export default OperationsList