import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { VacancyStepCreateRequest } from '../../types/vacancy/VacancyStepCreateRequest'
import vacansyService from '../../api/vacancy.api'

type Props = {
    vacancyId: number
    handleClose: () => void
    update: () => void
}

const VacancyStepCreateForm = (props: Props) => {

    const [formValues, setFormValues] = useState<VacancyStepCreateRequest>(new VacancyStepCreateRequest());

    const handleForm = (e: React.FormEvent) => {
        e.preventDefault()
        vacansyService.createStep({
            ...formValues,
            vacancyId: props.vacancyId
        }).then((response) => {
            if (response.status === 200) {
                props.handleClose()
                props.update()
            }
        }).catch((e: any) => {
            console.log(e)
        })
    }

    return <>
        <Form onSubmit={handleForm}>
            <Form.Group className='mb-3'>
                <Form.Label>Название этапа</Form.Label>
                <Form.Control value={formValues.name}
                    onChange={(e) => setFormValues((prev) => ({ ...prev, name: e.target.value }))} />
            </Form.Group>

            <Button type='submit'>Сохранить</Button>
        </Form>
    </>


}

export default VacancyStepCreateForm