import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import authService from '../../api/auth.api'
import { useTranslation } from 'react-i18next'; // Импортируем функцию перевода

type Props = {}

const ResetPassword = (props: Props) => {
    const { t } = useTranslation(); // Используем функцию перевода

    const [email, setEmail] = React.useState('')
    const [sendStatus, setSendStatus] = useState<boolean>(false)
    const handleForm = (event: React.FormEvent) => {
        event.preventDefault();
        authService.resetPassword(email).then(() => {
            setSendStatus(true);
        }).catch((e) => console.log(e))
    }

    return (
        <div className='sign-in-container'>
            <div className="white-block">
                <h1>{t('resetPassword.title')}</h1>
                <h4>{t('resetPassword.instruction')}</h4>
                {sendStatus ? <p className='text-success'>{t('resetPassword.successMessage')}</p> :
                    <>
                        <Form onSubmit={handleForm}>
                            <Form.Group className='mb-3'>
                                <Form.Label>{t('resetPassword.emailLabel')}</Form.Label>
                                <Form.Control type="email" placeholder={t('resetPassword.emailPlaceholder')} value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                            <Button variant="primary" type="submit">{t('resetPassword.submitButton')}</Button>
                        </Form>
                    </>
                }
            </div>
        </div>
    )
}

export default ResetPassword
