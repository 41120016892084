import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ICandidateVacancyStepItem } from '../../types/candidate/ICandidateVacancyStepItem'
import candidateService from '../../api/candidate.api'
import { convertUTCToDatestring } from '../../helpers/convertUTCToDatestring'

type Props = {
    resumeId: number
    vacancyId: number
}

const ResumeVacancyStepsModal = (props: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [list, setList] = useState<ICandidateVacancyStepItem[]>([]);

    const update = () => {
        candidateService.getVacancySteps(props.vacancyId, props.resumeId).then((response) => {
            setList(response.data)
        })
    }

    useEffect(() => {
        update()
    }, [props])

    return <>
        <button onClick={(e) => {
            handleShow()
        }} className={`dropdown-item`}>
            Все этапы
        </button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Этапы кандидата</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul className='list-group'>
                    {
                        list && list.length > 0 && list.map((item, index) => {
                            return <li className='list-group-item' key={index}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <small className='fw-500 text-muted'>Дата: {item.meetingDate ? convertUTCToDatestring(item.meetingDate)+" "+ (item.meetingType ?? '')  : "не задана"}</small>
                                        <p className='mb-0 fw-bold'>
                                            {item.step.name}
                                        </p>
                                    </div>
                                    {
                                        item.complete ? <p className='text-success mb-0'>Пройден</p>
                                            : item.failed ? <p className='text-danger mb-0'>Не пройден</p>
                                                : <p className='text-warning mb-0'>В процессе</p>
                                    }
                                </div>
                            </li>
                        })
                    }
                </ul>
            </Modal.Body>
        </Modal>
    </>
}

export default ResumeVacancyStepsModal