import { IMenu } from "../types/aside/IMenu";

export const ASIDE_MENU: IMenu[] = []
ASIDE_MENU.push({
    "title": "Заявки",
    "titleKZ": "Өтінімдер",
    "titleEN": "Applications",
    "list": [
        {
            "title": "Новые",
            "titleKZ": "Жаңалар",
            "titleEN": "New",
            "icon": "<i class=\"fa-solid fa-star\"></i>",
            "link": "/application/new"
        },
        {
            "title": "На рассмотрении",
            "titleKZ": "Көзделуде",
            "titleEN": "Under consideration",
            "icon": "<i class=\"fa-solid fa-hourglass-start\"></i>",
            "link": "/application/under_consideration"
        },
        {
            "title": "Принятые",
            "titleKZ": "Қабылданғандар",
            "titleEN": "Accepted",
            "icon": "<i class=\"fa-solid fa-circle-check\"></i>",
            "link": "/application/accepted"
        },
        {
            "title": "Отклоненные",
            "titleKZ": "Қабылданбағандар",
            "titleEN": "Rejected",
            "icon": "<i class=\"fa-solid fa-circle-xmark\"></i>",
            "link": "/application/rejected"
        },
        {
            "title": "На доработке",
            "titleKZ": "Қайта қаралуда",
            "titleEN": "Under revision",
            "icon": "<i class=\"fa-solid fa-file-pen\"></i>",
            "link": "/application/under_revision"
        },
        {
            "title": "Завершенные",
            "titleKZ": "Аяқталған",
            "titleEN": "Completed",
            "icon": "<i class=\"fa-solid fa-flag-checkered\"></i>",
            "link": "/application/completed"
        },
        {
            "title": "Отмененные",
            "titleKZ": "Бас тартылғандар",
            "titleEN": "Canceled",
            "icon": "<i class=\"fa-solid fa-ban\"></i>",
            "link": "/application/canceled"
        }
    ]
})
ASIDE_MENU.push({
    "title": "Вакансии",
    "titleKZ": "Бос орындар",
    "titleEN": "Vacancies",
    "list": [
        {
            "title": "Открытые",
            "titleKZ": "Ашық",
            "titleEN": "Open",
            "icon": "<i class=\"fa-solid fa-file-lines\"></i>",
            "link": "/vacancy/open"
        },
        {
            "title": "Закрытые",
            "titleKZ": "Жабық",
            "titleEN": "Closed",
            "icon": "<i class=\"fa-solid fa-file-circle-check\"></i>",
            "link": "/vacancy/closed"
        },
        {
            "title": "Отмененные",
            "titleKZ": "Бас тартылғандар",
            "titleEN": "Canceled",
            "icon": "<i class=\"fa-solid fa-ban\"></i>",
            "link": "/vacancy/canceled"
        }
    ]
})
ASIDE_MENU.push({
    "title": "Кандидаты",
    "titleKZ": "Кандидаттар",
    "titleEN": "Candidates",
    "list": [
        {
            "title": "Все",
            "titleKZ": "Барлығы",
            "titleEN": "All",
            "icon": "<i class=\"fa-solid fa-person\"></i>",
            "link": "/candidate/all"
        },
        {
            "title": "Участвуют в вакансиях",
            "titleKZ": "Бос орындарға қатысатын",
            "titleEN": "Participating in vacancies",
            "icon": "<i class=\"fa-solid fa-person-circle-question\"></i>",
            "link": "/candidate/processing"
        }
    ]
}
)
ASIDE_MENU.push({
    "title": "Информация о компании",
    "titleKZ": "Компания туралы ақпарат",
    "titleEN": "Company Information",
    "list": [
        {
            "title": "Льготы",
            "titleKZ": "Льготтар",
            "titleEN": "Benefits",
            "icon": "<i class=\"fa-solid fa-handshake\"></i>",
            "link": "/admin/privilege"
        },
        {
            "title": "Личные качества",
            "titleKZ": "Жеке сапалар",
            "titleEN": "Personal Qualities",
            "icon": "<i class=\"fa-solid fa-star\"></i>",
            "link": "/admin/personal-quality"
        },
        {
            "title": "Департаменты",
            "titleKZ": "Департаменттер",
            "titleEN": "Departments",
            "icon": "<i class=\"fa-solid fa-building\"></i>",
            "link": "/admin/department"
        }
    ]
}
)