import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import ManageTable from '../../components/manageTable/ManageTable'
import { Button, Spinner } from 'react-bootstrap'
import { useStores } from '../../stores'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { getCandidateTitle } from '../../helpers/getCandidateTitle'
import { ROUTES } from '../../helpers/routeConsts'
import { ICandidateShort } from '../../types/candidate/ICandidateShort'
import candidateService from '../../api/candidate.api'
import OperationsList from './components/OperationsList'
import { useTranslation } from 'react-i18next'; // Импортируем хук для перевода текста

type Props = {}

const Index = (props: Props) => {
    const { t } = useTranslation(); // Хук для перевода текста
    const { status } = useParams()

    const { routerStore } = useStores()

    const [pages, setPages] = useState<React.ReactNode[]>([])
    const [list, setList] = useState<ICandidateShort[] | null>(null)
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [sort, setSort] = useState<{ sortBy: String, sortDirection: 'ASC'|'DESC' }>({
        sortBy: 'id',
        sortDirection: 'DESC'
    })

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);

        if (timer) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(() => {
            updateList(sort);
        }, 300);

        setTimer(newTimer);
    };




    const updateList = (sorter:{ sortBy?: String, sortDirection?: 'ASC'|'DESC' } = {}) => {
        const {sortBy = 'id', sortDirection = 'DESC'} = sorter;
        const params = {sortBy, sortDirection}
        setList(null)
        candidateService.findAll({
            status,
            query: searchQuery.replaceAll('+','').replaceAll(' ',''),
            ...params
        }).then((response) => {
            if (response.status === 200) {
                setList(response.data[0])
            }
        }).catch((e) => {
            console.log(e)
        })

    }

    const handleSort = (sortBy:string)=> {
        if(sort.sortBy === sortBy) {
            setSort({ ...sort, sortDirection: sort.sortDirection == 'ASC' ? 'DESC' : 'ASC' })
            updateList({
                sortBy,
                sortDirection: sort.sortDirection == 'ASC' ? 'DESC' : 'ASC'
            })
        } else {
            setSort({ sortBy, sortDirection: 'ASC' })
            updateList({
                sortBy,
                sortDirection: 'ASC'
            })
        }
    }

    useEffect(() => {
        routerStore.setCurrentPath('/candidate/' + status)
        updateList(sort)
    }, [routerStore, status])

    return (
        <>
            <h3>{getCandidateTitle(status as string)}</h3>
            <Link to={ROUTES.CANDIDATE_CREATE} className='btn btn-primary mb-3'>{t('AddCandidateButton')}</Link> {/* Переводим кнопку "Добавить кандидата" */}
            <ManageTable
                title={t('CandidateListTitle')} 
                header={
                    <ul>
                        <li><form><input className='form-control' value={searchQuery} onChange={handleInputChange} placeholder={t('SearchInListPlaceholder')} /></form></li> {/* Переводим placeholder для поля поиска */}
                    </ul>
                }
                footer={<></>}
                pages={pages}
            >
                <thead>
                    <tr>
                        <th style={{ cursor: 'pointer' }} onClick={()=>handleSort('id')}>ID {
                            sort.sortBy === 'id' && <>{
                                sort.sortDirection == 'ASC' ? <i className="fa-solid fa-arrow-down-short-wide"></i> : <i className="fa-solid fa-arrow-down-wide-short"></i>
                            }</>
                        }</th>
                        <th style={{ cursor: 'pointer' }} onClick={()=>handleSort('name')}>{t('NameColumn')} {
                            sort.sortBy === 'name' && <>{
                                sort.sortDirection == 'ASC' ? <i className="fa-solid fa-arrow-down-short-wide"></i> : <i className="fa-solid fa-arrow-down-wide-short"></i>
                            }</>
                        }</th> {/* Переводим заголовок столбца "Имя" */}
                        <th style={{ cursor: 'pointer' }} onClick={()=>handleSort('email')}>{t('EmailColumn')} {
                            sort.sortBy === 'email' && <>{
                                sort.sortDirection == 'ASC' ? <i className="fa-solid fa-arrow-down-short-wide"></i> : <i className="fa-solid fa-arrow-down-wide-short"></i>
                            }</>
                        }</th> {/* Переводим заголовок столбца "Почта" */}
                        <th>{t('PhoneColumn')}</th> {/* Переводим заголовок столбца "Телефон" */}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list ?
                            <>
                                {
                                    list.map((item) => {
                                        return <tr key={item.id}>
                                            <td className='pt-3'>{item.id}</td>
                                            <td className='pt-3'><Link to={`${ROUTES.CANDIDATE_INFO}/${item.id}`}>{item.lastName + ' ' + item.firstName}</Link></td>
                                            <td className='pt-2'>{item.email}</td>
                                            <td className='pt-2'>{item.phone}</td>
                                            <td >
                                                <OperationsList id={item.id} update={updateList}/>
                                            </td>
                                        </tr>
                                    })
                                }
                            </> :
                            <tr>
                                <td colSpan={5} className='text-center p-5'>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </td>
                            </tr>
                    }
                </tbody>
            </ManageTable>
        </>
    )
}

export default observer(Index)
