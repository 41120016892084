import axios, { AxiosInstance } from 'axios';
import { ROUTES } from '../helpers/routeConsts';

export const API_URL = process.env.REACT_APP_API_URL as string

export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL as string

const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 30,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

export const fileUploadInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 30,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  withCredentials: true
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if (error.response.config.url !== ROUTES.REFRESH 
        && !error.response.config.url.includes(ROUTES.LOGIN)
        && !error.response.config.url.includes(ROUTES.REGISTER)) {
        window.location.href = ROUTES.LOGIN
      }
    } else if (error.response && error.response.status === 500) {
      // window.location.href=ROUTES.HOME
    }
    return Promise.reject(error);
  }
);


export default axiosInstance;

