export class Calendar {
    days: Date[]
    calendarDays: CalendarDay[]
    today: Date
    currentMonth:string

    constructor() {
        this.today = new Date()
        this.days = []
        let daysBefore = (this.today.getDay() === 0 ? 7 : this.today.getDay()) - 1
        let daysAfter = 7 - (this.today.getDay() === 0 ? 7 : this.today.getDay())

        this.setDays('before', daysBefore)
        this.days.push(this.today)
        this.setDays('after', daysAfter)

        this.calendarDays = this.days.map(day => new CalendarDay(day))
        this.calendarDays.sort((a: any, b: any) => a.date - b.date)
        this.currentMonth = ''
        this.getCurrentMonth()

    }

    getCurrentMonth() {
        let months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
        this.currentMonth = months[this.today.getMonth()] + ' '+ this.today.getFullYear()
    }

    static nextWeek(calendar: Calendar) {
        let newCalendar = new Calendar();
        newCalendar.today = new Date(calendar.days[0]);
        newCalendar.today.setDate(newCalendar.today.getDate() + 7);

        newCalendar.days = [];
        let daysBefore = (newCalendar.today.getDay() === 0 ? 7 : newCalendar.today.getDay()) - 1;
        newCalendar.setDays('before', daysBefore);

        newCalendar.days.push(newCalendar.today);

        newCalendar.setDays('after', 6 - daysBefore);

        newCalendar.calendarDays = newCalendar.days.map(day => new CalendarDay(day));
        newCalendar.calendarDays.sort((a: any, b: any) => a.date - b.date);
        newCalendar.getCurrentMonth();

        return newCalendar;
    }
    static prevWeek(calendar: Calendar) {
        let newCalendar = new Calendar();
        newCalendar.today = new Date(calendar.days[0]);
        newCalendar.today.setDate(newCalendar.today.getDate() - 7);

        newCalendar.days = [];
        let daysBefore = (newCalendar.today.getDay() === 0 ? 7 : newCalendar.today.getDay()) - 1;
        newCalendar.setDays('before', daysBefore);

        newCalendar.days.push(newCalendar.today);

        newCalendar.setDays('after', 6 - daysBefore);

        newCalendar.calendarDays = newCalendar.days.map(day => new CalendarDay(day));
        newCalendar.calendarDays.sort((a: any, b: any) => a.date - b.date);
        newCalendar.getCurrentMonth();

        return newCalendar;
    }

    setDays(type: 'before' | 'after', count: number) {
        for (let i = 1; i <= count; i++) {
            let date = new Date(this.today)
            date.setDate(type === 'before' ? date.getDate() - i : date.getDate() + i)
            this.days.push(date)
        }
    }

}

export class CalendarDay {
    date: Date
    day: string
    weekDay: string
    currentDate: boolean

    constructor(date: Date) {
        this.date = date
        this.day = `${date.getDate() < 10
            ? '0' + date.getDate().toString()
            : date.getDate().toString()}.${date.getMonth() + 1 < 10
                ? '0' + (date.getMonth() + 1).toString()
                : (date.getMonth() + 1).toString()}`
        this.weekDay = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'][date.getDay()]
        this.currentDate = `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`
            === `${new Date().getDate()}.${new Date().getMonth()}.${new Date().getFullYear()}`
    }
}