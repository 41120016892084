import React, { useEffect, useState } from 'react'
import vacansyService from '../../../api/vacancy.api'

type Props = {
    id:number
}

const StepCompletes = (props: Props) => {

    const [all,setAll] = useState<number>(0)
    const [completed, setCompleted] = useState<number>(0)
    useEffect(()=>{
        vacansyService.stepCompletes(props.id).then((response)=>{
            setAll(response.data.all)
            setCompleted(response.data.completed)
        })
    },[])
  return (
    <div>{completed} / {all}</div>
  )
}

export default StepCompletes