import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { IRegisterFormRequest } from '../../../types/auth/IRegisterFormRequest';
import { jwtDecode } from 'jwt-decode';
import { IUserFull } from '../../../types/user/IUserFull';
import authService from '../../../api/auth.api';
import { ROUTES } from '../../../helpers/routeConsts';
import { Alert, Button, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores';
import { observer } from 'mobx-react';
import Preloader from '../../Preloader';
import ReactInputMask from 'react-input-mask';

type Props = {
    token: string | undefined
}

const RegisterForm = (props: Props) => {
    const navigator = useNavigate();
    const { authStore } = useStores()
    const { t } = useTranslation()

    const [formValues, setFormValues] = useState<IRegisterFormRequest>({
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        inviteCode: '',
        isGoogle: false,
        phone: ''
    })

    const [error, setError] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        if (props.token) {
            let tokenInfo: IUserFull = jwtDecode(props.token)
            if (tokenInfo.registered) {
                authService.refreshByGoogleToken(props.token).then((response) => {
                    if (response.status === 200) {
                        authStore.setAuth(true)
                        authStore.setUser(response.data as IUserFull)
                        navigator(ROUTES.HOME)
                    }
                }).catch((e) => {
                    console.log(e)
                })
            } else {
                setFormValues({
                    ...formValues,
                    email: tokenInfo.email,
                    isGoogle: true,
                    firstName: tokenInfo.firstName,
                    lastName: tokenInfo.lastName
                })
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }, [])

    const handleForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        authService.register(formValues).then((response) => {
            setError('')
            if (response.status === 200) {
                authStore.setAuth(true)
                authStore.setUser(response.data as IUserFull)
                if ((response.data as IUserFull).company) {
                    navigator(ROUTES.HOME)
                } else {
                    navigator(ROUTES.NEED_COMPANY)
                }
            }
        }).catch((e) => {
            if (e.response.status == 409) {
                setError('Пользователь с таким email уже существует. Попробуйте войти.')
            } else {
                setError('Неизвестная ошибка. Попробуйте через несколько минут или обратитесь к нашим менеджерам.')
            }
        })
    }

    return loading ? <Preloader /> : <form onSubmit={(e) => handleForm(e)}>

        <div className='sign-up-email-input-container d-flex' style={{ gap: 20 }}>
            <input
                className='form-control'
                type="text"
                autoComplete='off'
                value={formValues.firstName}
                onChange={(e) => {
                    setFormValues((prev) => ({
                        ...prev,
                        firstName: e.target.value
                    }))
                }}
                placeholder={t("sign-up-firstName-placeholder")}
            />
            <input
                className='form-control'
                type="text"
                autoComplete='off'
                value={formValues.lastName}
                onChange={(e) => {
                    setFormValues((prev) => ({
                        ...prev,
                        lastName: e.target.value
                    }))
                }}
                placeholder={t("sign-up-secondName-placeholder")}
            />
        </div>

        <div className='sign-up-email-input-container'>
            <input
                disabled={props.token !== undefined}
                className='form-control'
                type="email"
                autoComplete='off'
                value={formValues.email}
                onChange={(e) => {
                    setFormValues((prev) => ({
                        ...prev,
                        email: e.target.value
                    }))
                }}
                placeholder={t("sign-in-email-placeholder")}
            />
        </div>


        <div className='sign-up-email-input-container mb-4'>
            <FormControl
                type="text"
                name="phone"
                as={ReactInputMask} mask="+7 (999) 999-99-99"
                autoComplete='off'
                value={formValues.phone}
                onChange={(e) => {
                    setFormValues((prev) => ({
                        ...prev,
                        phone: e.target.value
                    }))
                }}
                placeholder={t("sign-up-phone-placeholder")}
            />
        </div>

        <div className='sign-up-email-input-container'>
            <input
                className='form-control'
                type="password"
                autoComplete='off'
                value={formValues.password}
                onChange={(e) => {
                    setFormValues((prev) => ({
                        ...prev,
                        password: e.target.value
                    }))
                }}
                placeholder={t("sign-up-password-placeholder")}
            />
            <input
                className='form-control'
                type="password"
                autoComplete='off'
                value={formValues.confirmPassword}
                onChange={(e) => {
                    setFormValues((prev) => ({
                        ...prev,
                        confirmPassword: e.target.value
                    }))
                }}
                placeholder={t("sign-up-confirm-password-placeholder")}
            />
            {
                (formValues.password !== formValues.confirmPassword)
                && formValues.confirmPassword.length > 0 && <Alert variant={'danger'}>
                    Пароли не совпадают
                </Alert>
            }
        </div>

        <div className="sign-up-invite">
            <div className="title">
                <div className="line"></div>
                <div>{t("sign-up-invite-title")}</div>
                <div className="line"></div>
            </div>
            <div className='sign-up-email-input-container'>
                <input
                    className='form-control'
                    type="text"
                    autoComplete='off'
                    value={formValues.inviteCode}
                    onChange={(e) => {
                        setFormValues((prev) => ({
                            ...prev,
                            inviteCode: e.target.value
                        }))
                    }}
                    placeholder={t("sign-up-invite-code-placeholder")}
                />
            </div>
        </div>


        {error !== '' && <Alert variant={'danger'}>{error}</Alert>}


        <Button className='w-100 mt-4' type="submit">{t("sign-up-submit-text")}</Button>

        <p className='text-center mt-4'>{t("sign-up-have-account")}
            <Link to={ROUTES.LOGIN} className='fw-bold ms-2'>{t("sign-in-submit-text")}</Link>
        </p>
    </form>
}

export default observer(RegisterForm)