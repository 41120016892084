import React, { useEffect, useState } from 'react';
import { Button, Form, ListGroup, Modal } from 'react-bootstrap';
import vacansyService from '../../../api/vacancy.api';
import candidateService from '../../../api/candidate.api';
import { ICandidateComment } from '../../../types/candidate/ICandidateComment';
import { IDictionaryItem } from '../../../types/IDictionaryItem';
import dictionaryService from '../../../api/dictionary.api';
import { ICandidateCommentCreate } from '../../../types/candidate/ICandidateCommentCreate';
import { convertUTCToDatestring } from '../../../helpers/convertUTCToDatestring';
import { socket } from '../../../App';
import { useStores } from '../../../stores';
import CommentItem from './CommentItem';
import { useTranslation } from 'react-i18next'; // Импортируем хук для локализации

type Props = {
    vacancyId: number;
    resumeId: number;
    showComments: boolean;
    showCommentsFor: string | undefined;
};

const CandidateCommentsModal = (props: Props) => {
    const { t } = useTranslation(); // Инициализируем хук для локализации

    const [show, setShow] = useState(false);
    const [list, setList] = useState<ICandidateComment[]>([]);
    const [types, setTypes] = useState<IDictionaryItem[]>([]);
    const [formValues, setFormValues] = useState<ICandidateCommentCreate>({
        comment: '',
        commentTypeId: 0,
        vacancyId: props.vacancyId,
        resumeId: props.resumeId
    });

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        update();
        dictionaryService.findAll('commentType').then((response) => {
            setTypes(response.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const update = () => {
        candidateService.getComments(props.vacancyId, props.resumeId).then((response) => {
            setList(response.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const { authStore } = useStores();

    const handleForm = (e: React.FormEvent) => {
        e.preventDefault();
        candidateService.addComment(formValues).then((response) => {
            socket.emit('chat message', JSON.stringify({
                email: authStore.user?.email,
                action: t('comment.newCommentAction', { comment: formValues.comment }), // Локализованное действие нового комментария
                link: '/vacancy-candidates/' + props.vacancyId + '/' + props.resumeId,
                type: 'new-comment',
            }));
            update();
            setFormValues({
                ...formValues,
                comment: '',
                commentTypeId: 0
            });
        }).catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        if (props.showComments && props.showCommentsFor && Number(props.showCommentsFor) === props.resumeId) {
            handleShow();
        }
    }, []);

    return <>
        <button onClick={(e) => {
            handleShow();
        }} className={`dropdown-item`}>
            {t('comment.comments')} {/* Локализованный текст кнопки открытия комментариев */}
        </button>

        <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('comment.comments')}</Modal.Title> {/* Локализованный заголовок модального окна */}
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {
                        list.map((item, index) => {
                            return <CommentItem key={index} item={item} update={update} types={types} />;
                        })
                    }
                    <ListGroup.Item></ListGroup.Item>
                    <ListGroup.Item>
                        <form onSubmit={handleForm}>
                            <div className="form-group mb-3">
                                <label htmlFor="type" className="form-label">{t('comment.commentType')}</label> {/* Локализованный текст метки для типа комментария */}
                                <select className='form-select'
                                    value={formValues.commentTypeId} onChange={(e) => {
                                        setFormValues((prev) => {
                                            return {
                                                ...prev,
                                                commentTypeId: Number(e.target.value)
                                            };
                                        });
                                    }}
                                    id="type">
                                    <option value=''>{t('comment.selectCommentType')}</option> {/* Локализованный текст для выбора типа комментария */}
                                    {
                                        types.map((item) => {
                                            return <option value={item.id}>{item.name}</option>;
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="comment" className="form-label">{t('comment.comment')}</label> {/* Локализованный текст метки для комментария */}
                                <textarea className='form-control' id="comment"
                                    value={formValues.comment} onChange={(e) => {
                                        setFormValues((prev) => {
                                            return {
                                                ...prev,
                                                comment: e.target.value
                                            };
                                        });
                                    }}
                                    rows={3}>

                                </textarea>
                            </div>
                            <Button type='submit'>{t('comment.send')}</Button> {/* Локализованный текст кнопки отправки */}
                        </form>
                    </ListGroup.Item>
                </ListGroup>
            </Modal.Body>
        </Modal>
    </>;
};

export default CandidateCommentsModal;
