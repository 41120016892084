import axiosInstance, { fileUploadInstance } from "."
import { CandidateCreateRequest } from "../types/candidate/CandidateCreateRequest"
import { ICandidateCommentCreate } from "../types/candidate/ICandidateCommentCreate"
import { ICandidateGetFilter } from "../types/candidate/ICandidateGetFilter"
import { ResumeCreateRequest } from "../types/candidate/ResumeCreateRequest"
import {ICandidateFull} from "../types/candidate/ICandidateFull";

class CandidateService {
    async create(formData:CandidateCreateRequest) {
        const response = await axiosInstance.post('/api/candidate',formData)
        return response
    }

    async findAll(filter:ICandidateGetFilter) {
        const response = await axiosInstance.post('/api/candidate/findAll',filter)
        return response
    }

    async findById(id:number) {
        const response = await axiosInstance.get('/api/candidate/'+id)
        return response
    }

    async appendResume(formData:ResumeCreateRequest) {
        const fd = new FormData()
        fd.append('candidateId',formData.candidateId.toString())
        fd.append('file',formData.file as Blob)
        fd.append('name',formData.name)
        const response = await fileUploadInstance.post('/api/candidate/appendResume',fd)
        return response
    }

    async getVacancySteps(vacancyId:number, resumeId:number) {
        const response = await axiosInstance.get('/api/candidate/getVacancySteps/'+vacancyId+'/'+resumeId)
        return response
    }

    async getVacancyStepsShort(vacancyId:number, resumeId:number) {
        const response = await axiosInstance.get('/api/candidate/getVacancyStepsShort/'+vacancyId+'/'+resumeId)
        return response
    }

    async getComments(vacancyId:number, resumeId:number) {
        const response = await axiosInstance.get('/api/candidate/getComments/'+vacancyId+'/'+resumeId)
        return response
    }

    async addComment(fd:ICandidateCommentCreate) {
        const response = await axiosInstance.post('/api/candidate/addComment',fd)
        return response
    }

    async deleteComment(id:number) {
        const response = await axiosInstance.delete('/api/candidate/deleteComment/'+id)
        return response
    }

    async updateComment(id:number, text:string, type:number) {
        const response = await axiosInstance.put('/api/candidate/updateComment/'+id, {text, type})
        return response
    }

    async deleteResume(id:number) {
        const response = await axiosInstance.delete('/api/candidate/deleteResume/'+id)
        return response
    }

    async getVacancies(id:number) {
        const response = await axiosInstance.get('/api/candidate/getVacancies/'+id)
        return response
    }

    async deleteCandidate(id:number) {
        const response = await axiosInstance.delete<ICandidateFull>('/api/candidate/'+id)
        return response
    }
}

const candidateService = new CandidateService()

export default candidateService