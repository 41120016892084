import React from 'react'
import Header from './Header'
import Body from './Body'
import Footer from './Footer'

type Props = {
    title: string,
    children: React.ReactNode,
    header: React.ReactNode,
    footer: React.ReactNode,
    pages: React.ReactNode[]
}

const ManageTable = (props: Props) => {
    return (
        <div className='manage-table'>
            <Header title={props.title}>{props.header}</Header>
            <Body>
                <table className='table'>
                    {props.children}
                </table>
            </Body>
            <Footer pages={props.pages}>{props.footer}</Footer>
        </div>
    )
}

export default ManageTable