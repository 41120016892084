import React, { RefObject, useEffect, useRef, useState } from 'react'
import { IVacancyFull } from '../../types/vacancy/IVacancyFull'
import { Button, Form, ListGroup, Modal } from 'react-bootstrap'
import { ICandidateShort } from '../../types/candidate/ICandidateShort'
import candidateService from '../../api/candidate.api'
import SelectCandidateResumeModal from './SelectCandidateResumeModal'

type Props = {
    vacancy: IVacancyFull,
    update: () => void
}

const SelectCandidateModal = (props: Props) => {
    const [show, setShow] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [candidateList, setCandidateList] = useState<ICandidateShort[] | null>(null)

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)


    };
    const handleForm = (e: React.FormEvent) => {
        e.preventDefault();
    }

    useEffect(() => {
    }, [])

    return <>
        <Button className='mb-3' onClick={handleShow}>
            + Добавить
        </Button>

        <Modal show={show} size='lg' onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Добавление кандидата</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Вакансия: {props.vacancy.position}</p>
                <Form onSubmit={handleForm}>
                    <Form.Group className='mb-3'>
                        <Form.Label>
                            Поиск кандидата
                        </Form.Label>
                        <Form.Control
                            value={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value)
                                if (e.target.value.length > 2) {
                                    candidateService.findAll({
                                        query: e.target.value
                                    }).then((response) => {
                                        if (response.status === 200) {
                                            setCandidateList(response.data[0])
                                        }
                                    })
                                } else {
                                    setCandidateList(null)
                                }

                            }}
                            placeholder='начните вводить имя или фамилию кандидата' />
                    </Form.Group>
                    {
                        candidateList &&
                        <Form.Group>
                            <Form.Label>
                                Результаты поиска
                            </Form.Label>
                            {
                                candidateList.length > 0 && <ListGroup>
                                    {candidateList.map((candidate, index) => {
                                        return <SelectCandidateResumeModal key={index}
                                            vacancyId={props.vacancy.id}
                                            update={props.update}
                                            candidate={candidate}
                                            parentClose={handleClose} />
                                    })
                                    }
                                </ListGroup>
                            }
                        </Form.Group>
                    }
                </Form>
            </Modal.Body>
        </Modal>
    </>
}

export default SelectCandidateModal