export interface IFinderConstResults {
    title: string
    link: string
}

export const FINDER_CONST_RESULTS:IFinderConstResults[] = [
    {
        title:'Новые заявки',
        link:'/application/new',
    }, {
        title:'Заявки на рассмотрении',
        link:'/application/under_consideration',
    }, {
        title:'Принятые заявки',
        link:'/application/accepted',
    }, {
        title:'Отклоненные заявки',
        link:'/application/rejected',
    }, {
        title:'Заявки на доработке',
        link:'/application/under_revision',
    }, {
        title:'Завершенные заявки',
        link:'/application/completed',
    }, {
        title:'Открытые вакансии',
        link:'/vacancy/open',
    }, {
        title:'Закрытые вакансии',
        link:'/vacancy/closed',
    }, {
        title:'Отмененные вакансии',
        link:'/vacancy/canceled',
    }, {
        title:'Кандидаты',
        link:'/candidate/all',
    }
]