import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Spinner} from 'react-bootstrap';
import {useStores} from '../../stores';
import vacancyService from '../../api/vacancy.api';
import {convertUTCToDatestring} from '../../helpers/convertUTCToDatestring';
import OperationsList from './components/OperationsList';
import {IVacancyShort} from '../../types/vacancy/IVacancyShort';
import {observer} from 'mobx-react';
import UserShortInfo from '../../components/modals/UserShortInfo';
import {AxiosError} from 'axios';
import Filter from './components/Filter';
import ManageTable from '../../components/manageTable/ManageTable';

type Props = {};

const Index = (props: Props) => {
  const {status} = useParams();
  const {t} = useTranslation();
  const {routerStore} = useStores();

  const [pages, setPages] = useState<React.ReactNode[]>([]);
  const [list, setList] = useState<IVacancyShort[] | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      updateList();
    }, 300);

    setTimer(newTimer);
  };

  const updateList = () => {
    setList(null);
    setSearchQuery('');
    vacancyService.findAll({
      status,
      query: searchQuery
    }).then((response) => {
      if (response.status === 200) {
        setList(response.data[0]);
      }
    }).catch((e: AxiosError) => {
      if (e.response?.status === 404) {
        setList([]);
      }
    });
  };

  const afterUpdate = (data: IVacancyShort[]) => {
    setList(data);
  };

  useEffect(() => {
    routerStore.setCurrentPath('/vacancy/' + status);
  }, [routerStore, status]);

  return (
    <>
      <h3>{status === 'open' ? t('vacancy.open') : t('vacancy.closed')}</h3>
      <ManageTable
        title={t('vacancy.list')}
        header={
          <ul>
            <li><input className='form-control' value={searchQuery} onChange={handleInputChange}
                       placeholder={t('vacancy.search')}/></li>
            <li><Filter status={status} afterFilter={afterUpdate}/></li>
          </ul>
        }
        footer={<></>}
        pages={pages}
      >
        <thead>
        <tr>
          <th>ID</th>
          <th>{t('vacancy.position')}</th>
          <th>{t('vacancy.status')}</th>
          <th>{t('vacancy.recruiter')}</th>
          <th>{t('vacancy.creationDate')}</th>
          {(status === 'closed')
            ? <th>{t('vacancy.dateClosed')}</th>
            : (status === 'canceled')
              ? <th>{t('vacancy.dateCanceled')}</th>
              : null
          }
          <th>{t('vacancy.department')}</th>
          <th>{t('vacancy.operations')}</th>
        </tr>
        </thead>
        <tbody>
        {
          list ?
            <>
              {
                list.map((item) => {
                  return <tr key={item.id}>
                    <td className='pt-3'>{item.id}</td>
                    <td className='pt-3'>{item.position}</td>
                    <td className='pt-2'>
                      <div className="user-list-item">
                        {item.status.name}
                      </div>
                    </td>
                    <td className='d-flex'>
                      <UserShortInfo user={item.recruiter}/>
                    </td>
                    <td className='pt-3'>{convertUTCToDatestring(item.creationDate)}</td>
                    {status !== 'open' && <td className='pt-3'>
                      {
                        (status === 'closed')
                          ? item.dateOfClose && convertUTCToDatestring(item.dateOfClose)
                          : item.dateOfEnd && convertUTCToDatestring(item.dateOfEnd)
                      }
                    </td>
                    }
                    <td className='pt-3'>{item.department} / {item.division}</td>
                    <td>
                      <OperationsList id={item.id}/>
                    </td>
                  </tr>
                })
              }
            </> :
            <tr>
              <td colSpan={7} className='text-center p-5'>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </td>
            </tr>
        }
        </tbody>
      </ManageTable>
    </>
  )
}

export default observer(Index);
