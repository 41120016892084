export class VacancyCreateRequest {
    applicationId: number;
    position: string;
    amountOfWorkers: string;
    aboutCompanyForVacancy: string;
    problems: string;
    responsibilities: string;
    requirements: string;
    expectations: string;
    someWishes: string;
    whatWeOffer: string;
    opportunities: string;
    salary: string;
    afterTax: boolean;
    workScheduleTypeId: number;
    workExperienceTypeId: number;
    reasonForHiringId: number;
    workContractTypeId: number;
    departmentId: number;
    divisionId: number;
    privilegeIds: number[];
    softSkillIds: number[];
    personalQualitiesIds: number[];
    englishLevelId: number;
    educationDegreeId: number;
    additionalSkills: string;
    languages: {
        languageId: number;
        degreeId: number;
    }[]
    bonusTypeId: number;
    bonusValue: string;
    constructor() {
        this.applicationId = 0
        this.position = ''
        this.amountOfWorkers = '1'
        this.aboutCompanyForVacancy = ''
        this.problems = ''
        this.responsibilities = ''
        this.requirements = ''
        this.expectations = ''
        this.someWishes = ''
        this.whatWeOffer = ''
        this.opportunities = ''
        this.salary = ''
        this.afterTax = false
        this.workScheduleTypeId = 0
        this.workExperienceTypeId = 0
        this.reasonForHiringId = 0
        this.workContractTypeId = 0
        this.departmentId = 0
        this.divisionId = 0
        this.privilegeIds = []
        this.softSkillIds = []
        this.personalQualitiesIds = []
        this.englishLevelId = 0
        this.educationDegreeId = 0
        this.additionalSkills = ''
        this.languages = []
        this.bonusTypeId = 0
        this.bonusValue = ''
    }
}