import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ResumeCreateRequest } from '../../types/candidate/ResumeCreateRequest'
import candidateService from '../../api/candidate.api'

type Props = {
    id: number,
    handleClose: () => void
}

const ResumeCreateForm = (props: Props) => {

    const [formValues, setFormValues] = useState<ResumeCreateRequest>({ ...(new ResumeCreateRequest()), candidateId: props.id })

    const handleForm = (e: React.FormEvent) => {
        e.preventDefault();
        candidateService.appendResume(formValues).then((response) => {
            props.handleClose()
        }).catch((e) => {
            props.handleClose()
        })
    }

    return (
        <form onSubmit={handleForm}>
            <div className='form-grouop mb-3'>
                <label htmlFor="" className="form-label">Название </label>
                <input
                    className='form-control'
                    value={formValues.name}
                    onChange={(e) => {
                        setFormValues((prev) => ({
                            ...prev,
                            name: e.target.value
                        }))
                    }}
                />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="" className="form-label">Файл резюме</label>
                <input type="file" className="form-control" onChange={(e) => {
                    setFormValues((prev) => ({
                        ...prev,
                        file: (e.target.files && e.target.files.length ? e.target.files[0] : null)
                    }))
                }} />
            </div>
            <Button className='btn-primary' type='submit'>Добавить резюме</Button>
        </form>
    )
}

export default ResumeCreateForm