import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Импорт функции useTranslation
import vacansyService from '../../api/vacancy.api';
import { IVacancyFull } from '../../types/vacancy/IVacancyFull';
import ManageTable from '../../components/manageTable/ManageTable';
import CreateVacancyStepModal from '../../components/modals/CreateVacancyStepModal';
import StepOperationList from './components/StepOperationList';
import StepCompletes from './components/StepCompletes';
import { Button } from 'react-bootstrap';

type Props = {};

const Steps = (props: Props) => {
    const { id } = useParams();
    const { t } = useTranslation(); // Получение функции t для перевода текстов

    const [vacancy, setVacancy] = useState<IVacancyFull | null>(null);

    const update = () => {
        vacansyService.findById(Number(id)).then((response) => {
            setVacancy(response.data);
        });
    };

    const setStepIndex = (id: number, index: number) => {
        vacansyService.setStepIndex(id, index).then((response) => {
            if (response.status === 200) {
                update();
            }
        }).catch((e) => {
            console.log(e);
        });
    };

    useEffect(() => {
        update();
    }, [id]);

    return vacancy && (
        <>
            <h4 className='mb-3'>{t('steps.title', { vacancyPosition: vacancy.position })}</h4>
            <CreateVacancyStepModal vacancyId={vacancy.id} update={update} />
            <ManageTable
                title={t('steps.tableTitle')}
                header={<></>}
                footer={<></>}
                pages={[]}
            >
                <thead>
                    <tr>
                        <th style={{ width: 100 }}></th>
                        <th>{t('steps.tableHeaders.name')}</th>
                        <th>{t('steps.tableHeaders.completion')}</th>
                        <th>{t('steps.tableHeaders.operations')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        vacancy.steps && vacancy.steps.length > 0 && vacancy.steps.map((item) => (
                            <tr key={item.id}>
                                <td>
                                    <Button variant='light'
                                        onClick={() => setStepIndex(item.id, item.index - 1)}
                                        className='me-1' disabled={item.index === 0}><i className="fa-solid fa-arrow-up"></i></Button>
                                    <Button variant='light'
                                        onClick={() => setStepIndex(item.id, item.index + 1)}
                                        disabled={item.index === vacancy.steps.length-1}><i className="fa-solid fa-arrow-down"></i></Button>
                                </td>
                                <td>{item.name}</td>
                                <td><StepCompletes id={item.id} /></td>
                                <td>
                                    <StepOperationList step={item} update={update} />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </ManageTable>
        </>
    );
};

export default Steps;
