import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import RegisterForm from '../../components/forms/auth/RegisterForm'

type Props = {}

const Register = (props: Props) => {
    const { token } = useParams()
    const { t } = useTranslation();


    return <div className='sign-up-container'>
        <div className="white-block">
            <h1>{t("sign-up")}</h1>
            <h4>{t("sign-up-description")}</h4>
            <RegisterForm token={token} />
        </div>
    </div>
}

export default Register