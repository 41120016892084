import React, { useState } from 'react'
import { IUserFull } from '../../types/user/IUserFull'
import { Button, Form } from 'react-bootstrap'
import userService from '../../api/user.api'
import { useStores } from '../../stores'
import { observer } from 'mobx-react'
import ReactInputMask from 'react-input-mask'
import { useTranslation } from 'react-i18next'; // Импортируем функцию перевода

type Props = {
    user: IUserFull
}

const AccountSettingsForm = (props: Props) => {
    const { t } = useTranslation(); // Используем функцию перевода
    const [formValues, setFormValues] = useState<IUserFull>(props.user)
    const {authStore} = useStores()
    const hanldeForm = (e: React.FormEvent) => {
        e.preventDefault()
        userService.update(formValues).then((response) => {
            setFormValues(response.data)
            authStore.setUser(response.data)
        })
    }

    return <form onSubmit={hanldeForm}>
        <div className="row">
            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>{t('accountSettingsForm.firstNameLabel')}</Form.Label> {/* Используем функцию перевода */}
                    <Form.Control type="text" value={formValues?.firstName} onChange={(e) => setFormValues((prev: any) => {
                        return {
                            ...prev,
                            firstName: e.target.value
                        }
                    })} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="lastName">
                    <Form.Label>{t('accountSettingsForm.lastNameLabel')}</Form.Label> {/* Используем функцию перевода */}
                    <Form.Control type="text" value={formValues?.lastName} onChange={(e) => setFormValues((prev: any) => {
                        return {
                            ...prev,
                            lastName: e.target.value
                        }
                    })} />
                </Form.Group>
            </div>

            <div className="col-md-6">
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>{t('accountSettingsForm.emailLabel')}</Form.Label> {/* Используем функцию перевода */}
                    <Form.Control type="email" value={formValues?.email} onChange={(e) => setFormValues((prev: any) => {
                        return {
                            ...prev,
                            email: e.target.value
                        }
                    })} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="phone">
                    <Form.Label>{t('accountSettingsForm.phoneLabel')}</Form.Label> {/* Используем функцию перевода */}
                    <Form.Control 
                    type="text"
                     value={formValues?.phone} 
                     as={ReactInputMask} mask="+7 (999) 999-99-99"
                     onChange={(e) => setFormValues((prev: any) => {
                        return {
                            ...prev,
                            phone: e.target.value
                        }
                    })} />
                </Form.Group>
            </div>
        </div>

        <Button variant="primary" type="submit">{t('accountSettingsForm.saveChangesButton')}</Button> {/* Используем функцию перевода */}
    </form>
}

export default observer(AccountSettingsForm)
