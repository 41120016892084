import React, { useState } from 'react'
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ICompanyRegisterFormRequest } from '../../../types/auth/ICompanyRegisterFormRequest'
import ReactInputMask from 'react-input-mask'
import companyService from '../../../api/company.api'
import { CompanyCreateRequest } from '../../../types/company/CompanyCreateRequest'
import authService from '../../../api/auth.api'
import { observer } from 'mobx-react'
import { useStores } from '../../../stores'
import { IUserFull } from '../../../types/user/IUserFull'

type Props = {}

const CompanyBindingForm = (props: Props) => {
    const { t } = useTranslation()

    const [inviteCode, setInviteCode] = useState<string>('')
    const [formValues, setFormValues] = useState<CompanyCreateRequest>(new CompanyCreateRequest())

    const [sendRequest, setSendReques] = useState<string>('')
    const [binError, setBinError] = useState<string>('')
    const [codeError, setCodeError] = useState<string>('')

    const handleForm = (e: React.FormEvent) => {
        setBinError('')
        e.preventDefault()
        if(formValues.bin.replace(/\D/g,'').length !=12) {
            setBinError('БИН должен содержать 12 цифр')
            return
        }
        companyService.registrationCompany(formValues).then((response) => {
            console.log(response)
            setSendReques('Запрос отправлен!')
        }).catch((e) => console.log(e))
    }
    const { authStore } = useStores()
    const sendInviteCode = () => {
        authService.sendInviteCode(inviteCode).then((response) => {
            authService.refresh().then((response) => {
                if (response.status === 200) {
                    authStore.setAuth(true)
                    authStore.setUser(response.data as IUserFull)
                }
            }).catch((e) => console.log(e))
        }).catch((e:any) => {
            if(e.response.data === "INVITE_CODE_NOT_VALID") {
                setCodeError("Неправильный код приглашения, либо вы используете код, который ранее использовали.")
            } else {
                setCodeError("Что-то пошло не так. Попробуйте повторить через несколько минут. Если ошибка повторяется, обратитесь к нашим менеджерам.")
            }
        })
    }

    return (
        <div className='company-binding-form'>
            <div className="title">
                <div className="line"></div>
                <div>{t("sign-up-invite-title")}</div>
                <div className="line"></div>
            </div>
            <div className='company-input-container'>
                <InputGroup className="mb-3">
                    <Form.Control
                        className='mb-0'
                        value={inviteCode}
                        onChange={(e) => setInviteCode(e.target.value)}
                        placeholder="введите код приглашения"
                    />
                    <Button variant="primary" id="button-addon2" onClick={sendInviteCode}>
                        Отправить
                    </Button>
                </InputGroup>
                {codeError!=='' && <Form.Text className="text-danger">{codeError}</Form.Text>}
            </div>

            <div className="title">
                <div className="line"></div>
                <div>{"если вашей компании нет в системе"}</div>
                <div className="line"></div>
            </div>

            <form onSubmit={handleForm}>
                <h4 className='mb-4'>Запрос на регистрацию компании</h4>

                {
                    sendRequest === '' ?
                        <>
                            <input type="text" className='form-control mb-3'
                                placeholder='Название компании'
                                value={formValues.name} onChange={(e) => {
                                    setFormValues((prev) => ({
                                        ...prev,
                                        name: e.target.value
                                    }))
                                }}
                            />

                            <Form.Control type="text" className='mb-3'
                                placeholder='БИН'
                                as={ReactInputMask} mask="999999999999"
                                value={formValues.bin} onChange={(e) => {
                                    setFormValues((prev) => ({
                                        ...prev,
                                        bin: e.target.value
                                    }))
                                }}
                            />
                            {binError!=='' && <p className='text-danger'>{binError}</p>}

                            <FormControl type="text" className='mb-3'
                                placeholder='Телефон'
                                as={ReactInputMask} mask="+7 (999) 999-99-99"
                                value={formValues.phone} onChange={(e) => {
                                    setFormValues((prev) => ({
                                        ...prev,
                                        phone: e.target.value
                                    }))
                                }}
                            />

                            <Button type='submit'>Отправить</Button>
                        </> :
                        <h3>{sendRequest}</h3>
                }
            </form>

        </div>
    )
}

export default observer(CompanyBindingForm)