import axiosInstance, { fileUploadInstance } from "."
import { CompanyCreateRequest } from "../types/company/CompanyCreateRequest"
import { CompanyUpdateRequest } from "../types/company/CompanyUpdateRequest"
import { ToggleRole } from "../types/company/ToggleRole"

class CompanyService {
    async getMyCompany() {
        const response = await axiosInstance.get('/api/company/getMyCompany')
        return response
    }

    async getEmployees() {
        const response = await axiosInstance.get('/api/company/getEmployees')
        return response
    }

    async updateCompanyInfo(fd:CompanyUpdateRequest) {
        const response = await axiosInstance.put('/api/company/updateCompanyInfo', fd)
        return response
    }

    async updateCompanyLogo(image:File) {
        const fd = new FormData()
        fd.append('image',image)
        const response = await fileUploadInstance.put('/api/company/updateCompanyLogo',fd)
        return response
    }

    async sendInvite(email:string, roles:string[]) {
        const response = await axiosInstance.post('/api/company/sendInvite', {email,roles})
        return response
    }

    async unassignEmployee(email:string) {
        const response = await axiosInstance.post('/api/company/unassignEmployee', {email})
        return response
    }

    async setRoleForEmployee(email:string, role:string) {
        const response = await axiosInstance.post('/api/company/setRoleForEmployee', {email, role})
        return response
    }
    async removeRoleForEmployee(email:string, role:string) {
        const response = await axiosInstance.post('/api/company/removeRoleForEmployee', {email, role})
        return response
    }
    
    async getRoles() {
        const response = await axiosInstance.get(`/api/partner/roles`)
        return response
    }

    async toggleRole(fd:ToggleRole) {
        const response = await axiosInstance.put(`/api/partner/roles/toggleRole`, fd)
        return response
    }

    async registrationCompany(fd:CompanyCreateRequest) {
        const response = await axiosInstance.post('/api/company/registration', fd)
        return response
    }

}

const companyService = new CompanyService()

export default companyService