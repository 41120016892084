import React, { useEffect, useState } from 'react'
import dictionaryService from '../../../api/dictionary.api'
import { useStores } from '../../../stores'
import { IDictionaryItem } from '../../../types/IDictionaryItem'
import { ROUTES } from '../../../helpers/routeConsts'
import DivisionCreateModal from './components/DivisionCreateModal'
import ManageTable from '../../../components/manageTable/ManageTable'
import DivisionOperationList from './components/DivisionOperationList'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

type Props = {
}

const Divisions = (props: Props) => {

    const { parentId } = useParams()

    const { routerStore } = useStores()
    const [list, setList] = useState<IDictionaryItem[] | null>(null)
    const [searchResult, setSearchResult] = useState<IDictionaryItem[] | null>(null)

    const [searchQuery, setSearchQuery] = useState<string>('')
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        routerStore.setCurrentPath(ROUTES.ADMIN_DEPARTMENT)
        updateList()
    }, [])

    useEffect(() => {
        setSearchResult(list ? list.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase())) : [])
    }, [searchQuery])

    const updateList = () => {
        dictionaryService.findAllByParentId('division', Number(parentId)).then((response) => {
            if (response.status === 200) {
                setList(response.data)
                setSearchResult(response.data)
            }
        })
    }

    return <>
        <h3>Отделы департамента</h3>

        <DivisionCreateModal parentId={Number(parentId)} update={updateList} />

        <ManageTable
            title='Список отделов'
            header={
                <ul>
                    <li><input className='form-control' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder='поиск в списке' /></li>
                </ul>
            }
            footer={<></>}
            pages={[<></>]}
        >
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Название</th>
                    <th>Операции</th>
                </tr>
            </thead>
            <tbody>
                {
                    searchResult ?
                        <>
                            {
                                (searchResult as IDictionaryItem[]).map((item) => {
                                    return <tr key={item.id}>
                                        <td className='pt-3'>{item.id}</td>
                                        <td className='pt-3'>{item.name}</td>
                                        <td>
                                            <DivisionOperationList update={updateList} item={item} />
                                        </td>
                                    </tr>
                                })
                            }
                        </> :
                        <tr>
                            <td colSpan={5} className='text-center p-5'>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </td>
                        </tr>
                }
            </tbody>
        </ManageTable>
    </>
}

export default Divisions