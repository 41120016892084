import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import VacancyStepCreateForm from '../forms/VacancyStepCreateForm';

type Props = {
    vacancyId: number
    update: () => void
}

const CreateVacancyStepModal = (props: Props) => {

    const [show, setShow] = useState<boolean>(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return <>
        <Button variant="primary" className='mb-3' onClick={handleShow}>
            + Добавить этап
        </Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Создание этапа</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <VacancyStepCreateForm
                    vacancyId={props.vacancyId}
                    handleClose={handleClose}
                    update={props.update}
                />
            </Modal.Body>
        </Modal>
    </>
}

export default CreateVacancyStepModal